/**
 * 数据中心/工作台
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { actionCreator } from '../store';
import './workbench.less';
import { Button, Input, Select, Tabs, message, Row, Col, Popover, Descriptions } from 'antd';
import All from './all/all'
import { GetProject, GetTaskType, GetTasks, getDataCRCStatistic, getVisitStatistic } from "../../../services/data/DataService";
import { createFromIconfontCN, SearchOutlined, QuestionCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import RemarksModal from "./modal";


let isShowVisit = false
const { TabPane } = Tabs;
let _this;
const { Option } = Select;
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2343612_ixe83tdh89g.js',
    ],
});

class workbench extends Component {

    constructor(props) {
        super(props);
        _this = this;
        this.state = {
          
            selectedProject: null,
            activeKey: this.props.pageState.activeKey, // tabs 切换的key
            /** 页面查询条件元素*/
            page: 1,//当前页码数
            pageSize: 24,//页码大小
            subjectData: [], // 条件查询下拉框
            selectValue: this.props.pageState.selectValue || 'All', // 任务类型
            subjectStatcisicData: {}, // 受试者工作台统计数据
            project: [], // 当前用户项目
            visible: false,
            addSubjectReadonly: false, // 是否禁用添加受试者
            productCode: '', // 项目的code
            pRoleCode: '', //项目角色code
            isNameSearch: '',//是否开启姓名搜索
            visitorFinish: {},
        };
        this.inputEl = React.createRef();
        this.selectEl = React.createRef();
    }

    componentDidMount() {
        // 缓存的默认值,便于记录返回
        if (this.inputEl.current) { // 确保DOM元素已挂载  
            this.inputEl.current.state.value = this.props.pageState.inputEl
        }
        const tenantCode = localStorage.getItem('tenantCode')
        const env = process.env.REACT_APP_ENV;
        isShowVisit = false
        // 是否显示受试者访视完成数
        if (((env === 'test' || env === 'development') && tenantCode === 'TENANT-0176') || (env === 'production' && ["TENANT-042"].includes(tenantCode))) {
            isShowVisit = true
        }
        this.initRoleCondition();
        this.getProject();
        this.getTaskType();
    }

    /**
     * 处理缓存的数据
     */
    handleRecord = () => {

        this.props.handlePageState({
            ...this.props.pageState,
            selectValue: this.state.selectValue,
            inputEl: this.inputEl.current.state.value
        })
    }

    /**
     * Tabs 切换 events
     * @param key
     */
    handleChange = (key, isInit) => {
        // 页数初始化
        if (!isInit) {
            this.props.tasksData.page = 1
            this.props.handleTasksData(this.props.tasksData);
        }
        let productCode = '';
        let pRoleCode = '';
        if (this.state.project.length != 0) {
            let u = this.state.project.filter(item => (
                item.id == key
            ))
           
            if (u.length != 0) {
                // 设置当前选中的tabs
                this.setState({
                    selectedProject: u[0]
                })
                productCode = u[0].code
                pRoleCode = u[0].roleCode
            }

            this.state.project.map(item => {
                if (item.id == key) {
                    _this.getProjectStatistic(item.code);
                }
            })
        }
        _this.setState({
            activeKey: key,
            productCode,
            pRoleCode: pRoleCode
        }, () => {
            this.props.handlePageState({
                ...this.props.pageState,
                activeKey: key,
            })
            _this.getTasks(productCode)
        })
    }

    /**
     * 查找
     * @param key
     */
    handleSearch = () => {
        this.props.tasksData.page = 1
        this.props.handleTasksData(this.props.tasksData);
        this.getTasks(this.state.productCode)
    }

    /**
     * 下拉框
     * @param key
     */
    handleSelect = (c) => {
        this.setState({
            selectValue: c
        })
    }

    /**
     * 是否禁用添加受试者
     */
    initRoleCondition = () => {
        const token = sessionStorage.getItem('token');
        if (token) {
            try {
                const mToken = JSON.parse(token);
                const addSubjectReadonly = (mToken?.roleCodes || []).some(item => item == 'ReadOnly');
                this.setState({
                    addSubjectReadonly: addSubjectReadonly
                });
            } catch (error) {
            }
        }
    }

    /**
     * 获取 当前用户的项目
     * @param key
     */
    getProject = () => {
        GetProject().then(res => {
            if (res.code == 0) {
                if (res.data.length === 0) {
                    return;
                }
                this.setState({
                    project: res.data,
                }, () => {
                    if (this.state.activeKey) {
                        this.handleChange(this.state.activeKey, true)
                    } else {
                        this.handleChange(res.data[0].id.toString(), true)
                    }

                })

            }
        })
    }

    /**
     * 获取 任务类型
     * @param key
     */
    getTaskType = () => {
        GetTaskType().then(res => {
            res.data && res.data.length > 0 && res.data.map(c => {
                this.state.subjectData.push({
                    label: c.text,
                    value: c.key
                })

            })
        })
    }

    /**
     * 添加受试者
     * @param key
     */
    handleAddSubjects = () => {
        this.setState({
            visible: true,
        });
    }

    /**
     * 设置关闭隐藏状态和要传递的参数
     */
    closeModal = () => {
        this.setState({
            visible: false,
        });
    }

    /**
     * 获取 任务表数据
     * @param key
     */
    getTasks = (projectCode = '', currentPage) => {
        const tenantCode = localStorage.getItem('tenantCode')
        // 首次渲染列表，页面优先取缓存中的页数
        if (!currentPage) {
            currentPage = this.props.tasksData.page
        }
        const param = (tenantCode === 'TENANT-0178') ? {
            subjectName: this.inputEl.current ? this.inputEl.current.state.value : undefined,
            projectCode: projectCode,
            type: this.state.selectValue,
            page: currentPage ? currentPage : this.state.page,
            pageSize: this.state.pageSize,
        } : {
            subjectCode: this.inputEl.current ? this.inputEl.current.state.value : undefined,
            projectCode: projectCode,
            type: this.state.selectValue,
            page: currentPage ? currentPage : this.state.page,
            pageSize: this.state.pageSize,
        }
        GetTasks(param).then((res) => {
            let obj = res.data ? res : {};
            this.props.handleTasksData(obj);
            this.setState({ isNameSearch: res.isNameSearch })
        })
    }

    /**
     * 获取 任务表数据
     * @param key
     */
    getProjectStatistic = async (projectCode) => {
        let visitRes = []
        if (isShowVisit) {
            visitRes = await getVisitStatistic({ projectCode })
        }


        getDataCRCStatistic(projectCode).then(res => {
            let u = res.data;
            if (!u) {
                return;
            }
            this.setState({
                subjectStatcisicData: {
                    goalNum: u.goalNum,
                    groupNum: u.groupNum,
                    grofinnishNumup: u.finnishNum,
                    visitorFinish: visitRes.data || [],
                    num: u.num,
                    locremianingNumk: u.remianingNum,
                    exchangedNum: u.exchangedNum,
                    visitOne: u.visitOne,
                    visitThree: u.visitThree,
                    visitTwo: u.visitTwo,
                    groupsStatistic: (Array.isArray(u.groupsStatistic) && u.groupsStatistic.length > 0) ? u.groupsStatistic : null
                }
            })
        })
    }

    /**
     * icon 图标
     */
    formatIcon = iconName => {
        return iconName ? (<IconFont type={iconName} />) : undefined;
    }

    render() {
        const targetName = sessionStorage.getItem('targetName') || '受试者';
        let hiddenReport = false;
        let user = JSON.parse(sessionStorage.getItem('token') + "");
        let tenantCode = localStorage.getItem('tenantCode')
        if (user && (user.tenantCode === "TENANT-013" || user.tenantName === "肠外营养")) {
            hiddenReport = true;
            const filterArr = [
                { 'value': 'All', 'label': '全部' },
                { 'value': 'Input', 'label': '未完成' },
                { 'value': 'Complete', 'label': '已完成' },
            ];
            this.state.subjectData = filterArr;
        } else if (user && user.roleCode === "EDC_PI") {
            hiddenReport = true;
        }

        let selectAfter = (
            this.state.subjectData.length > 0 ?
                <Select
                    defaultValue="全部"
                    value={this.state.selectValue}
                    className="select-after"
                    onSelect={this.handleSelect}
                    name={'subjectCode'}
                >
                    {
                        this.state.subjectData.map(i => (
                            <Option
                                value={i.value}
                                key={i.value}
                            >{i.label}</Option>
                        ))
                    }
                </Select> : <></>
        );
        let { pRoleCode } = this.state
        const { goalNum, groupNum, grofinnishNumup, visitorFinish, num, locremianingNumk, exchangedNum, visitOne, visitTwo, visitThree } = this.state.subjectStatcisicData;


        const subjectStatcisicList = isShowVisit ? [
            { key: 0, icon: 'iconruzu', name: `${targetName}目标数`, num: goalNum, color: '#7c6cb4', background: '#faf4ff' },
            { key: 1, icon: 'iconluru1', name: `${targetName}入组数`, num: groupNum, color: '#B2D2DB', background: '#F0F7FF', info: 'groupNum' },
            { key: 6, icon: 'icondongjie', name: `${targetName}访视完成数`, num: visitorFinish, color: '#B7EB8F', background: '#EBFCDE' },
            { key: 2, icon: 'iconshenhe1', name: `${targetName}完成数`, num: grofinnishNumup, color: '#ECD48E', background: '#FFF8E5', info: 'finishNum' },
            // { key: 3, icon: 'iconqianming', name: pRoleCode == 'ReadOnly' ? '访视1' : '累计积分', num: pRoleCode == 'ReadOnly' ? visitOne : num, color: '#FFC9CB', background: '#FFF1F7' },
            // { key: 4, icon: 'iconhecha', name: pRoleCode == 'ReadOnly' ? '访视2' : '可兑换积分', num: pRoleCode == 'ReadOnly' ? visitTwo : locremianingNumk, color: '#FFBB96', background: '#FFF6F1' },
            // { key: 5, icon: 'icondongjie', name: pRoleCode == 'ReadOnly' ? '访视3' : '已兑换积分', num: pRoleCode == 'ReadOnly' ? visitThree : exchangedNum, color: '#B7EB8F', background: '#EBFCDE' },
        ] :
            [
                { key: 0, icon: 'iconruzu', name: `${targetName}目标数`, num: goalNum, color: '#7c6cb4', background: '#faf4ff' },
                { key: 1, icon: 'iconluru1', name: `${targetName}入组数`, num: groupNum, color: '#B2D2DB', background: '#F0F7FF', info: 'groupNum' },
                { key: 2, icon: 'iconshenhe1', name: `${targetName}完成数`, num: grofinnishNumup, color: '#ECD48E', background: '#FFF8E5', info: 'finishNum' },
                { key: 3, icon: 'iconqianming', name: pRoleCode == 'ReadOnly' ? '访视1' : '累计积分', num: pRoleCode == 'ReadOnly' ? visitOne : num, color: '#FFC9CB', background: '#FFF1F7' },
                { key: 4, icon: 'iconhecha', name: pRoleCode == 'ReadOnly' ? '访视2' : '可兑换积分', num: pRoleCode == 'ReadOnly' ? visitTwo : locremianingNumk, color: '#FFBB96', background: '#FFF6F1' },
                { key: 5, icon: 'icondongjie', name: pRoleCode == 'ReadOnly' ? '访视3' : '已兑换积分', num: pRoleCode == 'ReadOnly' ? visitThree : exchangedNum, color: '#B7EB8F', background: '#EBFCDE' },
            ]



        // ReadOnly
        return (
            <div className={'workbench'}>
                <div className={'workbench_top'} hidden={hiddenReport}>
                    <Row gutter={12}>
                        {
                            subjectStatcisicList.map(i => {
                                return (
                                    <Col key={i.key} className="gutter-row" span={4}>
                                        <div className={'cols-con'}>
                                            <div className={'subjects-left'}>
                                                <div className={'item_icon'} style={{ background: i.background }}>
                                                    {_this.formatIcon(i.icon)}
                                                </div>
                                            </div>
                                            <div className={'subjects-right'}>
                                                {i.name !== `${targetName}访视完成数` && <div className={'number'} style={{ color: i.color }}>{i.num ? i.num + '例' : 0}</div>}

                                                {i.name == `${targetName}访视完成数` &&
                                                    <>
                                                        <div style={{ color: i.color }}>
                                                            {i.num && i.num[0].nodeName && <span>{i.num && i.num[0].nodeName}:</span>}
                                                            <span className={'number'}>{`${i.num && i.num[0].count || 0}例 `}</span>
                                                        </div>
                                                        {i.name == `${targetName}访视完成数` && <div className='info-tip'>
                                                            <Popover placement="bottom" content={
                                                                <div >
                                                                    {
                                                                        i.num?.length && i.num.map(e => {
                                                                            return (
                                                                                <div className='visit_list'>
                                                                                    <div className='visit_list_left'> {e.nodeName}：</div>
                                                                                    <div className='visit_list_right'> {e.count}例</div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }

                                                                </div>
                                                            } trigger="hover">
                                                                <span>完成进度</span> <InfoCircleOutlined style={{ fontSize: '12px', color: '#6c96f7' }} />
                                                            </Popover>
                                                        </div>
                                                        }

                                                    </>
                                                }
                                                <div className={'name'}>
                                                    <span>{i.name} </span>

                                                </div>

                                            </div>
                                            {
                                                (i.info && this.state.subjectStatcisicData.groupsStatistic) && <div className='info-tip'>
                                                    <Popover content={
                                                        <Descriptions layout="horizontal" column={1} style={{ width: '100px' }}>
                                                            {
                                                                this.state.subjectStatcisicData.groupsStatistic.map(item => {
                                                                    return <Descriptions.Item label={item.shortName}>{item[i.info]}</Descriptions.Item>
                                                                })
                                                            }
                                                        </Descriptions>
                                                    } trigger="hover">
                                                        分组数据&nbsp;<InfoCircleOutlined style={{ fontSize: '12px', color: '#6c96f7' }} />
                                                    </Popover>
                                                </div>
                                            }
                                        </div>

                                    </Col>
                                )
                            })
                        }
                    </Row >
                </div >

                <div className={'workbench_bottom'}>
                    <div className={'searchs'}>
                        <div className={'all_search'}>
                            <div>
                                <Input
                                    name={'projectCode'}
                                    addonAfter={selectAfter}
                                    placeholder={`请输入${targetName}编号/姓名查找`}
                                    ref={this.inputEl}

                                />
                                <Button
                                    type="primary"
                                    style={{ width: 86, height: 36 }}
                                    onClick={this.handleSearch}
                                >
                                    查找
                                </Button>
                            </div>
                        </div>
                        <div className={'addSubject'} hidden={user?.roleCode === 'EDC_READ' || this.state.addSubjectReadonly}>
                            <Button
                                type="primary"
                                style={{ height: 36, borderRadius: '2px' }}
                                onClick={this.handleAddSubjects}
                            >
                                添加{`${targetName}`}
                            </Button>
                        </div>
                    </div>

                    <Tabs
                        onChange={this.handleChange}
                        tabBarStyle={{
                            'color': 'rgba(0, 0, 0, 0.65)',
                        }}

                        activeKey={this.state.activeKey}
                    >
                        {/* <TabPane tab="全部" key="-1">
                            {this.state.activeKey === '-1' ?
                                (
                                    <div className={'workbench_bottom_con'}>
                                        <All
                                            productCode={this.state.productCode}
                                            getTasks={this.getTasks}
                                        ></All>
                                    </div>
                                )
                                : null}
                        </TabPane> */}
                        {
                            this.state.project && this.state.project.length != 0 ?
                                this.state.project.map(u => (
                                    <TabPane tab={u.name} key={u.id}>
                                        {this.state.activeKey == u.id ? (
                                            <div className={'workbench_bottom_con'}>
                                                <All
                                                    handleRecord={this.handleRecord}
                                                    productCode={this.state.productCode}
                                                    getTasks={this.getTasks}
                                                    projects={this.state.project}
                                                    project = {u}
                                                    tenantCode={tenantCode}
                                                    projectId={u.id}
                                                    subjectScaleReportState={u?.subjectScaleReportState}
                                                    isNameSearch={this.state.isNameSearch}
                                                    pageState={this.props.pageState}
                                                    tasksData={this.props.tasksData}

                                                ></All>
                                            </div>
                                        ) : null}
                                    </TabPane>
                                )) : ''
                        }
                    </Tabs>
                </div>
                <div className={'modal'}>
                    <RemarksModal
                        visible={this.state.visible}
                        closeModal={this.closeModal}
                        selectedProject={this.state.selectedProject}
                    />
                </div>
            </div >
        )
    }
}

const mapStateToProps = (state) => ({
    tasksData: state.getIn(['data', 'tasksData']),
    pageState: state.getIn(['data', 'pageState'])
})

const mapDispatchToProps = (dispatch) => ({
    handleTasksData(data) {
        const action = actionCreator.TasksDataAction(data);
        dispatch(action)
    },
    handlePageState(data) {
        const action = actionCreator.PageStateAction(data);
        dispatch(action)
    }

})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(workbench));