/**
 * 质疑列表
 */
import React from 'react';
import './index.less';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import {
    Input, Select, Button, message as Message, Table, message, Tag 
} from "antd";
import { createFromIconfontCN } from '@ant-design/icons';

import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import PubSub from "pubsub-js";
import {
    GetReplyStatus, GetQueryList
} from "../../../../../services/dct/DctService";
import { DownOutlined } from '@ant-design/icons';
import DetailModal from "./modal";

let env = process.env.REACT_APP_ENV;
let _this;
const { Option } = Select;
const { Search } = Input;
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2283082_sb9kcktrb1j.js',//icon-shanchu icon-tongji icon-quxiao icon-quyufangwenqushi icon-tianjia1 icon-xiugai
    ],
});

class QuerylistsComponent extends React.Component {

    constructor(props) {
        super(props);
        _this = this;
        _this.state = {
            dataLists: [], // 列表集合
            columns: [
                {
                    title: '项目名称',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>
                                    {record.projectName}
                                </div>
                            </div>
                        )
                    }
                },
                {
                    title: `${sessionStorage.getItem('targetName') || '受试者'}编号`,
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>
                                    {record.subjectCode}
                                </div>
                            </div>
                        )
                    }
                },
                {
                    title: '所在访视',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>
                                    {record.visitName}
                                </div>
                            </div>
                        )
                    }
                },
                {
                    title: '表单名称',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>{record.formName}</div>
                            </div>
                        )
                    }
                },
                {
                    title: '字段',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>{record.label}</div>
                            </div>
                        )
                    }
                },
                {
                    title: '质疑文本',
                    width: '12%',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>{record.questionText}</div>
                            </div>
                        )
                    }
                },
                {
                    title: '更新时间',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>{record.updateTime ? record.updateTime.substring(0, 10) : ''}</div>
                                
                            </div>
                        )
                    }
                },
                {
                    title: '持续时间',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>{record.duration ? `${record.duration.substring(0, 10)}天` : ''}</div>
                            </div>
                        )
                    }
                },
                {
                    title: '质疑人',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>{record.questionUserName}</div>
                            </div>
                        )
                    }
                },
                {
                    title: '状态',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'statetxt'}>
                                    {
                                        record.questionStatus === '1' ? <Tag color="blue">待回复</Tag> :
                                            record.questionStatus === '2' ? <Tag color="warning">已回复</Tag> : <Tag>已关闭</Tag>
                                    }
                                </div>
                            </div>
                        )
                    }
                },
                {
                    title: '',
                    width: '',
                    render: (text, record, index) =>
                        <div
                            onClick={eve => {
                                eve.stopPropagation();
                            }}
                            className={'partPop'}
                        >
                            <Button type="link" onClick={() => this.onDetails(record, index)} >
                                查看详情
                            </Button>
                        </div>
                },
            ],// 表格 行 配置
            page: 1,//当前页码数
            pagination: { // 分页组件元素对象信息
                onChange: (pageNo) => {
                    this.getPageData(pageNo);
                },
                current: 1,
                pageSize: 10,
                total: 0,
                showSizeChanger: false,
            },
            visible: false,// modal显隐
            taskId: '', // 任务id
            formName: '',
            replyStatusList: [], // 回复状态
            turnStatue: '', // 翻页状态
            questionStatus: null, // 质疑状态
        }
        this.timer = null
    }

    formRef = React.createRef();
    inputRef = React.createRef();

    componentDidMount() {
        _this.getPageData();
    }

    /**
     * 查看详情
     */
    onDetails = async (record, index) => {
        this.setState({
            visible: true,
            taskId: record.id,
            formName: record.formName,
            questionStatus: record.questionStatus
        })

        sessionStorage.setItem('taskId',record.id)

        if (index == 0) {
            this.setState({
                turnStatue: 'prev'
            })
        } else if (index == (this.state.dataLists.length -1)) {
            this.setState({
                turnStatue: 'next'
            })
        } else {
            this.setState({
                turnStatue: ''
            })
        }
    }

    /**
     * 获取列表数据 
     */
    getPageData = async (curPage, name) => {
        let { projectId } = this.props;
        let { page } = this.state;
        const params = {
            page: curPage ? curPage : page,
            pageSize: this.state.pagination.pageSize,
            questionStatus: "",
        }

        const res = await GetQueryList(params);
        if (res && res.code == 0) {
            _this.setState({
                dataLists: [...res.data.rows],
                page: res.data.page,
                pageSize: res.data.pageSize,
                pagination: {
                    ...this.state.pagination,
                    current: res.data.page,
                    pageSize: res.data.pageSize,
                    total: res.data.total,
                }
            })
        }
    }

    // 获取详情信息
    getModalValue = (id, type) => {
        let { dataLists } = this.state;
        if (type == 'next') {
            dataLists && dataLists.length > 0 && dataLists.map((item,index)=>{
                if (item.id == id) {
                    if (index == dataLists.length - 1) {
                        this.setState({
                            turnStatue: 'next'
                        })
                        message.warning('后面没有数据了！')
                        return
                    }
                    this.setState({
                        taskId: dataLists[index+1].id,
                        turnStatue: ''
                    })
                }
            })
        } else if (type == 'prev') {
            dataLists && dataLists.map((item,index)=>{
                if (item.id == id) {
                    if (index == 0) {
                        this.setState({
                            turnStatue: 'prev'
                        })
                        message.warning('前面没有数据了！')
                        return
                    }
                    this.setState({
                        taskId: dataLists[index-1].id,
                        turnStatue: ''
                    })
                }
            })
        }
    }

    // 关闭拒绝模态框
    closeModal = () => {
        this.setState({
            visible: false,
        });
    }

    render() {
        let { columns, dataLists, pagination, taskId, turnStatue, formName, questionStatus } = this.state;

        dataLists.map((item, index) => {
            item['key'] = index + 1
        })

        return (
            <div className='queryList_wrap'>
                <div className={'queryList'}>
                    <div className={'header_content'}>
                        <div className={'left_wrap'}>
                            <span>质疑列表</span><br/>
                            {/* <Select defaultValue="全部项目" bordered={false}>
                                <Option value="1">全部项目</Option>
                            </Select><br/> */}
                            <span className={'subTitle'}>共 {pagination.total} 条记录</span>
                        </div>
                    </div>

                    <Table
                        locale={{ emptyText: "暂无数据" }}
                        columns={columns}
                        dataSource={dataLists}
                        defaultExpandAllRows={true}
                        expandIconAsCell={false}
                        pagination={dataLists.length !== 0 && pagination}
                        rowKey={'id'}
                        size='small'
                    />
                </div>

                <DetailModal
                    visible={_this.state.visible}
                    closeModal={_this.closeModal}
                    getValue={(v,type) => this.getModalValue(v, type)}
                    taskId={taskId}
                    turnStatue={turnStatue}
                    formName={formName}
                    getPageDataFun={this.getPageData}
                    questionStatus={questionStatus}
                />
            </div>
        );
    }
}
export default withRouter(QuerylistsComponent);