/**
 * 报名审核模块路由配置
 */
import ApplyList from '../components/apply/list/list';
import Representative from '../components/apply/representative/list'
import NewContractsList from '../components/apply/newContractsList/list';
import DoctorApprove from '../components/apply/doctorApprove';
import DoctorAudit from '../components/apply/doctorAudit';
import MemberSync from '../components/apply/memberSync';
//  import ReprotDetails from '../components/project/detail/projectReport/details/details';     

const routes = [
    { 'path': '/apply/list', 'component': ApplyList },
    { 'path': '/apply/representative', 'component': Representative },
    { 'path': '/newContract/list', 'component': NewContractsList },
    { 'path': '/doctorApprove', 'component': DoctorApprove },
    { 'path': '/doctorAudit', 'component': DoctorAudit },
    { 'path': '/memberSync', 'component': MemberSync },
    //  {'path': '/project/detail/projectReport/details', 'component': ReprotDetails},
];

export default routes
