import { Button, Form, Input, Modal, Radio, message as messager } from 'antd'
import modStyl from './CompleteModal.module.scss'

import React, { useContext, useState } from 'react'
import { DataType, visitTaskContext } from '..'
import { examineeStatusDict } from './DictTransform'
import { finishTask } from '../../../../services/dct/visit'

type Props = {
  current: DataType;
  onClose: ()=> void;
}

const CompleteModal: React.FC<Props> = ({current, onClose})=> {

  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const ctx = useContext(visitTaskContext)

  const handleFinish = async ({subjectState, remark}: {subjectState: number, remark: string})=> {
    setLoading(true)
    const {code, message} = await finishTask({
      visitTaskId: current.id,
      subjectState,
      remark,
    })
    setLoading(false)
    if(code === 0) {
      onClose()
      ctx.reload()
      messager.success(message)
    }
  }

  return (
    <Modal onCancel={onClose} visible={true} title="完成随访" footer={null} closable maskClosable={false} destroyOnClose>
      <Form onFinish={handleFinish} form={form} layout="vertical" colon>
        <Form.Item label="患者状态" name="subjectState" rules={[{required: true, message: '请选择患者状态'}]}>
          <Radio.Group>
            {examineeStatusDict.map(({value, label})=> <Radio value={value} key={value}>{label}</Radio>)}
          </Radio.Group>
        </Form.Item>
        <Form.Item label="其他需要备注的重要信息" name="remark" rules={[{required: true, message: '请输入备注信息'}]}>
          <Input.TextArea rows={4}></Input.TextArea>
        </Form.Item>
        <div className={modStyl.footer}>
          <Button onClick={onClose}>取消</Button>
          <Button loading={loading} htmlType="submit" type="primary">确认</Button>
        </div>
      </Form>
    </Modal>
  )
}

export default CompleteModal