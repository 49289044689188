import './SubjectModal.less'
import { Modal } from "antd"
import { WithoutRouterSubject } from "../../../data/workbench/all/subjects/subjects"
import React from "react"

type Props = {
  subjectCode: string;
  onClose: ()=> void;
}

const SubjectModal: React.FC<Props> = ({subjectCode, onClose}) => {
  return (
    <Modal onCancel={onClose} centered className="visit-task-subject-modal" bodyStyle={{background: '#F6F7FC', minHeight: 800, padding: '12px 0'}} title="患者详情" width="90%" visible={true} footer={null} maskClosable destroyOnClose>
      <WithoutRouterSubject visibleBack={false} location={{
        state: subjectCode,
        // 改为详情数据 state
        // stateFlag: 'Active',
        tenantCode: 'TENANT-0668',
        projectId: 0,// 受试者转移需要
        subjectScaleReportState: null,
      }}/>
    </Modal>
  )
}
export default SubjectModal