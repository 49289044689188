/**
 * 平台管理模块路由配置
 */
import ProjectDetail from '../components/project/detail/Detail';     // 项目中心
import ReprotDetails from '../components/project/detail/projectReport/details/details';     // 项目中心

const routes = [
    {'path': '/project/detail', 'component': ProjectDetail},
    {'path': '/project/detail/projectReport/details', 'component': ReprotDetails},
];

export default routes
