import modStyl from './subjectInfo.module.scss'

import React, { useEffect, useMemo, useRef, useState } from "react"
import { Button, Col, Form, FormInstance, Input, Modal, Row, message as messager } from 'antd';
import { EditFilled, InfoCircleOutlined, InfoOutlined } from '@ant-design/icons';
import { getSubjectBindState, updateMobile } from '../../../../../../services/data/patientSelfReported'
import storageX, { Engine } from '../../../../../../util/storageX';

//#region 姓名缩写提示
const TooltipContent = (
  <div>
      姓名缩写填写规则：支持4-10位大写字母格式填写姓名缩写；<br/>
      两字姓名填写两字拼音前两个大写字母<br/>
      举例：张悦ZHYU;<br/>
      三字姓名填写三字首字母及第三字第二字母<br/>
      举例：刘书明LSMI<br/>
      四字姓名填写每一个字的首字母<br/>
      举例：上官文朔SGWS<br/>
      如果按以上规则仍无法取得四位姓名缩写时，则用“-”在最后补齐四位姓名缩写<br/>
      举例：王丽娥WLE-<br/>
      四字以上姓名可以填写每一个字的首字母（最多支持十位）<br/>
      举例：博尔济吉特琦善BEJJTQS。
  </div>
);
//#endregion

//#region 
type EditButtonProps = {
  subjectCode: string;
  onClick: ()=> void;
}
const EditButton: React.FC<EditButtonProps> = ({subjectCode, onClick})=> {

  const [state, setState] = useState()
  
  const fetchInfo = async ()=> {
    const { data, code } = await getSubjectBindState(subjectCode);

    if(0 === code) {
      setState(data.bindState)
    }
  }
  
  const hidden = useMemo(()=> {
    const store = storageX(Engine.Session)
    const roleCode = store.get('roleCode') as string
    const projectRoleCode = store.get('projectRoleCode') as string
    return state !== 0 || projectRoleCode == 'ReadOnly' || ['CALL_CENTER', 'EDC_READ'].includes(roleCode)
  }, [state])

  useEffect(()=> {
    fetchInfo()
  }, [])
  
  return (
    <>
      {
        !hidden && (
          <Button size="small" type="primary" icon={<EditFilled/>} onClick={onClick}>修改</Button>
        )
      }
    </>
  )
}
//#endregion

type Props = {
  mobile: string;
  subjectName: string;
  subjectCode: string;
  updateSubjectData: ()=> void;
}

// 手机号脱敏
const maskNumber = (num: string) =>{
  if(num){
    return num.replace(/(1\d{2})(\d{4})(\d{4})/, '$1****$3')
  }
  return '暂无'
}
const rules = {
  name: [
    {required: true, message: '请输入姓名缩写'},
    {pattern: /^([A-Z]|-){4,10}$/, message: '仅支持4-10位大写字母格式或者“-”'}
  ],
  mobile: [
    {required: true, message: '请输入手机号'},
    {pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号'}
  ]
}

const SubjectInfo:React.FC<Props> = ({mobile, subjectName, subjectCode, updateSubjectData})=> {

  const [visible, setVisible] = useState(false)
  const formRef = useRef<FormInstance>(null)

  const targetName = sessionStorage.getItem('targetName') || '受试者';
  // 修改受试者信息
  const visibleModal = ()=>{
    setVisible(true)
    setTimeout(()=>{
      if(formRef.current) {
        formRef.current.setFieldsValue({ name: subjectName, mobile })
      }
    }, 200)
  }

  
  const handleOk = async ()=>{
    if(!formRef.current) {
      return
    }
    const { mobile, name} = formRef.current.getFieldsValue([ 'mobile', 'name'])
    const {code , message} = await updateMobile({
      mobile,
      name,
      subjectCode
    })

    if(code == 0){
      messager.success('修改成功', 1)
      updateSubjectData()
      setVisible(false)
    }
  }

  return (
    <>
      <div className={modStyl.container}>
        <div className={modStyl.header}>
            <div className={modStyl.west}>
              <InfoOutlined/>
              {targetName}信息
            </div>
            <div className={modStyl.east}>
              <EditButton onClick={visibleModal} subjectCode={subjectCode}/>
            </div>
        </div>
        <div className={modStyl.content}>
          <dl>
            <dt>手机号</dt>
            <dd>{maskNumber(mobile)}</dd>
          </dl>
          <dl>
            <dt>姓名缩写</dt>
            <dd>{subjectName ? subjectName : '---'}</dd>
          </dl>
        </div>
      </div>
      {
        visible && (
          <Modal
            className={'workModal'}
            visible={true}
            title={`${targetName}信息修改`}
            onCancel={()=> setVisible(false)}
            width={450}
            centered
            destroyOnClose
            maskClosable
            footer={null} >
              <Form 
                name="nest-messages"
                ref={formRef}
                onFinish={handleOk}
                labelCol={{span: 7}}
                wrapperCol={{span: 17}}>
                <Form.Item label="手机号" name='mobile' rules={rules.mobile}>
                  <Input style={{ width: 224 }} placeholder="请输入手机号" maxLength={11}/>
                </Form.Item>
                <Form.Item label="姓名缩写" name='name' tooltip={{ title: TooltipContent, icon: <InfoCircleOutlined /> }} rules={rules.name}>
                  <Input style={{ width: 224 }} placeholder="请输入姓名缩写" maxLength={10}/>
                </Form.Item>   
                <Row justify="center" style={{marginTop: 40}}>
                  <Col>
                    <Button htmlType="submit" type="primary">确认</Button>
                  </Col>
                </Row>
              </Form>
            </Modal>
        )
      }
    </>
  )
}

export default SubjectInfo