import React, { Component } from 'react';
import { Modal, Button, Form, Input, Select, Radio, message } from 'antd';
import { withRouter } from 'react-router-dom';
import './modal.less'
import {
    GetProject,
    GetCenterByProjectId,
    AddSubject,
    getGroupByProjectId
} from "../../../services/data/DataService";
import { getYsbmApi } from '../../../services/project/ProjectService'
import localStorageUtil from "../../../comm/localStorage";
import PubSub from "pubsub-js";
import { InfoCircleOutlined } from '@ant-design/icons'
const layout = {
    labelCol: {
        span: 7,
    },
    wrapperCol: {
        span: 17,
    },
};
const { Option } = Select;

class modal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            projectId: 0,
            projectList: [], // 当前用户项目
            centerList: [], // 根据项目id获取所属中心列表
            centerSelectOpenFlag: false,
            submitDisabled: false,//按钮状态
            subjectCodeRule: '0', //1隐藏受试者编码
            groupList: [],
            groupType: '0',
            ysbmCode: '',
            // 君实受试者知情
            informedOptions: [
                {
                    value: 1,
                    label: '线上知情',
                },
                {
                    value: 2,
                    label: '线下知情',
                }
            ]
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const { visible } = nextProps;
        if (visible) {
            this.getProject()
        }
    }

    componentDidMount() {
       
    }

    form = React.createRef();

    /**
     * 获取 当前用户的项目
     * @param key
     */
    getProject = () => {
        GetProject().then(res => {
            this.setState({
                projectList: res.data,
            })
            if (res.data.length == 1) {
                this.form.current.setFieldsValue({
                    projectCode: res.data[0].code
                })
                this.getYsbm(res.data[0].id)
                this.getCenterByProjectId(res.data[0].id)
                this.getGroupList(res.data[0].code)
                this.setState({
                    projectId: res.data[0].id
                })
            }
        })
    }

    getYsbm = (projectId) => {
        getYsbmApi(projectId).then(res => {
            this.setState({
                ysbmCode: res.data
            })
        })
    }

    /**
     * 项目名称下拉集合
     */
    handleProjectChange = (projectCode, option) => {
        if (projectCode) {
            this.setState({
                ysbmCode: ''
            }, () => {
                this.getYsbm(option.id)
            })
            this.getCenterByProjectId(option.id)
            this.getGroupList(projectCode)
            this.setState({
                projectId: option.id
            })
        }
    }

    /**
     * 所属中心 下拉集合
     */
    getCenterByProjectId = (id) => {
        GetCenterByProjectId(id).then(res => {
            this.setState({
                centerList: res.data
            })
            if (res.data.length == 1) {
                this.form.current.setFieldsValue({
                    centerCode: res.data[0].code
                })
            }
        })
    }

    /**
     * 所属分组 下拉集合
     */
    getGroupList = (projectCode) => {
        let params = {
            projectCode
        }
        getGroupByProjectId(params).then(res => {
            if (res.code == 0) {
                this.setState({
                    groupList: res.data.configDtoList || [],
                    groupType: res.data.groupType || '0',
                    subjectCodeRule: res.data.subjectCodeRule|| '0'
                })

                // if (res.data.groupType != '2') {
                if (res.data.groupType == '1' || res.data.groupType == '0') {
                    this.form.current.setFieldsValue({
                        groupCode: '随机分组'
                    })
                } else {
                    this.form.current.setFieldsValue({ groupCode: null })
                }

            }
        })
    }

    /**
     * 项目名称下拉集合
     */
    /*handleCenterChange = () => {
        console.log(this.form.current.getFieldsValue(['projectCode']))
    }*/

    /**
     * 项目名称下拉集合
     */
    handleCenterFocus = () => {
        if (!this.form.current.getFieldsValue(['projectCode']).projectCode) {
            message.error('请先选择项目名称');
        }
    }

    /**
     * 添加
     */
    handleOk = () => {
        console.log(this.state.groupType);
        let param = this.form.current.getFieldsValue(['projectCode', 'centerCode', 'subjectCode', 'groupCode', 'mobile', 'name', 'informed'])
        param.subjectCode = param.subjectCode ? param.subjectCode.trim() : ''
        param.groupCode = param.groupCode == '随机分组' ? '' : param.groupCode
        this.setState({
            submitDisabled: true
        })
        AddSubject(param).then(res => {
            if (res.code == 0) {
                const subjectScaleReportState = (this.state?.projectList ?? []).find(p => p.code === param.projectCode)?.subjectScaleReportState
                this.setState({
                    projectList: [],
                    centerList: [],
                }, () => {
                    this.props.closeModal()
                })
                //面包屑
                let menuModule = localStorageUtil.get('menuModule', true);
                menuModule.levelThreeTitle = sessionStorage.getItem('targetName') || '受试者';
                menuModule.levelTwoUrl = '/data/workbench';
                localStorageUtil.set('menuModule', menuModule, true);
                PubSub.publish('onMenuModuleChange', menuModule);
                //跳转页面
                if (res.data.hasScreen) {
                    //入排
                    this.props.history.push({
                        pathname: '/data/workbench/all/subjects',
                        state: res.data.code,
                        stateFlag: 'Init',
                        projectId: this.state.projectId,
                        subjectScaleReportState,
                    })
                } else {
                    //受试者首页
                    this.props.history.push({
                        pathname: '/data/workbench/all/subjects',
                        projectId: this.state.projectId,
                        state: res.data.code,
                        stateFlag: 'Active',
                        subjectScaleReportState,
                    })
                }
            }
            this.setState({
                submitDisabled: false
            })
        })
    };

    /**
     * 关闭
     */
    handleClose = () => {
        this.props.closeModal();
        this.setState({
            projectList: [],
            centerList: [],
        })
    };

    render() {
        const { visible } = this.props;
        const  targetName = sessionStorage.getItem("targetName") || '受试者'
        const tooltipContent = (
            <div>
                姓名缩写填写规则：支持4-10位大写字母格式填写姓名缩写；<br/>
                两字姓名填写两字拼音前两个大写字母<br/>
                举例：张悦ZHYU;<br/>
                三字姓名填写三字首字母及第三字第二字母<br/>
                举例：刘书明LSMI<br/>
                四字姓名填写每一个字的首字母<br/>
                举例：上官文朔SGWS<br/>
                如果按以上规则仍无法取得四位姓名缩写时，则用“-”在最后补齐四位姓名缩写<br/>
                举例：王丽娥WLE-<br/>
                四字以上姓名可以填写每一个字的首字母（最多支持十位）<br/>
                举例：博尔济吉特琦善BEJJTQS。
            </div>
        
        );
        return (
            <>
                <Modal
                    className={'workModal'}
                    visible={visible}
                    title={`添加${targetName}`}
                    onCancel={this.handleClose}
                    width={454}
                    centered
                    destroyOnClose
                    footer={[]}
                >
                    <div >
                        <Form
                            {...layout}
                            name="nest-messages"
                            ref={this.form}
                            onFinish={this.handleOk}
                        >
                            <Form.Item
                                label="项目名称"
                                name='projectCode'
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择项目名称!',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder='请选择项目名称'
                                    style={{ width: 224 }}
                                    onChange={this.handleProjectChange}
                                >
                                    {
                                        this.state.projectList && this.state.projectList.length != 0 ?
                                            this.state.projectList.map(v => (
                                                <Option
                                                    value={v.code}
                                                    id={v.id}
                                                    key={v.code}>{v.name}</Option>
                                            )) : ''
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="所属中心"
                                name='centerCode'
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择所属中心',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder='请选择所属中心'
                                    style={{ width: 224 }}
                                    /*onChange={this.handleCenterFocus}*/
                                    onFocus={this.handleCenterFocus}
                                /*open={this.state.centerSelectOpenFlag ? false : true}*/
                                >
                                    {
                                        this.state.centerList && this.state.centerList.length != 0 ?
                                            this.state.centerList.map(v => (
                                                <Option
                                                    value={v.code}
                                                    key={v.code}>{v.name}</Option>
                                            )) : ''
                                    }
                                </Select>
                            </Form.Item>
                            { this.props.selectedProject?.editModel===1 &&  
                               <>
                                   <Form.Item
                                   label="手机号"
                                   name='mobile'
                                   rules={[
                                        {
                                            required: true,
                                            message: '请输入手机号',
                                        },
                                        { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号' }
                                   ]}
                                 
                               >
                                   <Input
                                       style={{ width: 224 }}
                                       placeholder="请输入手机号"
                                       maxLength={11}
                              
                                   />
                               </Form.Item>
                       
                               <Form.Item
                                   label="姓名缩写"
                                   name='name'
                                   tooltip={{ title: tooltipContent, icon: <InfoCircleOutlined />, overlayClassName:'formContent' }}
                                   rules={[
                                        {
                                            required: true,
                                            message: '请输入姓名缩写',
                                            
                                        },
                                        { pattern: /^([A-Z]|-){4,10}$/, message: '仅支持4-10位大写字母格式或者“-”'}
                                   ]}
                        
                               >
                                   <Input
                                       style={{ width: 224 }}
                                       placeholder="请输入姓名缩写"
                                       maxLength={10}
                                   />
                               </Form.Item>
                               <Form.Item label={`${targetName}知情`} name="informed" rules={[{required: true, message: '请选择知情方式'}]}>
                                   <Radio.Group options={this.state.informedOptions}></Radio.Group>
                               </Form.Item>
                               </>
                            } 
                            <Form.Item
                                label="所属分组"
                                name='groupCode'
                                hidden={this.state.groupType == '0'}
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择所属分组',
                                    },
                                ]}
                            >
                                {this.state.groupType == '2' ? <Select
                                    placeholder='请选择所属分组'
                                    style={{ width: 224 }}
                                >
                                    {
                                        this.state.groupList && this.state.groupList.length != 0 ?
                                            this.state.groupList.map(v => (
                                                <Option
                                                    value={v.groupCode}
                                                    key={v.groupCode}>{v.groupName}</Option>
                                            )) : ''
                                    }
                                </Select> : <div>随机分组</div>}
                            </Form.Item>

                            {this.state.ysbmCode && <Form.Item
                                label="医生编码"
                                name='subjectCode'
                            >
                                {this.state.ysbmCode}
                            </Form.Item>}

                            <Form.Item
                                label={`${targetName}编码`}
                                name='subjectCode'
                                rules={[
                                    {
                                        whitespace: true,
                                        message: '不能输入空格',
                                    }
                                ]}
                                hidden={this.state.subjectCodeRule == '1'}
                            >
                                <Input
                                    style={{ width: 224 }}
                                    placeholder="不填自动生成"
                                />
                            </Form.Item>

                            <Form.Item
                                style={{
                                    textAlign: 'center',
                                    width: '100%',
                                    marginBottom: 0,
                                    marginTop: 45,
                                }}
                                wrapperCol={24}
                            >
                                <Button disabled={this.state.submitDisabled} htmlType="submit" type="primary">
                                    确认
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </Modal>
            </>
        );
    }
}

export default withRouter(modal);
