import React from "react"
import locale from "antd/es/date-picker/locale/zh_CN"
import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd"
import { useEffect, useState } from "react"
import { getTaskDistributeCenterList, getTaskDistributeProjectList } from "../../../../services/dct/DctService"

import modStyl from './Condition.module.scss'
import { Moment } from "moment"

export enum StatusEnum {
  // NONE节点不是业务使用 仅前端控制按钮显示
  NONE = 'NONE',
  RUNNING = '待审核',
  AGREE = '已通过',
  REJECT_REPLYING = '质疑待回复',
  REJECT_COMPLETING = '质疑待确认'
}

export type ApproveStatus = keyof typeof StatusEnum

export type Params = {
  projectName: string;
  centerName: string;
  subjectCode: string;
  createUser: string;
  approveStatus: ApproveStatus;
  startTime: string;
  endTime: string;
}

type ConditionProps = {
  onSearch: (params: Params)=> void
}

type SelectOption = {
  label: string;
  value: string;
}

const colCols = {
  span: 6
}
const fieldCols = {
  labelCol: {
    span: 5
  },
  wrapperCol: {
    span: 19
  }
}

const Condition: React.FC<ConditionProps> = (props)=> {

  const [projectName, setProjectName] = useState<string>()
  const [centerName, setCenterName] = useState<string>()
  const [approveStatus, setApproveStatus] = useState<ApproveStatus>('RUNNING')
  const [subjectCode, setSubjectCode] = useState('')
  const [createUser, setCreateUser] = useState('')
  const [betweenAt, setBetweenAt] = useState<string[]>([])
  const [betweenAtMonent, setBetweenAtMonent] = useState<[Moment, Moment]>()

  // 所属项目
  const [projs, setProjs] = useState<SelectOption[]>([])
  // 所属中心
  const [centers, setCenters] = useState<SelectOption[]>([])

  // 状态
  const [status] = useState(()=> {
    return Object.keys(StatusEnum).map((value: string)=> ({
      label:  StatusEnum[value as ApproveStatus],
      value: value,
    }))
  })
  const targetName = sessionStorage.getItem('targetName') || '受试者'
  // 项目列表
  type ProjectResult = {
    code: number;
    message?: string;
    data: string[];
  }
  const fetchProjs = async ()=> {
    const { code, data }: ProjectResult = await getTaskDistributeProjectList()
    if(code === 0) {
      if(data.length === 1) {
        setProjectName(data[0])
      }
      setProjs(
        data.map((name: string)=> ({
          label: name,
          value: name
        }))
      )
    }
  }

  // 中心列表
  type  CenterResult = {
    code: number;
    message?: string;
    data: {
      id: number;
      title: string;
    }[];
  }
  const fetchCenters = async ()=> {
    const { code, data }: CenterResult = await getTaskDistributeCenterList()
    if(code === 0) {
      if(data.length === 1) {
        setCenterName(data[0].title)
      }
      setCenters(
        data.map(({title})=> ({
          label: title,
          value: title
        }))
      )
    }
  }

  const rangePickerControl = (raw: any, between: [string, string])=> {
    setBetweenAt(between)
    setBetweenAtMonent(raw)
  }

  const submitControl = ()=> {
    const [startTime, endTime] = betweenAt
    props.onSearch({
      approveStatus,
      projectName: projectName ?? '',
      centerName: centerName ?? '',
      subjectCode,
      createUser,
      startTime,
      endTime
    })
  }

  const resetControl = ()=> {
    const projName = projs.length === 1 ? projs[0]['value'] : undefined
    const centName = centers.length === 1 ? centers[0]['value'] : undefined

    setCenterName(centName)
    setProjectName(projName)
    setApproveStatus('RUNNING')
    setBetweenAt([])
    setBetweenAtMonent(undefined)
    setCreateUser('')
    setSubjectCode('')

    props.onSearch({
      approveStatus: "RUNNING",
      projectName: projName ?? '',
      centerName: centName ?? '',
      subjectCode: '',
      createUser: '',
      startTime: '',
      endTime: '',
    })
  }

  useEffect(()=> {
    fetchProjs()
    fetchCenters()
  }, [])

  return (
    <div className={modStyl.wrapper}>
      <Form>
        <Row gutter={16}>
          <Col {...colCols}>
            <Form.Item {...fieldCols} label="所属项目">
              <Select onChange={(value)=> setProjectName(value)} value={projectName} options={projs} placeholder="请选择" showSearch allowClear/>
            </Form.Item>
          </Col>
          <Col {...colCols}>
            <Form.Item {...fieldCols} label="所属中心">
              <Select onChange={(value)=> setCenterName(value)} value={centerName} options={centers} placeholder="请选择" showSearch allowClear/>
            </Form.Item>
          </Col>
          <Col {...colCols}>
            <Form.Item {...fieldCols} label="　采集人">
              <Input onChange={({target})=> setCreateUser(target.value)} value={createUser} placeholder="请输入采集人" allowClear/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col {...colCols}>
            <Form.Item {...fieldCols} label={targetName}>
              <Input onChange={({target})=> setSubjectCode(target.value)} value={subjectCode} placeholder={'请输入'+targetName+'姓名缩写/编号查找'} allowClear/>
            </Form.Item>
          </Col>
          <Col {...colCols}>
            <Form.Item {...fieldCols} label="审核状态">
              <Select onChange={(value)=> setApproveStatus(value)} value={approveStatus} options={status.slice(1)} placeholder="请选择"/>
            </Form.Item>
          </Col>
          <Col {...colCols}>
            <Form.Item {...fieldCols} label="审核时间">
              <DatePicker.RangePicker style={{width: '100%'}} onChange={rangePickerControl} value={betweenAtMonent} locale={locale} allowClear/>
            </Form.Item>
          </Col>
          <Col {...colCols}>
            <Button onClick={submitControl} type="primary">搜索</Button>
            <Button onClick={resetControl} style={{marginLeft: 10}}>重置</Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default Condition