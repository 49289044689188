/**
 * 审核记录下载
 */
import React from 'react';
import './index.less';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import {
    Form, Input, Select, Button, message as Message, Table, message, Modal, DatePicker
} from "antd";
import { createFromIconfontCN, FormOutlined, PaperClipOutlined, SaveOutlined, DownloadOutlined } from '@ant-design/icons';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import PubSub from "pubsub-js";
import { approveTaskList, downloadTaskUrl, createDownloadTask, approveProjectEnume } from "../../services/downloadApprove/DownloadApproveService";
import { GetProjectEnume } from "../../services/dct/DctService";
import utils from "../../util/utils";
import { BASE_URL } from '../../comm/config';
import $ from 'jquery';

const dateFormat = 'YYYY-MM-DD';
const FormItem = Form.Item;

let _this;
const { Option } = Select;
const { Search } = Input;
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2283082_sb9kcktrb1j.js',//icon-shanchu icon-tongji icon-quxiao icon-quyufangwenqushi icon-tianjia1 icon-xiugai
    ],
});

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
};

class DctApproveDownload extends React.Component {

    constructor(props) {
        super(props);
        _this = this;
        _this.state = {
            dataLists: [], // 列表集合
            columns: [
                {
                    title: '任务名称',
                    width: '10%',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>{record.name}</div>
                            </div>
                        )
                    }
                },
                {
                    title: '项目名称',
                    width: '15%',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>
                                    {record.projectName}
                                </div>
                            </div>
                        )
                    }
                },
                {
                    title: '开始时间',
                    width: '',
                    render: (text, record, index) => {
                        if (record.createTime) {
                            return (
                                <div className={'con_div'}>
                                    <div className={'sub-title'}>{record.createTime ? record.createTime.replace('T', ' ') : ''}</div>
                                </div>
                            )
                        }
                    }
                },
                {
                    title: '完成时间',
                    width: '',
                    render: (text, record, index) => {
                        if (record.finishTime) {
                            return (
                                <div className={'con_div'}>
                                    <div className={'sub-title'}>{record.finishTime ? record.finishTime.replace('T', ' ') : ''}</div>
                                </div>
                            )
                        }
                    }
                },
                {
                    title: '状态',
                    width: '',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div current_state'}>
                                {/* 0-下载中 1-已完成 */}
                                <Button type={record.taskState == '0' ? 'link' : 'text'}>
                                    {
                                        record.taskState == '0' ? '生成中' : '已完成'
                                    }
                                </Button>
                            </div>
                        )
                    }
                },
                {
                    title: '',
                    width: '',
                    render: (text, record) =>
                        <div
                            onClick={eve => {
                                eve.stopPropagation();
                            }}
                            className={'partPop'}
                        >
                            <Button type="link" onClick={() => this.downLoadData(record)} disabled={record.taskState == '0'}>
                                下载数据
                            </Button>
                        </div>
                },
            ],// 表格 行 配置
            page: 1,//当前页码数
            pagination: { // 分页组件元素对象信息
                onChange: (pageNo) => {
                    this.getPageData(pageNo);
                },
                current: 1,
                pageSize: 10,
                total: 100,
                showSizeChanger: false,
            },
            isCreateVisible: false, // 创建数据下载任务模态框
            isDownVisible: false, // 下载数据模态框
            projectList: [], // 所有项目
            projectCode: '', // 项目code
            currentDate: null, // 检索日期
            rowId: '',
            caseStartDate: null, // 起始时间
            caseEndDate: null, // 截至时间
        }
    }

    formRef = React.createRef();
    formRefDownLoad = React.createRef();
    inputRef = React.createRef();

    componentDidMount = () => {
        // 列表
        this.getPageData(); 
        // 获取项目枚举
        this.getProjectEnume(); 
    }

    // 获取项目枚举
    getProjectEnume = () => {
        approveProjectEnume().then(res => {
            this.setState({
                projectList: res || []
            })
        })
    }

    /**
     * 下载数据
     */
    downLoadData = async (record) => {

        if (this.formRefDownLoad.current) {
            this.formRefDownLoad.current.resetFields()
        }

        this.setState({
            isDownVisible: true,
            rowId: record.id,
        })
    }

    /**
     * 下载数据-关闭
     */
    handleDownCancel = () => {
        this.setState({
            isDownVisible: false
        })
    }

    /**
     * 获取列表数据 
     */
    getPageData = async (curPage) => {
        let { page, projectCode, currentDate } = this.state;

        const params = {
            projectCode,
            page: curPage ? curPage : page,
            pageSize: this.state.pagination.pageSize,
            date: currentDate || '',
            taskType:1
        }

        const res = await approveTaskList(params);
        if (res && res.code == 0) {
            _this.setState({
                dataLists: [...res.data.rows],
                page: res.data.page,
                pageSize: res.data.pageSize,
                pagination: {
                    ...this.state.pagination,
                    current: res.data.page,
                    pageSize: res.data.pageSize,
                    total: res.data.total,
                }
            })
        }
    }

    // 取消
    handleCancel = async () => {
        this.setState({
            isCreateVisible: false
        })
    }

    // form过滤空格
    getValueFromEvent = (event) => {
        return event.target.value.replace(/\s+/g, "")
    }

    // 创建下载任务
    createTasks = () => {
        if (this.formRef.current) {
            this.formRef.current.resetFields()
        }
        
        this.setState({
            isCreateVisible: true
        })
    }

    // 选择项目
    handleSelectChange = (value) => {
        this.setState({
            projectCode: value
        }, () => {
            this.getPageData()
        })
    }

    // 选择日期
    onDateChange = (date, dateString) => {
        this.setState({
            currentDate: dateString
        }, () => {
            this.getPageData()
        })
    };

    // 创建/提交
    onSubmit = async (values) => {
        const form = this.formRef.current;
        const formValues = form.getFieldsValue(
            ['projectCode', 'passWord', 'caseStartDate', 'caseEndDate']);
        
        let params = {
            projectCode: formValues.projectCode,
            pwd: formValues.passWord
        }

        if (formValues.caseStartDate) {
            params['startDate'] = moment(formValues.caseStartDate).format('YYYY-MM-DD')
        }
        if (formValues.caseEndDate) {
            params['endDate'] = moment(formValues.caseEndDate).format('YYYY-MM-DD')
        }
        
        const res = await createDownloadTask(params);
        if (res && res.code == 0) {
            message.success('创建成功！');

            _this.setState({
                isCreateVisible: false
            }, () => {
                this.getPageData()
            })
        }
    }
    // 下载/提交
    onDownloadSubmit = async (values) => {
        const form = this.formRefDownLoad.current;
        const formValues = form.getFieldsValue(['passWord']);

        let params = {
            id: this.state.rowId,
            pwd: formValues.passWord
        }

        downloadTaskUrl(params).then(res => {
            if (res && res.code == 0) {
                window.open(res.data);
                this.setState({
                    isDownVisible: false
                })
            }
        })
    }

    render() {
        let { columns, dataLists, pagination, isCreateVisible, isDownVisible, projectList, currentDate } = this.state;

        dataLists.length > 0 && dataLists.map((item, index) => {
            item['key'] = index + 1
        })

        return (
            <div className='sourceData'>
                <div className={'download_wrap'}>
                    <div className={'title_wrap'}>
                        <div>审核记录下载</div>
                        <div className={'search_wrap'}>
                            <DatePicker
                                onChange={this.onDateChange}
                                locale={locale}
                                value={currentDate ? moment(currentDate, 'YYYY-MM-DD') : null}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;

                            <Select defaultValue="所有项目" onChange={this.handleSelectChange} size='middle'>
                                <Option value={''}>所有项目</Option>
                                {projectList.length > 0 && projectList.map(project => (
                                    <Option key={project.projectCode} value={project.projectCode}>{project.projectName}</Option>
                                ))}
                            </Select>&nbsp;&nbsp;&nbsp;&nbsp;

                            <Button type="primary" size={'middle'} onClick={() => this.createTasks()}>
                                创建数据下载任务
                            </Button>
                        </div>
                    </div>

                    <Table
                        locale={{ emptyText: "暂无数据" }}
                        columns={columns}
                        dataSource={dataLists}
                        pagination={dataLists.length !== 0 && pagination}
                        rowKey={'id'}
                    />
                </div>

                {/* 创建数据下载任务 */}
                <Modal
                    title="创建数据下载任务"
                    visible={isCreateVisible}
                    onOk={this.onSubmit}
                    okText='确认创建'
                    cancelText='关闭'
                    onCancel={this.handleCancel}
                    width='30%'
                >
                    <Form
                        {...layout}
                        name="nest-messages"
                        onFinish={this.onSubmit}
                        requiredMark={true}
                        ref={this.formRef}
                        autoComplete="off"
                        initialValues={{
                            'projectCode': '',
                            'passWord': '',
                        }}
                    >
                        <Form.Item
                            label="请选择项目"
                            name='projectCode'
                            rules={[{ required: false, message: '请选择项目' }]}
                        >
                            <Select
                                onChange={this.handleChange}
                                placeholder='请选择项目'
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                allowClear
                            >
                                {projectList.length > 0 && projectList.map(project => (
                                    <Option key={project.projectCode} value={project.projectCode}>{project.projectName}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="请输入超级密码"
                            name="passWord"
                            rules={[{ required: false, message: '请输入超级密码!' }]}
                        >
                            <Input type="password" allowClear />
                        </Form.Item>

                        <Form.Item
                            label="审核记录起始时间"
                            name="caseStartDate"
                            rules={[{ required: false, message: '请输入审核记录起始时间!' }]}
                        >
                            <DatePicker
                                locale={locale}
                                style={{width: '100%'}}
                            />
                        </Form.Item>
                        <Form.Item
                            label="审核记录截至时间"
                            name="caseEndDate"
                            rules={[{ required: false, message: '请输入审核记录截至时间!' }]}
                        >
                            <DatePicker
                                locale={locale}
                                style={{width: '100%'}}
                            />
                        </Form.Item>
                    </Form>
                </Modal>

                {/* 下载数据 */}
                <Modal
                    title="验证密码"
                    visible={isDownVisible}
                    onOk={this.onDownloadSubmit}
                    okText='开始下载'
                    cancelText='关闭'
                    onCancel={this.handleDownCancel}
                    width='30%'
                >
                    <Form
                        {...layout}
                        name="nest-messages"
                        onFinish={this.onDownloadSubmit}
                        requiredMark={true}
                        ref={this.formRefDownLoad}
                        initialValues={{
                            'passWord': '',
                        }}
                    >
                        <Form.Item
                            label="请输入超级密码"
                            name="passWord"
                            rules={[{ required: false, message: '请输入超级密码!' }]}
                        >
                            <Input type="password" />
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    }
}
export default withRouter(DctApproveDownload);