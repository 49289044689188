import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import PlatComponents from './PlatComponents';
import ProjectComponents from './ProjectComponents';
import TenantComponents from './TenantComponents';
import ConfigComponents from './ConfigComponents';
import DesignComponents from './DesignComponents';
import DataComponents from './DataComponents';
import ApplyComponents from './ApplyComponents';
import CdmsComponents from './CdmsComponents';
import DctComponents from './DctComponents';
import SystemComponents from './SystemComponents';
import LogComponents from './logComponents';
import DownloadComponents from './DownloadComponents';
import DctApproveComponents from './DownloadComponents';
import FollowComponents from './FollowComponents';
import AuditComponents from './AuditComponents';
import ProjectDataComponents from './ProjectDataComponents';
import CpdsComponents from './CpdsComponents';
import CsoComponents from './CsoComponents';
import AreaRoutes from './AreaRoutes';
import ActivityList from './ActivityComponents'
export class RootRouter extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const isLogin = sessionStorage.getItem('token') || '';
        const LoginPathName = sessionStorage.getItem('LoginPathName');  // /login , /safeLogin
        const routes = this.getComponents().map((item, index) => {
            return (
                <Route
                    exact
                    key={index}
                    path={item.path}
                    render={props =>
                        isLogin ?
                            <item.component key={props.match.params.type}{...props} /> :
                            <Redirect to={{
                                pathname: `${LoginPathName === '/safeLogin' ? '/safeLogin' : '/login'}`,
                                state: { from: props.location }
                            }} />
                    }
                />
            );
        });
        return (
            <Switch>
                {routes}
            </Switch>
        )
    }

    getComponents = () => {
        let components = [];
        components.push(
            ...PlatComponents, ...ProjectComponents, ...TenantComponents, ...ConfigComponents,
            ...DesignComponents, ...DataComponents, ...ApplyComponents,
            ...DctComponents, ...CdmsComponents, ...LogComponents, ...SystemComponents, ...DownloadComponents,
            ...DctApproveComponents, ...FollowComponents, ...AuditComponents, ...ProjectDataComponents, ...CsoComponents, ...CpdsComponents, ...AreaRoutes, ...ActivityList);
        return components;
    }
}
