import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './all.less';
import { List, Card, Button } from 'antd';
import localStorageUtil from "../../../../comm/localStorage";
import PubSub from "pubsub-js";
import { createFromIconfontCN, MessageOutlined, MessageTwoTone, WarningFilled } from "@ant-design/icons";
import ChatModal from '../../../chat/ChatModal';
const env = process.env.REACT_APP_ENV;
let eproProjectCodes = []
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/c/font_2343612_enl0kq66cmg.js',
    ],
});
class all extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            dataEmpty: [],
            dataRender: [],
            total: 24,
            showChatModal: false,
            subjectCode: '',
        };
    }


    componentWillReceiveProps(nextProps, nextContext) {
        this.dealTaskdata(nextProps.tasksData);
    }

    componentWillMount() {
        let dataEmpty = [];
        for (let i = 0; i < 24; i++) {
            dataEmpty.push({
                createUser: ''
            })
        }
        this.setState({
            dataEmpty
        })
    }

    componentDidMount() {

        /* this.props.getTasks()*/
        if (env === 'development' || env === 'test') {
            eproProjectCodes = ['PROJECT10000629', 'PROJECT10000623']
        } else if (env === 'production') {
            eproProjectCodes = ['PROJECT10000556', 'PROJECT10000555', 'PROJECT10000558', 'PROJECT10000557']
        }


    }

    dealTaskdata = (data) => {
        if (JSON.stringify(data) != '{}') {
            const taskData = { ...data }
            if (taskData.data && taskData.data.length != 0) {
                if (taskData.data.length % 24 > 0) {
                    const exrea = taskData.data.length % 24;
                    const addArr = 24 - exrea;
                    for (let i = 0; i < addArr; i++) {
                        taskData.data.push({
                            createUser: ''
                        })
                    }
                }
            }
            this.setState({
                data: this.sortMethods(taskData.data) || [],
                total: taskData.totalRow || 0
            })
        }
    }

    // 排序
    sortMethods = (data) => {
        data && data.length > 0 && data.map((dataItem, dataIndex) => {
            dataItem.tasks && dataItem.tasks.length > 0 && dataItem.tasks.map((taskItem, taskIndex) => {
                if (taskItem.type == "Answer") {
                    dataItem.tasks[0] = taskItem
                }
            })
        })
        return data
    }

    /**
     * 卡片 点击
     * @param key
     */
    handleItemClick = (object) => {
        this.props.handleRecord()
        sessionStorage.setItem('subjectsState', object.subjectState);
        // 记录点击条目数据
        object && sessionStorage.setItem('photoEditItem', JSON.stringify(object));
        let menuModule = localStorageUtil.get('menuModule', true);
        menuModule.levelThreeTitle = sessionStorage.getItem('targetName') || '受试者';
        menuModule.levelTwoUrl = '/data/workbench';
        localStorageUtil.set('menuModule', menuModule, true);
        PubSub.publish('onMenuModuleChange', menuModule);

        //Active => '受试者页面' Init => '入排'
        this.props.history.push({
            pathname: '/data/workbench/all/subjects',
            state: object.subjectCode,
            stateFlag: object.subjectState,
            tenantCode: this.props.tenantCode,
            isNameSearch: this.props.isNameSearch,
            projectId: this.props.projectId,
            subjectScaleReportState: this.props.subjectScaleReportState
        })
    }

    /**
     * 卡片 icon图标颜色
     */
    handleCardFormIcon = (u, uVal) => {
        switch (u) {
            case 'Answer': // Answer-已回复质疑，Question-未回复质疑
                if (uVal === "Question") {
                    return <><WarningFilled /> </>
                }
                break;
            case 'Input':
                if (uVal === "Running") {
                    return <span>{this.formatIcon('iconluru2')}</span>
                } else if (uVal === 'Complete') {
                    return <span>{this.formatIcon('iconInput_Complete')}</span>
                } else if (uVal === 'Wait') {
                    return <span>{this.formatIcon('iconInput_Wait')}</span>
                }
                break;
            case 'Check':
                if (uVal === "Running") {
                    return <span>{this.formatIcon('iconxitonghecha')}</span>
                } else if (uVal === 'Complete') {
                    return <span>{this.formatIcon('iconCheck_Complete')}</span>
                } else if (uVal === 'Question') {
                    return <span>{this.formatIcon('iconCheck_Question')}</span>
                } else if (uVal === 'Wait') {
                    return <span>{this.formatIcon('iconCheck_Wait')}</span>
                }
                break;
            case 'Question':
                if (uVal === "Running") {
                    return <span>{this.formatIcon('iconrengonghecha')}</span>
                } else if (uVal === 'Complete') {
                    return <span>{this.formatIcon('iconQuestion_Complete')}</span>
                } else if (uVal === 'Question') {
                    return <span>{this.formatIcon('iconQuestion_Question')}</span>
                } else if (uVal === 'Wait') {
                    return <span>{this.formatIcon('iconQuestion_Wait')}</span>
                } else if (uVal === 'Answer') {
                    return <span>{this.formatIcon('iconQuestion_Answer')}</span>
                }
                break;
            case 'Examine':
                if (uVal === "Running") {
                    return <span>{this.formatIcon('iconshujuhecha')}</span>
                } else if (uVal === 'Complete') {
                    return <span>{this.formatIcon('iconExamine_Complete')}</span>
                } else if (uVal === 'Question') {
                    return <span>{this.formatIcon('iconExamine_Question')}</span>
                } else if (uVal === 'Wait') {
                    return <span>{this.formatIcon('iconExamine_Wait')}</span>
                } else if (uVal === 'Answer') {
                    return <span>{this.formatIcon('iconExamine_Answer')}</span>
                }
                break;
            case 'Freeze':
                if (uVal === "Running") {
                    return <span>{this.formatIcon('icondongjie1')}</span>
                } else if (uVal === 'Complete') {
                    return <span>{this.formatIcon('iconFreeze_Complete')}</span>
                } else if (uVal === 'Wait') {
                    return <span>{this.formatIcon('iconFreeze_Wait')}</span>
                }
                break;
            case 'Autograph':
                if (uVal === "Running") {
                    return <span>{this.formatIcon('iconqianming1')}</span>
                } else if (uVal === 'Complete') {
                    return <span>{this.formatIcon('iconAutograph_Complete')}</span>
                } else if (uVal === 'Wait') {
                    return <span>{this.formatIcon('iconAutograph_Wait')}</span>
                }
                break;
            case 'Lock':
                if (uVal === "Running") {
                    return <span>{this.formatIcon('iconsuoding2')}</span>
                } else if (uVal === 'Complete') {
                    return <span>{this.formatIcon('iconLock_Complete')}</span>
                } else if (uVal === 'Wait') {
                    return <span>{this.formatIcon('iconLock_Wait')}</span>
                }
                break;
            case 'Complete':
                if (uVal === "Running") {
                    return <span>{this.formatIcon('iconsuoding2')}</span>
                } else if (uVal === 'Complete') {
                    return <span>{this.formatIcon('iconLock_Complete')}</span>
                } else if (uVal === 'Wait') {
                    return <span>{this.formatIcon('iconLock_Wait')}</span>
                }
                break;
            case 'Wait':
                if (uVal === "Running") {
                    return <span>{this.formatIcon('iconsuoding2')}</span>
                } else if (uVal === 'Complete') {
                    return <span>{this.formatIcon('iconLock_Complete')}</span>
                } else if (uVal === 'Wait') {
                    return <span>{this.formatIcon('iconLock_Wait')}</span>
                }
                break;
            case 'Confirm':
                if (uVal === "Running") {
                    return <span>{this.formatIcon('icondairuzu')}</span>
                }
                break;
            default:
        }
    }

    /**
     * 访视列表 每条数据左侧的line颜色
     */
    handleCardTestIconColor = (c) => {
        switch (c) {
            case 'Answer':
                return 'answer'
                break;
            case 'Check':
            case 'Question':
            case 'Examine':
                return 'warn'
                break;
            case 'Input':
            case 'Freeze':
            case 'Autograph':
            case 'Lock':
            case 'Complete':
            case 'Wait':
            case 'Confirm':
                return 'running'
                break;
            /*case 'Running':
                return 'running'
                break;
            case 'Complete':
                return 'complete'
                break;
            case 'Wait':
                return 'wait'
                break;*/
        }
    }

    /**
     * icon 图标
     */
    formatIcon = iconName => {
        return iconName ? (<IconFont type={iconName} />) : undefined;
    }

    showTasks = (item) => {
        let currentRole = sessionStorage.getItem('roleCode');
        let show = true
        if (this.props.projects) {
            let Index = this.props.projects.findIndex(v => v.projectCode == item.code)
            if (this.props.projects[Index].roleCode == 'ReadOnly') {
                show = false
            }
        }

        if (currentRole == 'EDC_READ') {
            show = false
        }
        if (show) {
            return (
                <div>
                    {
                        item.subjectState == 'Pass' ? <p className='card_bottom gray'>入组筛选失败</p> :
                            item.tasks.length === 0 ? <p>暂无</p> :
                                <p
                                    className={`card_bottom ${item.tasks[0].message ? this.handleCardTestIconColor(item.tasks[0].type) : ''}`}
                                    key={item.tasks[0].number}
                                >
                                    {item.tasks[0].message ? this.handleCardFormIcon(item.tasks[0].type, item.tasks[0].state) : ''}
                                    {item.tasks[0].message ? item.tasks[0].message : '暂无'}
                                </p>
                    }
                </div>
            )
        }
    }

    render() {
        const progressFn = (item) => {
            //Complete => 完成，Running => 进行中，Question => 有质疑，Answer => 质疑已回复，Wait => 未开始
            let progressCon = [];
            if (item.progress.length === 1) {
                let clName = ''
                if (item.progress[0] === "Running") {
                    clName = 'running'
                } else if (item.progress[0] === 'Complete') {
                    clName = 'complete'
                } else if (item.progress[0] === 'Question') {
                    clName = 'question'
                } else if (item.progress[0] === 'Answer') {
                    clName = 'answer'
                }
                progressCon.push(<span className={clName} style={{ width: '20%' }}></span>)
                progressCon.push(<span style={{ width: '100%' }}></span>)
            } else {
                item.progress.map((i, v) => {
                    let clName = ''
                    if (i === "Running") {
                        clName = 'running'
                    } else if (i === 'Complete') {
                        clName = 'complete'
                    } else if (i === 'Question') {
                        clName = 'question'
                    } else if (i === 'Answer') {
                        clName = 'answer'
                    }
                    progressCon.push(<span className={clName} key={v}></span>)
                })
            }
            return progressCon
        };

        return (
            <div className={'all'}>
                <div className={'all_content'}>
                    {
                        this.state.data && this.state.data.length !== 0 ?
                            <List
                                grid={{ column: 6 }}
                                dataSource={this.state.data}
                                rowKey="id"
                                pagination={{
                                    defaultCurrent: this.props.tasksData.page || 1,
                                    current: this.props.tasksData.page,
                                    onChange: page => {
                                        this.props.getTasks(this.props.productCode, page)
                                    },
                                    pageSize: 24,
                                    pageSizeOptions: [24],
                                    total: this.state.total,
                                    showSizeChanger: false,
                                }}
                                renderItem={item => (
                                    item.createUser != '' ?
                                        <List.Item
                                            className={'listItem'}
                                            onClick={() => (this.handleItemClick(item))}
                                            key={item.subjectCode}
                                        >
                                            <Card bordered={false}>
                                                <div>
                                                    <div className={'card_top'}>
                                                        <div className={'float_set'}>
                                                            <div hidden={ this.props.project?.editModel === 0 }  className={'form_info'}> 
                                                                {item.subjectName && <span>{item.subjectName || "未知"}-{item.sex || "未知"}-{item.age ? item.age+"岁" : "未知"}</span>}
                                                                {item.bindState === 1 && <Button
                                                                    onClick={(event)=> {
                                                                        event.stopPropagation()

                                                                        const i = this.state.data.findIndex(({subjectCode})=> subjectCode === item.subjectCode)

                                                                        const newData = [...this.state.data]

                                                                        newData[i].unread = 0

                                                                        // 清空未读数量
                                                                        this.setState({
                                                                            data: newData,
                                                                            showChatModal: true,
                                                                            subjectCode: item.subjectCode,
                                                                        })
                                                                    }}
                                                                    className='chat'
                                                                    type='primary'
                                                                    shape='round'
                                                                    size="small"
                                                                    icon={<MessageTwoTone style={{fontSize: 14}}/>}>
                                                                        {item.unread > 0 && <i>{item.unread}</i>}
                                                                    </Button>}
                                                            </div>
                                                            <div className={'user'}>
                                                                {
                                                                    this.formatIcon('iconyishengicon')
                                                                }
                                                                <span>{item.createUser}</span>
                                                            </div>
                                                        </div>
                                                        <div className={'info'}>
                                                            <div className={'left'}>
                                                                {
                                                                    this.formatIcon('iconrenyuandanganguanli')
                                                                }
                                                            </div>
                                                            <div className={'right'}>

                                                                {/* {
                                                                    this.props.tenantCode === 'TENANT-041' ? (
                                                                        <>
                                                                        <p className='subjectCode'>{item.subjectName || '暂无'}</p>
                                                                        <p>{item.subjectCode}</p>
                                                                        
                                                                        </>
                                                                    ) : (
                                                                        <p className='subjectCode'>{item.subjectCode}</p>
                                                                    )
                                                                } */}
                                                                {
                                                                    (this.props.isNameSearch === 1) ? (
                                                                        <>
                                                                            <p className='subjectCode'>{item.subjectName || '暂无'}</p>
                                                                            <p>{item.subjectCode}</p>
                                                                        </>
                                                                    ) : (
                                                                        <p className='subjectCode'>{item.subjectCode}</p>
                                                                    )
                                                                }

                                                                <p>{item.projectName}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='group_name'>{item.groupName}</div>
                                                    {
                                                        eproProjectCodes.includes(item.projectCode) && item.subjectName ? <div className='group_name'>{sessionStorage.getItem('targetName') || '受试者'}简称：{item.subjectName}</div> : ''
                                                    }
                                                    <div className={'card_middle'}>
                                                        <div className={'progress'}>
                                                            {
                                                                item.progress.length === 0 ? '' : progressFn(item)
                                                            }
                                                        </div>
                                                    </div>
                                                    {
                                                        this.showTasks(item)
                                                    }

                                                </div>
                                            </Card>
                                        </List.Item> :
                                        <List.Item
                                            className={'listItem'}
                                            key={item.subjectCode}
                                        >
                                            <Card bordered={false}>
                                                <div style={{ width: '100%', height: 107, background: '#fff' }}></div>
                                            </Card>
                                        </List.Item>
                                )
                                }
                            /> :
                            <List
                                grid={{ column: 6 }}
                                dataSource={this.state.dataEmpty}
                                rowKey="id"

                                pagination={{
                                    current: this.props.tasksData.page || 1,
                                    pageSize: 24,
                                    pageSizeOptions: [24],
                                    showSizeChanger: false,
                                }}
                                renderItem={item => (
                                    <List.Item
                                        className={'listItem'}
                                        key={item.subjectCode}
                                    >
                                        <Card>
                                            <div style={{ width: '100%', height: 107, background: '#fff' }}></div>
                                        </Card>
                                    </List.Item>
                                )
                                }
                            />
                    }
                </div>
                {/* 聊天窗口 */}
                <ChatModal
                 visible={this.state.showChatModal}
                 subjectCode={this.state.subjectCode}
                 onClose={()=> {
                    this.setState({
                        showChatModal: false
                    })
                }}/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    tasksData: state.getIn(['data', 'tasksData']),
})

const mapDispatchToProps = () => ({})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(all));