import React, {Fragment} from 'react';
import {Redirect} from 'react-router-dom';

class EmptyPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            path: props.location.state.path ? props.location.state.path : '',
            id: props.location.state.id ? props.location.state.id : '',
        }
    }

    render() {
        return (
            <Fragment>
                <Redirect to={{pathname: this.state.path, state: {id: this.state.id}}}/>
            </Fragment>
        )
    }
}

export default EmptyPage;