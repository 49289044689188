type Transform = {
  (value: string | null, key: string): unknown
}

type SessionStorageToken = {
  token: string;
  roleCode: string;
  roleCodes: string[];
  // 此数据从另一个key中获取合并到此
  userCode: string;
  userName: string;
  tenantName: string;
  tenantCode: string;
}

export enum Engine {
  Local = 'localStorage',
  Session = 'sessionStorage',
}

class StorageX {
  // 当前使用的存储引擎
  private _engine: Engine = Engine.Local;
  //
  private _adapter: Storage = localStorage;
  // 数据格式化
  private _formatter: Function | null = null;
  // 距离过期秒数
  private _seconds: number = 0;

  constructor(engine: Engine) {
    this._engine = engine;
    this._adapter = engine === Engine.Local ? localStorage : sessionStorage;
  }

  /**
   * 获取登录信息
   */
  user() {
    const old: [Engine, Storage] = [this._engine, this._adapter];
    if(this._engine === Engine.Local) {
      this._engine = Engine.Session;
      this._adapter = sessionStorage;
    }
    const data = this.json().get('token') as SessionStorageToken;
    const userCode = this.get('userName') as string;
    // 用后重置
    [this._engine, this._adapter] = old;

    return {
      ...data,
      userCode
    };
  }

  json() {
    this._formatter = JSON.parse;
    return this;
  }

  numeric() {
    this._formatter = Number;
    return this;
  }

  bool() {
    this._formatter = (value: string)=> {
      return ['true', '1', 'ok', 'yes', 'y'].includes(value.toLowerCase())
    }
    return this;
  }

  /**
   * 获取所有缓存名称
   * @returns 
   */
  keys() {
    return Object.keys(this._engine)
  }

  /**
   * 获取所有数据
   * @param defaultValue 默认值
   * @param transform 数据取值后转换
   * @returns 
   */
  all(defaultValue:unknown = null, transform?: Transform) {
    return this.keys().reduce((accumulator, key)=> {
      accumulator[key] = this.get(key, defaultValue, transform)
      return accumulator
    }, {} as {[s: string]: unknown})
  }

  /**
   * 取值
   * @param key 名称
   * @param defaultValue 默认值
   * @param transform 数据转换
   * @returns 
   */
  get(key: string | string[], defaultValue:unknown = null, transform?: Transform): unknown {
    if(this._formatter instanceof Function) {
      transform = (value)=> (this._formatter as Function)(value)
    }

    const handleDefault = ()=> {
      if(typeof defaultValue === 'function') {
        return defaultValue(key);
      }
      return defaultValue ?? null
    }

    // 单数据获取
    if(typeof key === 'string') {
      const value = this._adapter.getItem(key)
      // 无效数据时
      if(['undefined', 'null', null].includes(value)) {
        return handleDefault()
      }

      // 转换数据
      if(typeof transform === 'function') {
        const transformed = transform.apply(undefined, [value, key]);
        // 用后重置
        this._formatter = null
        // 
        if(transformed && typeof transformed === 'object') {
          if(Object.hasOwn(transformed, '$$data') && Object.hasOwn(transformed, '$$expire')) {
            const {$$data, $$expire} = transformed as {$$expire: number, $$data: unknown};
            if($$expire) {
              const now = Math.ceil(new Date().getTime() / 1000)
              if($$expire >= now) {
                return $$data;
              }
            }
            // 过期删除
            this.remove(key);
            return handleDefault()
          }
        }
        return transformed
      }

      return value
    }

    // 批量取值
    if(Array.isArray(key)) {
      return key.reduce((accumulator, current)=> {
        accumulator.push(this.get(current, defaultValue, transform))
        return accumulator
      }, [] as unknown[])
    }
    return []
  }

  expire(seconds: number) {
    this._seconds = seconds
    return this;
  }

  /**
   * 设置缓存
   * @param key 
   * @param value 
   */
  set(key: string | string[], value: unknown) {
    if(typeof key === 'string') {
      key = [key]
    }

    // 过期时间处理
    if(this._seconds > 0) {
      const now = Math.ceil(new Date().getTime() / 1000);
      value = {
        $$expire: now + this._seconds,
        $$data: value
      }
      // 用后重置
      this._seconds = 0
    }
    
    if(Array.isArray(key)) {
      key.map((name)=> {
        this._adapter.setItem(name, typeof value === 'string' ? value : JSON.stringify(value))
      })
    }
  }

  /**
   * 删除缓存
   * @param key 
   */
  remove(key: string | string[]) {
    if(typeof key === 'string') {
      key = [key]
    }
    if(Array.isArray(key)) {
      key.map((name)=> {
        this._adapter.removeItem(name)
      })
    }
  }

  /**
   * 清空缓存
   */
  empty() {
    this._adapter.clear()
  }
}

export default (engine: Engine = Engine.Local)=> new StorageX(engine)
