import modStyl from './CallPhoneNumber.module.scss'
import React, { useState } from 'react';
import { Button } from 'antd';
import { PhoneFilled } from '@ant-design/icons';
import { formatSeconds } from './utils';
import { callPhoneNumber, hangUp, logoff } from './cc/aiccyun';

type ButtonProps = {
  onClick: ()=> void
}
const CallButton: React.FC<ButtonProps> = ({onClick})=> {
  return (
    <Button onClick={onClick} type='primary' className={modStyl.button}>
      <PhoneFilled style={{transform: 'rotate(180deg)'}}/>
      <span>拨打电话</span>
    </Button>
  )
}

const HangUpButton: React.FC<ButtonProps> = ({onClick})=> {
  return (
    <Button onClick={onClick} danger={true} type='primary' className={modStyl.button}>
      <PhoneFilled style={{transform: 'rotate(224deg)'}}/>
      <span>挂断电话</span>
    </Button>
  )
}

type Props = {
}

let timer: any;

const CallPhoneNumber: React.FC<Props> = ()=> {
  // 0:初始(挂断通话) 1:振铃中 2:通话中
  const [status, setStatus] = useState(0)
  const [seconds, setSeconds] = useState(0)
  
  const handleHangup = ()=> {
    clearInterval(timer)
    setSeconds(0)
    setStatus(0)
    hangUp()
  }

  const handleCall = ()=> {
    callPhoneNumber('15212429979')
    // 拨打状态
    setStatus(1)
    // 模拟通话中
    setTimeout(()=> {
      setStatus(2)
      timer = setInterval(()=> setSeconds((preSeconds)=> preSeconds+1), 1000)
    }, 2000)
  }
  return (
    <div className={modStyl.callPhoneNumber}>
      <div className={modStyl.r}>
        {status === 0 && <span>&nbsp;</span>}
        {status === 1 && <span className={modStyl.ringing}>拨号中...</span>}
        {status === 2 && <span className={modStyl.seconds}>{formatSeconds(seconds)}</span>}
      </div>
      <div className={modStyl.r}>
        {
          status === 0 ? <CallButton onClick={handleCall}/> : <HangUpButton onClick={handleHangup}/>
        }
      </div>
    </div>
  )
}

export default CallPhoneNumber