/**
 * 拒绝
 */
import React, { Component } from 'react';
import { Modal, Button, Form, Input, Select, Radio, message, Timeline, List, Avatar, Tag } from 'antd';
import {
    createFromIconfontCN, EditOutlined, CloseOutlined, ExclamationCircleOutlined,
    StepBackwardOutlined, StepForwardOutlined
} from '@ant-design/icons';
import './index.less'

import {
    GetQueryListDetails, CloseImageQuestion, ReplyImageQuestion
} from "../../../../../../services/dct/DctService";

import PreviewCom from './preview';
import DetailsCom from './details';


const { Option } = Select;
const { TextArea } = Input;
let timeInterval;
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2283082_862m1tbppl6.js',//icon-shanchu icon-tongji icon-quxiao icon-baocun icon-quyufangwenqushi icon-tianjia1 icon-xiugai
    ],
});

class DetailsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            selectValue: null, // 选中的value
            ImgList: null,
            detailsData: null, // 详情数据
            taskId: null, // 任务id
            isAddQuery: true, // 是否添加质疑
            closeQuery: false, // 是否关闭质疑
            questionStatus: ''
        }
    }
    formRef = React.createRef();

    componentDidMount = () => {
        let { taskId, visible } = this.props;
        if (visible && taskId) {
            this.getDetailsData(taskId)
            this.setState({
                taskId,
                closeQuery: false
            })
        }
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps) {
            let { taskId, visible } = nextProps;
            if (visible && nextProps != this.props.taskId) {
                this.getDetailsData(taskId)
            }
            this.setState({
                taskId,
                closeQuery: false
            })
        } else {
            this.getDetailsData(this.props.taskId)
        }
    }

    // 获取详情
    getDetailsData = async (id) => {
        let params = {
            id
        }
        const res = await GetQueryListDetails(params);
        if (res && res.code == 0) {
            this.setState({
                detailsData: res.data,
                questionStatus: res.data.questionStatus
            })
        }
    }

    /**
     * 确认
     */
    handleOk = (values) => {
        let _this = this;
        let { taskId } = _this.state
        Modal.confirm({
            title: '关闭质疑',
            icon: <ExclamationCircleOutlined />,
            content: '关闭质疑后不可再重新质疑，当前如有输入内容会被放弃，是否确认关闭？',
            okText: '确认',
            onOk: function () {
                let params = {
                    id: taskId || sessionStorage.getItem('taskId'),
                    rejectReason: ""
                }
                CloseImageQuestion(params).then(res => {
                    if (res && res.code == 0) {
                        message.success('已关闭质疑！')
                        _this.setState({
                            isAddQuery: true,
                            closeQuery: true
                        })
                        _this.props.getPageDataFun()
                    }
                })
            },
            cancelText: '取消',
        });
    };

    /**
     * 取消
     */
    handleCancel = () => {
        this.props.closeModal();
    };

    /**
     * icon 图标
     */
    formatIcon = iconName => {
        return iconName ? (<IconFont type={iconName} />) : undefined;
    }

    // 获取详情信息
    getDetailsValue = (id, type) => {
        this.props.getValue(id, type)
    }

    // 上一条
    onPrev = () => {
        let { taskId } = this.state;
        this.props.getValue(taskId || sessionStorage.getItem('taskId'), 'prev')
    }
    // 下一条
    onNext = () => {
        let { taskId } = this.state;
        this.props.getValue(taskId || sessionStorage.getItem('taskId'), 'next')
    }

    // 补充质疑
    addQuery = () => {
        this.setState({
            isAddQuery: false,
            rejectReason: ''
        })
    }

    // 回复质疑
    replyQuery = (v) => {
        let { taskId } = this.state;
        let params = {
            id: taskId || sessionStorage.getItem('taskId'),
            rejectReason: v
        }
        if (!v || v == '') {
            message.error('质疑内容不能为空！')
            return
        }
        ReplyImageQuestion(params).then(res => {
            if (res && res.code == 0) {
                message.success('回复成功！')
                this.setState({
                    isAddQuery: true
                })
                this.getDetailsData(taskId)
            }
        })
    }

    // 获取取消回复状态
    getQueryStatus = (v) => {
        this.setState({
            isAddQuery: v
        })
    }

    render() {
        const { loading, selectValue, ImgList, detailsData, isAddQuery, closeQuery, rejectReason } = this.state;
        const { visible, formName, turnStatue, questionStatus } = this.props;

        return (
            <>
                <Modal
                    className={'queryModals'}
                    visible={visible}
                    title={formName}
                    onCancel={this.handleCancel}
                    width={'60%'}
                    style={{minWidth: 900}}
                    centered
                    destroyOnClose={true}
                    footer={[
                        <div className={'pageTurn'}>
                            <Button
                                type="link"
                                onClick={this.onPrev}
                                disabled={turnStatue == 'prev' ? true : false}
                            > 上一条</Button>
                            <Button
                                type="link"
                                onClick={this.onNext}
                                disabled={turnStatue == 'next' ? true : false}
                            >下一条 </Button>
                        </div>,
                        <div hidden={questionStatus == '3' || this.state.questionStatus == '已关闭' ? true : closeQuery}>
                            <Button
                                type="link"
                                key="back"
                                onClick={this.addQuery}
                                hidden={!isAddQuery}
                            >补充质疑</Button>
                            <Button
                                key="submit"
                                type="primary"
                                onClick={this.handleOk}
                            >关闭质疑</Button>
                        </div>

                    ]}
                >
                    <div className='content_wrap'>
                        <div className='previewContent'>
                            <PreviewCom
                                data={detailsData}
                            />
                        </div>
                        <div className='detailContent'>
                            <DetailsCom
                                data={detailsData}
                                getValue={(v, t) => this.getDetailsValue(v, t)}
                                turnStatue={this.props.turnStatue}
                                addQueryStatus={isAddQuery}
                                getQueryStatus={(v) => this.getQueryStatus(v)}
                                replyQueryFun={(v) => this.replyQuery(v)}
                                rejectReason={rejectReason}
                            />
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}

export default DetailsModal;