import React, { useEffect, useRef, useState } from "react"

import indexStyl from './index.module.scss'
import Condition, { Params } from "./components/Condition"
import { Button, Empty, Table } from "antd"
import { ColumnsType } from "antd/lib/table"
import PhoneModal from "./components/PhoneModal"
import SendModal from "./components/SendModal"
import DetailModal, { CommandEnum } from "./components/DetailModal"
import CompleteModal from "./components/CompleteModal"
import { getVisitList } from "../../../services/dct/visit"
import UserInfo from "./components/UserInfo"
import DictTransform from "./components/DictTransform"
import { loginex, logout } from "./components/cc/aiccyun"
import { formatDateTime } from "./components/utils"
import SubjectModal from "./components/SubjectModal"

export type DataResult = {
  code: number;
  message?: string;
  data: {
    page: number;
    pageSize: number;
    total: number;
    rows: DataType[];
  }
}

export type DataType = {
  adminName: string;
  adminUser: string;
  baseDate: string;
  callLimitTimes: null | string;
  callMissTimes: null | string;
  centerCode: string;
  centerName: string;
  createTime: string;
  createUser: null | string;
  endDate: string;
  failCause: null | string;
  id: number;
  isDeleted: null | boolean;
  msgContent: null | string;
  projectCode: string;
  projectName: string;
  remark: null | string;
  ruleCode: string;
  startDate: string;
  subjectCode: string;
  subjectMobile: string;
  subjectName: null | string;
  updateTime: null | string;
  updateUser: null | string;
  visitContent: string;
  visitStatus: number;
  visitTime: null | string;
  visitType: number;
  visitUser: null | string;
  visitUserName: null | string;
  // 本人还是家属
  txr: null | number;
  // 性别
  xb: null | number;
  // 受教育程度
  jycb: null | number;
  // 居住城市
  jzcs: null | string;
}

type VisitTaskContext = {
  openSubjectModal: (subjectCode: string)=> void;
  reload: ()=> void;
}
export const visitTaskContext = React.createContext<VisitTaskContext>({
  openSubjectModal: ()=> {},
  reload: ()=> {},
})

const VisitTask = ()=> {

  const [current, setCurrent] = useState<DataType>()
  // 详情
  const [visible, setVisible] = useState(false)
  // 短信随访
  const [visibleSend, setVisibleSend] = useState(false)
  // 电话随访
  const [visiblePhone, setVisiblePhone] = useState(false)
  // 完成随访
  const [visibleComplete, setVisibleComplete] = useState(false)
  // 患者详情
  const [subjectCode, setSubjectCode] = useState('')
  const [visibleSubject, setVisibleSubject] = useState(false)
  
  const [pageSize] = useState(20)
  const [pageNum, setPageNum] = useState(1)
  
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)

  const [dataSource, setDataSource] = useState<DataType[]>([])

  const conditionRef = useRef<Params>({
    subjectCode: "",
    projectCode: "",
    visitStatus: 0, 
    centerCode: "",
    adminUser: "",
    startTime: "",
    endTime: ""
  })
  
  //#region 表格列
  const columns: ColumnsType<DataType> = [
    {
      width: 80,
      title: '任务ID',
      align: 'right',
      dataIndex: 'id',
    },
    {
      width: 120,
      title: '所属项目',
      ellipsis: true,
      dataIndex: 'projectName',
      render: (text: string) => <a>{text}</a>,
    },
    {
      width: 120,
      title: '所属中心',
      dataIndex: 'centerName',
    },
    {
      width: 120,
      title: '采集人',
      dataIndex: 'adminName',
    },
    {
      width: 160,
      title: '患者',
      dataIndex: 'subjectName',
      render: (_: string, record: DataType)=> {
        return <UserInfo data={record} simple/>
      }
    },
    {
      width: 100,
      title: '随访类型',
      dataIndex: 'visitType',
      render: (value: number)=> <DictTransform name="visitType" value={value}/>
    },
    {
      width: 100,
      ellipsis: true,
      title: '随访内容',
      dataIndex: 'visitContent'
    },
    {
      width: 160,
      title: '随访时间',
      dataIndex: 'createTime',
      render: (value: string | null)=> {
        if(!value) {
          return '--'
        }
        return value.split('T').shift()
      }
    },
    {
      width: 100,
      title: '随访状态',
      dataIndex: 'visitStatus',
      render: (value: number)=> <DictTransform name="visitStatus" value={value}/>
    },
    {
      width: 100,
      title: '随访人员',
      dataIndex: 'visitUserName'
    },
    {
      width: 160,
      title: '实际随访时间',
      dataIndex: 'visitTime',
      render: (value: string | null)=> formatDateTime(value)
    },
    {
      width: 240,
      title: '操作',
      key: 'action',
      render: (_: any, record: DataType) => (
        <>
          <Button onClick={()=> {
            setCurrent(record)
            setVisible(true)
          }} type="primary" size="small">查看详情</Button>
          <Button onClick={()=> {
            setCurrent(record)
            setVisiblePhone(true)
          }} size="small" style={{marginLeft: 5}}>电话随访</Button>
          {
            record.visitType === 2 && (
              <Button onClick={()=> {
                setCurrent(record)
                setVisibleSend(true)
              }} size="small" style={{marginLeft: 5}}>短信提醒</Button>
            )
          }
        </>
      ),
    },
  ];
  //#endregion

  const fetchData = async (params: Params, reload=false)=> {
    setLoading(true)
    const {code, data} = await getVisitList(params, reload ? 1 : pageNum, pageSize)
    if(reload) {
      setPageNum(1)
    }
    setLoading(false)
    if(code === 0) {
      setTotal(data.total)
      setDataSource(data.rows)
    }
  }

  const onSearch = (params: Params)=> {
    conditionRef.current = params
    fetchData(params, true)
  }

  const handleCommand = (type: CommandEnum)=> {
    if(type === CommandEnum.Phone) {
      setVisiblePhone(true)
    } else if (type === CommandEnum.Send) {
      setVisibleSend(true)
    } else {
      setVisibleComplete(true)
    }
  }

  // useEffect(()=> {
  //   loginex((dd: any)=> {
  //     console.log('?loginex-callback', dd)
  //   }).then(()=> {
      
  //   }).catch(()=> {})

  //   return ()=> {
  //     logout()
  //   }
  // }, [])

  const providerValue = {
    openSubjectModal: (code: string)=> {
      setSubjectCode(code)
      setVisibleSubject(true)
    },
    reload: ()=> {
      if(visible) {
        setVisible(false)
      }
      fetchData(conditionRef.current, true)
    }
  }

  return (
    <visitTaskContext.Provider value={providerValue}>
      <div className={indexStyl.wrapper}>
        <Condition onSearch={onSearch}/>
        <div className={indexStyl.tableWrapper}>
          <Table
            loading={loading}
            size="middle"
            rowKey="id"
            scroll={{y: window.innerHeight - 350}}
            columns={columns}
            dataSource={dataSource}
            locale={{emptyText: <Empty description="暂无数据"/>}}
            pagination={{
              total: total,
              current: pageNum,
              pageSize: pageSize,
              hideOnSinglePage: true,
              onChange: (page: number)=> {
                setPageNum(page)
                fetchData(conditionRef.current)
              },
              showSizeChanger: false,
              showQuickJumper: false,
              position: ["bottomRight"]}}/>
        </div>
      </div>
      {
        visibleSend && current && (
          <SendModal current={current} onClose={()=> setVisibleSend(false)}/>
        )
      }
      {
        visiblePhone && current && (
          <PhoneModal current={current} onClose={()=> setVisiblePhone(false)}/>
        )
      }
      {
        visible && current && (
          <DetailModal current={current} onCommand={handleCommand} onClose={()=> setVisible(false)}/>
        )
      }
      {
        visibleComplete && current && (
          <CompleteModal current={current} onClose={()=> setVisibleComplete(false)}/>
        )
      }
      {
        visibleSubject && subjectCode && (
          <SubjectModal subjectCode={subjectCode} onClose={()=> setVisibleSubject(false)}/>
        )
      }
    </visitTaskContext.Provider>
  )
}

export default VisitTask
