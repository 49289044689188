/**
 * 项目报表
 */
import React from 'react';
import './index.less';
import { Select, DatePicker, Button, Input, Modal, Upload, message, Row , Col} from "antd";
import CaseAuditList from "./list";
import 'moment/locale/zh-cn';
import moment from 'moment';
import { downLoadAuditList, importAccountExcel, GetAccountTemplate, GetProjectEnume, GetTenantSelects, GetProjectCenter } from '../../../../services/dct/DctService';
import { DownloadOutlined, CloudUploadOutlined} from '@ant-design/icons';
import utils from "../../../../util/utils";
import locale from 'antd/es/date-picker/locale/zh_CN';
const { Dragger } = Upload;
const { Option } = Select;
let _this;

const { RangePicker } = DatePicker;
class DctCaseAudit extends React.Component {

    constructor(props) {
        super(props);
        //  创建ref容器
        this.childRef = React.createRef();

        _this = this;
        _this.tenantProjectMap = {};
        _this.state = {
            selectTabPane: '1', // 报表类型 1DAY,2WEEK,3MONTH
            startDate: moment(new Date()).format('YYYY-MM-DD'), // 开始时间
            endDate: moment(new Date()).format('YYYY-MM-DD'), // 结束时间
            projects: [], // 项目枚举
            centerList: [], // 项目下面的中心枚举
            centerCode: undefined,
            subjectCode:"",
            tenants: [],
            auditState: '',
            tenantCode: '',
            projectCode: '',
            selectCont: 0, // 勾选数量
            tenantProjects: [], // 租户对应的项目
            showTenanProject: false,
            isModalOpen: false,
            errorVoList: [],
            btnLoading: false,
            fileList: []
        }
    }

    componentDidMount() {
        // this.getProjectEnume()
        this.getTenantAndAudit();
    }

    // 获取项目枚举
    getProjectEnume = () => {
        GetProjectEnume().then(res => {
            if (res) {
                this.setState({
                    projects: res
                })
            }
        })
    }

    getTenantAndAudit = () => {
        GetTenantSelects().then(res => {
            if (res.code == 0 && res.data) {
                let allProject = [];
                (res.data || []).forEach(item => {
                    _this.tenantProjectMap[item.code] = item;
                    allProject = [...allProject, ...item.selects];
                });
                this.setState({
                    tenants: res.data || [],
                    projects: allProject
                })
            }
        })
    }
    /**
     * 切换tab
     */
    onTabClick = (val) => {
        this.setState({
            selectTabPane: Number(val)
        })
    }

    // 选择审核状态
    handleSelectChangeAudit = (value) => {
        this.setState({
            auditState: value
        }, () => {
           this.changeFilterData()
        })

    }

    // 选择租户
    handleSelectChangeTenant = (value) => {
       
        let projectCode = this.state.projectCode;
        if (value) {
            // 选择租户选项
            let selectItem = _this.tenantProjectMap[value];
            projectCode = ''; // 选择租户时，项目选为所有。租户和项目有联动效果
            this.setState({
                tenantCode: value,
                projectCode: projectCode,
                tenantProjects: selectItem.selects,
                showTenanProject: true,
            },()=>{
                this.changeFilterData()
            })
        } else {
            // 选择全部
            this.setState({
                tenantCode: value,
                tenantProjects: [],
                showTenanProject: false
            },()=>{
                this.changeFilterData()
            })
        }
      
       
    }

    // 选择项目
    handleSelectChange = (value) => {
        this.setState({
            projectCode: value,
            centerCode: undefined
        }, () => {
            this.getProjectCenter(value)
            this.changeFilterData()
        })

    }

    getProjectCenter = (projectCode) => {

        if (projectCode) {
            let params = {
                projectCode
            }
            GetProjectCenter(params).then(res => {
                if (res.code == 0) {
                    this.setState({
                        centerList: res.data
                    })
                }
            })
        } else {
            this.setState({
                centerList: []
            })
        }
    }


    handleCenterChange = (value) => {
        this.setState({
            centerCode: value
        }, () => {
            this.changeFilterData()
        })

    }
    handleSubjectCodeChange = (e) => {
        let value = e.currentTarget.value;
       
        this.setState({
            subjectCode: value
        }, () => {
           this.changeFilterData()
        })

    }
    handleCreateUserChange = (e) => {
        let value = e.currentTarget.value;
        // if (value.length !== 11 && value.length !== 0) {
        //     return;
        // }
        this.setState({
            createUser: value
        }, () => {
           this.changeFilterData()
        })

    }
    // 审核时间
    changeDate = (date, dateString) => {
        this.setState({
            startTime: dateString[0],
            endTime: dateString[1]
        },()=>{
            this.changeFilterData()
        })
    }
    // 更新勾选项目
    updateCount = (selectCont) => {
        this.setState({ selectCont });
    }

    // 勾选通过
    selectAllPass = () => {
        this.childRef.current.selectAllPass();
    }

    // 勾选拒绝
    selectAllReject = () => {
        this.childRef.current.selectAllReject();
    }
    // 结算标记
    handleAcoount = () =>{
        this.setState({
            isModalOpen: true
        })
    }
    // 导出数据
    exportData = () =>{
        this.setState({
            btnLoading: true
        })
        const { projectCode } = this.state
        let projectList = [];
        if (projectCode != '') {
            projectList.push(projectCode)
        }
        const params = {
            account: this.state.account,
            startTime: this.state.startTime,
            endTime: this.state.endTime,
            taskId: this.state.taskId,
            createUser: this.state.createUser,
            projectCode: this.state.projectCode,
            auditState: this.state.auditState,
            tenantCode: this.state.tenantCode,
            centerCode: this.state.centerCode,
            subjectCode: this.state.subjectCode,
            projectList: projectList
        }
        downLoadAuditList(params).then(res=>{
            this.setState({
                btnLoading: false
            })
            // 创建一个Blob URL  
            const url = window.URL.createObjectURL(new Blob([res]));
            // 创建一个<a>标签并设置其href属性为Blob URL  
            const a = document.createElement('a');
            a.href = url;
            a.download = '结算审核数据.xlsx'; // 设置你想要的文件名（包括扩展名）  
            // 触发下载  
            document.body.appendChild(a); // 必须添加到DOM中才能触发点击  
            a.click();

            // 清理（从DOM中移除并撤销Blob URL）  
            setTimeout(() => {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            }, 100); // 延迟一点以确保下载开始  
        })
            
        
    }

    // 导出
    downloadRecord = () => {
        let { auditState, createUser, tenantCode, projectCode } = this.state;
        let projectList = [];
        if (projectCode != '') {
            projectList.push(projectCode)
        }
        let url = `/data/subject/audit/downLoadAuditList`
        let params = {
            auditState,
            createUser,
            projectList,
            tenantCode
        }
        utils.exportPostFun(url, params)
    }

    // 结算标记弹窗操作
    handleOk = ()=>{
        if(!this.state.fileList?.length){
            return message.error("请上传结算标记文件")
        }
        const uploadFormData = new FormData();
        this.state.fileList.forEach(file => {
            uploadFormData.append('file', file);
        });
        importAccountExcel(uploadFormData).then(res=>{
            if(res.code == 0 ) {
                if(!res.data.length){
                    message.success("导入成功！")
                    this.setState({
                        isModalOpen: false,
                        fileList: [],
                        errorVoList:[]
                    })
                    this.changeFilterData()
                }else{
                    this.setState({
                        errorVoList: res.data,
                    })
                }
              
            }
        })
    }

    handleCancel = ()=>{
        this.setState({
            isModalOpen: false,
            errorVoList: [],
            fileList: []
        })
    }

    downLoadTmplate = ()=>{
        GetAccountTemplate().then(res => {
            // 创建一个Blob URL  
            const url = window.URL.createObjectURL(new Blob([res]));

            // 创建一个<a>标签并设置其href属性为Blob URL  
            const a = document.createElement('a');
            a.href = url;
            a.download = '结算标记模板.xlsx'; // 设置你想要的文件名（包括扩展名）  

            // 触发下载  
            document.body.appendChild(a); // 必须添加到DOM中才能触发点击  
            a.click();

            // 清理（从DOM中移除并撤销Blob URL）  
            setTimeout(() => {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            }, 100); // 延迟一点以确保下载开始  
        })
        
    }
    // 查询条件修改
    handleFilterChange = (key, val)=>{
     
        this.setState({
            [key]: val
        },()=>{
            this.changeFilterData()
        })
    }

    changeFilterData = ()=>{
        this.childRef.current.getPageData(1, null, null, null, {
            account: this.state.account,
            startTime: this.state.startTime,
            endTime: this.state.endTime,
            taskId: this.state.taskId,
            createUser: this.state.createUser,
            projectCode: this.state.projectCode,
            auditState: this.state.auditState,
            tenantCode: this.state.tenantCode,
            centerCode: this.state.centerCode,
            subjectCode: this.state.subjectCode,
            isFilter: true
        })
    }

    render() {
        let { selectTabPane, startDate, endDate, projectCode, projects, tenants, selectCont, showTenanProject, 
            tenantProjects, centerList, centerCode, subjectCode,  isModalOpen,  btnLoading, errorVoList, fileList} = this.state;
        let displayProjectList = showTenanProject ? tenantProjects : projects;

        const props = {
            name: 'file',
            accept: ".xlsx,.xls",
            maxCount: 1,
            headers: {
                authorization: 'authorization-text',
            },
            beforeUpload: file => {
                const isLt = file.size > 30*1024*1024;
                const allowedTypes = [".xlsx", ".xls"];
                const fileType = file.name.slice(file.name.lastIndexOf('.'))
              
                if(isLt){
                    message.error(`文件大小不能超过30MB`);
                }else if(!allowedTypes.includes(fileType)){
                    message.error(`请上传xlsx或者xls类型文件`);
                    
                }else{
                    this.setState({
                        fileList: [file],
                    });
                }
                return false
            },
         
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                    };
                });
            },
            fileList
          };
        return (
            <div className={'dctAccount'}>
                <div className='tabsTitle'>
                  <div className='search'>
                    <Row gutter={['8px']}>
                        <Col span={4} >
                            <span className='search_label'>任务ID：</span>
                            <Input
                                placeholder="请输入任务ID"
                                onChange={(e)=>this.handleFilterChange('taskId', e.target.value)}
                                size='middle'
                                className='search_content'
                            />
                        </Col>
                        <Col span={4} >
                            <span className='search_label'>任务编码：</span>
                            <Input
                                placeholder="请输入任务编码"
                                onChange={this.handleSubjectCodeChange}
                                size='middle'
                                className='search_content'
                            />
                        </Col>
                        <Col span={4}>
                            <span className='search_label'>上传人：</span>
                            <Input
                                placeholder="请输入上传人账号"
                                onChange={this.handleCreateUserChange}
                                size='middle'
                                className='search_content'
                            />
                        </Col>
                        <Col span={8}>
                            <span className='search_label'>审核时间：</span>
                            <RangePicker onChange={this.changeDate} locale={locale} className='search_content' />

                        </Col> 
                          
                    </Row>
                    <br/>
                    <Row gutter={['8px']}>
                        <Col span={4}>
                            <span className='search_label'>结算状态：</span>
                            <Select   className='search_content'defaultValue="全部" onChange={(e)=>this.handleFilterChange('account', e)} size='middle' >
                                <Option value={''}>全部</Option>
                                <Option key={1} value={0}>未结算</Option>
                                <Option key={2} value={1}>已结算</Option>
                            </Select>

                        </Col>
                        <Col span={4}>
                            <span className='search_label'>审核状态：</span>
                            <Select   className='search_content' defaultValue="全部" onChange={this.handleSelectChangeAudit} size='middle' >
                                <Option value={''}>全部</Option>
                                <Option key={1} value={1}>待审核</Option>
                                <Option key={2} value={2}>通过</Option>
                                <Option key={3} value={3}>未通过</Option>
                            </Select>
                        </Col>
                        <Col span={4}>
                            <span className='search_label'>租户：</span>
                            <Select  className='search_content' defaultValue="所有租户" onChange={this.handleSelectChangeTenant} size='middle' >
                                <Option value={''}>所有租户</Option>
                                {tenants.length > 0 && tenants.map(project => (
                                    <Option key={project.code} value={project.code}>{project.name}</Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={6}>
                             <span className='search_label'>项目中心：</span>
                            <Select className='search_content'  defaultValue="所有项目" value={projectCode} onChange={this.handleSelectChange} size='middle' >
                                <Option value={''}>所有项目</Option>
                                {(displayProjectList || []).map(project => (
                                    <Option key={project.code} value={project.code}>{project.name}</Option>
                                ))}
                            </Select>
                        
                        </Col>
                        <Col span={6}>
                            {/* <span className='search_label'>所属中心：</span> */}
                            <Select className='search_content' placeholder="请选择所属中心" value={centerCode} disabled={!projectCode} onChange={this.handleCenterChange} size='middle'>
                                {(centerList || []).map(project => (
                                    <Option key={project.code} value={project.code}>{project.name}</Option>
                                ))}
                            </Select>
                        </Col>
                        </Row>
                    </div>
                    <div className='multiple_select'>
                        <Button className='all_pass' type='primary' onClick={this.selectAllPass}>全部通过</Button>
                        <Button className='all_reject' type='primary' onClick={this.selectAllReject} danger>全部拒绝</Button>
                        <Button className='all_reject' type='primary' onClick={this.handleAcoount} >结算标记</Button>
                        <Button className='all_reject' type='primary' loading={btnLoading} onClick={this.exportData} >导出数据</Button>

                        {/* <Button className='all_reject' type="primary" icon={<DownloadOutlined />} size={'middle'} onClick={() => this.downloadRecord()}>
                            导出
                        </Button> */}
                        {
                            selectCont > 0 &&
                            <span className='select_count'>已选择{selectCont}个</span>
                        }
                    </div>
               
                    <CaseAuditList
                        ref={this.childRef}
                        {...this.props}
                        type={selectTabPane}
                        startDate={startDate}
                        endDate={endDate}
                        projectCode={projectCode}
                        selectTabPane={selectTabPane}
                  
                        updateCount={this.updateCount}
                    />

                    <Modal className='modal-account'  title="结算标记" visible={isModalOpen} onOk={this.handleOk} onCancel={this.handleCancel}
                       okText="导 入" cancelText="取 消">
                        <div>请先下载模板信息，更新后上传，<span className='demo-click' onClick={this.downLoadTmplate}>点击下载模板</span></div>
                        <Dragger {...props}>
                            <p className="ant-upload-drag-icon">
                                 <CloudUploadOutlined />
                            </p>
                            <p className="ant-upload-text">将文件拖到此处，或点击上传</p>
                            <p className="ant-upload-hint">
                                只能上传xlsx文件，且不超过30MB
                            </p>
                        </Dragger>
                        {
                            errorVoList.length>0 &&   
                            <div className='upload_fail_content'>
                                <div className='fail_tip'>请仔细查看下列数据，修改后可重新上传审核</div>
                                <div className='error_list'>
                                    <div className='error_item'>一共有<span>&nbsp;{errorVoList.length}&nbsp;</span>条错误:</div>
                                    {
                                        errorVoList.map((v, i) => (
                                            <div className='error_item' key={i}>{i + 1}. {v.erroy}</div>
                                        ))
                                    }
        
                                </div>
                            </div>
                        }
                      
                    </Modal>
                </div>
            </div>
        );
    }
}

export default DctCaseAudit;