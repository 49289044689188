import React, { useRef, useState, useEffect } from "react";
import moment from "moment";
import { loadMicroApp } from "qiankun";
import { Button, Modal, Select, Spin, Input, message as Message } from "antd";
import { RightOutlined, FileTextOutlined, NotificationOutlined, MessageOutlined } from "@ant-design/icons";
import { getReportedById, getVisitsByScaleCode, getSubjectBindState, getSubjectBinding, remindBySms, scanExamCode, restatement } from "@/services/data/patientSelfReported";
import "./patientSelfReported.less";
import { EPRO_SCALE_URL } from '@/comm/config'
import { QRCodeCanvas } from "qrcode.react";

const PatientSelfReported = (props) => {
  const [codeURL, setCodeURL] = useState(null);
  const { TextArea } = Input;
  const { selfReportedList, subjectCode, resumeVisitId } = props;
  // 访视信息
  const [visitInfo, setVisitInfo] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalReport, setModalReport] = useState(false)
  const [modalWarn, setModalWarn] = useState(false)
  const [currentTpl, setCurrentTpl] = useState({
    id: null,
    name: "",
  });
  const [visitList, setVisitList] = useState([]);
  const [visitId, setVisitId] = useState(null);
  const [recordList, setRecordList] = useState([]);
  const [currentRecordId, setCurrentRecordId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [patientPhone, setPatientPhone] = useState(null);
  const [bindInfo, setBindInfo] = useState({});
  const [rejectState, setRejectState] = useState('9');
  const [rejectReason, setRejectReason] = useState(null);
  const targetName = sessionStorage.getItem('targetName') || '受试者';
  // useEffect(async () => {
  //   if (visitId) {
  //     try {
  //       const { data } = await getReportedById({ scaleCode: currentTpl?.code, visitId });
  //       const tempList = (data ?? []).map(({ createTime, ...reset }) => ({
  //         ...reset,
  //         createTime: handleFormatDate(createTime),
  //       }));
  //       const recordObj = tempList[0];
  //       setRecordList(tempList);
  //       if (tempList?.length) {
  //         changeRecordId(recordObj?.scalaServerRecordId);
  //       } else {
  //         changeRecordId(null)
  //       }
  //     } finally {}
  //   }
  // }, [visitId, currentTpl.code]);

  const getItemStateText = (reportState) => {
    switch (reportState) {
      case 2:
        return "重报";
        break;
      case 1:
        return "已上报";
        break;
      case 0:
        return "未上报";
        break;
    }
  };

  useEffect(async () => {
    if (!subjectCode) return;
    await subjectBindState(subjectCode)

  }, [subjectCode]);

  const subjectBindState = async (subjectCode) => {
    try {
      const { data, code } = await getSubjectBindState(subjectCode);
      setVisitInfo(data)

    } catch (error) {
      return Promise.reject(error)
    }
  }

  // 格式化时间
  const handleFormatDate = (date) => {
    if (!date) return;
    return moment(date).format("YYYY-MM-DD HH:mm");
  };

  // 获取患者自报记录
  const getReportDetail = async (row) => {
    if (row.reportState === 0) return;
    setCurrentTpl(row);
    const { data } = await getVisitsByScaleCode({ scaleCode: row.code, subjectCode });
    const visitList = (data ?? []).map(({ id, name }) => ({
      label: name,
      value: id,
    }));
    const visitObj = visitList.find(v => v.value === resumeVisitId) ?? visitList[0]
    setVisitList(visitList);
    setVisitId(() => {
      getRecord(visitObj?.value, row.code)
      return visitObj?.value
    })
  };
  // 受试者重报提醒
  const getReportAgain = (event, row) => {
    console.log(row)
    event?.stopPropagation()
    setCurrentTpl(row);
    setModalReport(true)
    setRejectReason(null)
  }
  // 提醒受试者
  const getWarn = async (event, row) => {
    event?.stopPropagation()
    if (!visitInfo.bindState) {
      return Message.error(`请先邀请${targetName}绑定信息`);
    }
    console.log(row)
    if(!row.arrival){
      return Message.error("未到填写时间，请核查后再发起提醒！")
    }
    // 已绑定显示二维码信息
    const result = await scanExamCode({
      projectCode: visitInfo?.projectCode,
      scaleId: row.id,
      subjectCode
    })
    const url = result?.data + `&recordId=default&scaleName=${row.name}&scaleCode=${row.code}&isScan=true`
    console.log(url)
    setCodeURL(url);
    setCurrentTpl(row);
    setModalWarn(true);

  }

  // 获取受试者填报问卷记录
  const getRecord = async (visitId, scaleCode) => {
    if (!visitId || !scaleCode) return;
    try {
      const { data } = await getReportedById({ scaleCode, visitId });
      const tempList = (data ?? []).map(({ createTime, ...reset }) => ({
        ...reset,
        createTime: handleFormatDate(createTime),
      }));
      const recordObj = tempList[0];
      setRecordList(tempList);
      setIsModalOpen(true);
      if (tempList.length) {
        changeRecordId(tempList.at(-1).scalaServerRecordId);
      } else {
        changeRecordId(null)
      }
    } finally { }
  }

  // 查看报告
  const handleReport = (event, row) => {
    event?.stopPropagation?.();
  };

  // 关闭弹窗
  const handleClose = () => {
    setIsModalOpen(false);
    changeRecordId(null)
  };

  // 切换量表下的记录ID
  const changeRecordId = (id) => {
    // setLoading(true)
    setCurrentRecordId(id);
  };

  const handleVisitChange = (value) => {
    setVisitId(value);
    getRecord(value, currentTpl?.code)
  };

  const handleLoadIframe = () => {
    setLoading(false);
  };
  // 上传原因选择
  const handleChange = (val) => {
    setRejectState(val.value)
    console.log(`selected ${val}`);
  };


  // 自报提醒
  const toRemindBySms = () => {
    remindBySms({
      scaleId: currentTpl.id,
    }).then((res) => {
      const { code, message } = res
      if (code === 0) {
        Message.success("发送成功！");
      } else {
        Message.error(message)
      }
      setModalWarn(false)
    })
  }

  // 重报提醒
  const sendRemind = () => {
    if (!rejectReason) return Message.error("请输入具体原因")
    restatement({
      scaleId: currentTpl.id,
      rejectState,
      rejectReason
    }).then((res) => {
      const { code, message } = res;
      if (code === 0) {
        Message.success("发送成功！")
      } else {
        Message.error(message)
      }
      props?.refreshForms()

      setModalReport(false)
    })
  }

  return selfReportedList?.length ? (
    <div className="report-container">
      <div className="report-header">患者自报</div>
      {selfReportedList.map((sp) => (
        <div
          className="report-item"
          data-state={sp?.reportState}
          key={sp.id}
          onClick={() => getReportDetail(sp)}
        >
          <div className="report-item--title">{sp.name}</div>
          <div className="report-item--right">
            <div className="report-item--state">
              <span className="report-state--text" data-state={sp?.reportState}>
                {getItemStateText(sp?.reportState)}
              </span>
              {sp?.reportState === 1 && (
                <span className="report-state--time">{handleFormatDate(sp?.updateTime)}</span>
              )}
            </div>
            <div className="report-item--behavior">
              {sp?.reportState === 1 ? (
                <>
                  <span onClick={(e) => getReportAgain(e, sp)}>
                    重报
                    <RightOutlined />
                  </span>
                  <span>
                    问卷
                    <RightOutlined />
                  </span>
                  <span>
                    报告
                    <RightOutlined />
                  </span>
                </>
              ) : (
                <div style={{ "cursor": 'pointer' }}>
                  <span onClick={(e) => getWarn(e, sp)}>
                    提醒
                    <RightOutlined />
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
      {/* 提醒弹窗 */}
      <Modal title={visitInfo.bindState === 0 ? `${targetName}自报提醒（未绑定）` : `${targetName}自报提醒`} visible={modalWarn} footer={null} width={600} onCancel={() => { setModalWarn(false) }}>
        <div className="modal-warn">
          <div className="modal-warn-left">
            <div className="warn-title"><NotificationOutlined style={{ fontSize: '16px', color: '#6c97f7' }} />&nbsp;{visitInfo.bindState === 0 ? "线下邀请" : "线下提醒"}</div>

            <div className="warn-qrcode">
              {!!codeURL && <QRCodeCanvas value={codeURL} size={180} />}
            </div>
            <div className="modal-warn-bottom">
              <div>使用微信扫描二维码</div>
              <div>{visitInfo.bindState === 0 ? "立即绑定" : "立即填写问卷"}</div>
            </div>

          </div>
          <div className="modal-warn-right">
            <div className="warn-title"><MessageOutlined style={{ fontSize: '16px', color: '#6c97f7' }} />&nbsp;{visitInfo.bindState === 0 ? "短信邀请" : "短信提醒"}</div>

            <div className="modal-report-module">
              <div className="modal-report-content"><FileTextOutlined /><span>{currentTpl.name}</span></div>
              {/* <div className="modal-report-nums">应上报1次 / 实际上报1次</div> */}
            </div>
            {visitInfo.bindState === 0 &&
              <div className="modal-report-phone">
                <Input placeholder="请输入要邀请患者的手机号" type="number" onChange={(e) => { setPatientPhone(e.target.value) }}></Input>
              </div>
            }
            <div>
              <div className="modal-report-exam">以以下身份提醒患者上报问卷</div>
              <div className="modal-report-doctor">
                <div className="modal-report-doctor-position">{visitInfo?.centerName}</div>
                <div className="modal-report-doctor-name">{visitInfo?.doctorName}</div>
              </div>

            </div>
            <br />
            <br />
            <Button type="primary" block shape="round" onClick={toRemindBySms}>发送提醒</Button>
            {/* <div className="modal-warn-bottom " style={{ "margin-top": '12px' }}>每人每日仅能提醒一次</div> */}
          </div>
        </div>
      </Modal>
      {/* 重报提醒弹窗 */}
      <Modal title={`${targetName}自报重新上报提醒`} visible={modalReport} footer={null} width={600} onCancel={() => { setModalReport(false) }}>
        <div >
          <div className="modal-report-tips">请选择重新上报原因</div>
          <div className="modal-container">
            <div className="modal-container-left">
              <div className="modal-report-module">
                <div className="modal-report-content"><FileTextOutlined /><span>{currentTpl.name}</span></div>
                {/* <div className="modal-report-nums">应上报1次 / 实际上报1次</div> */}
              </div>
              <div>
                <div className="modal-report-exam">以以下身份提醒患者上报问卷</div>
                <div className="modal-report-doctor">
                  <div className="modal-report-doctor-position">{visitInfo?.centerName}</div>
                  <div className="modal-report-doctor-name">{visitInfo?.doctorName}</div>
                </div>
              </div>
            </div>
            <div className="modal-container-right">
              <div className="modal-container-right-select">
                <Select
                  defaultValue="9"
                  style={{ width: "100%" }}
                  onChange={handleChange}
                  options={[
                    {
                      value: '9',
                      label: '其他原因',
                    },

                  ]}
                />
              </div>
              <div className="modal-container-right-textarea">
                <TextArea rows={3} placeholder="请输入具体原因" value={rejectReason} maxLength={200} onChange={(e) => { setRejectReason(e.target.value) }} />
              </div>
              <Button type="primary" block shape="round" onClick={sendRemind}>发送提醒</Button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        title={currentTpl.name}
        visible={isModalOpen}
        wrapClassName="self-reported--modal"
        footer={null}
        width={1000}
        destroyOnClose
        maskClosable={false}
        onCancel={handleClose}
      >
        <Spin spinning={loading}>
          <div className="report-modal--container">
            <div className="report-modal--record">
              <div className="report-modal--header">
                <Select
                  value={visitId}
                  onChange={handleVisitChange}
                  bordered={false}
                  className="report-record--select"
                  options={visitList}
                />
              </div>
              <div className="report-record--content">
                {recordList.map(({ scalaServerRecordId, createTime }) => (
                  <div
                    onClick={() => changeRecordId(scalaServerRecordId)}
                    className={`record-item ${scalaServerRecordId === currentRecordId ? "active" : ""
                      }`}
                  >
                    <FileTextOutlined className="record-item--icon" />
                    {createTime}
                  </div>
                ))}
              </div>
            </div>
            <div className="report-modal--question">
              <div className="report-modal--header">评估问卷</div>
              <div className="iframe">
                {currentRecordId && (
                  <iframe
                    src={`${EPRO_SCALE_URL}epro/evaluation/${currentTpl.code}?disabled=true&recordId=${currentRecordId}`}
                    frameBorder="0"
                    height="100%"
                    width="100%"
                    id="pgwj"
                    title="评估问卷"
                  ></iframe>
                )}
              </div>
            </div>
            <div className="report-modal--result">
              <div className="report-modal--header">评估结果</div>
              <div className="iframe">
                {currentRecordId && (
                  <iframe
                    src={`${EPRO_SCALE_URL}scale/report/${currentRecordId}/${currentTpl.code}`}
                    onLoad={handleLoadIframe}
                    frameBorder="0"
                    height="100%"
                    width="100%"
                    id="pgjg"
                    title="评估结果"
                  ></iframe>
                )}
              </div>
            </div>
          </div>
        </Spin>
      </Modal>
    </div >
  ) : (
    <></>
  );
};

export default PatientSelfReported;
