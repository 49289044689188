/**
 * 报名审核
 */
import React from 'react';
import './list.less';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import {
    Form, Input, Select, Button, message as Message, Tooltip, Table, message, Tabs, Switch, Modal, DatePicker, Image, ConfigProvider, Col, Row, Spin
} from "antd";
import { FormOutlined, PaperClipOutlined, DownloadOutlined, ExclamationCircleFilled, DownOutlined, UpOutlined } from '@ant-design/icons';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import 'moment/locale/zh-cn';
import {
    GetRepresentativeList, GetApplyDetail, GetApplyOperate, GetApplyIdentification, GetEditId,
    GetAgreementDetail, revokeRollback, deleteApply, applyUserSync,
    getApplysettlementShow, GetApplyTenantList, GetApplyProjectList, getIdcardInfo
} from "../../../services/apply/ApplyService";
import provinceData from '../province';
import { BASE_URL } from '../../../../src/comm/config';
import $ from 'jquery';
import ReasonModal from "./modal/modal";
import UploadModal from './uploadModal'

const { confirm } = Modal;
const dateFormat = 'YYYY-MM-DD';

let env = process.env.REACT_APP_ENV;
let _this;
const { Option } = Select;
const { Search } = Input;

class ApplylistsComponent extends React.Component {

    constructor(props) {
        super(props);
        _this = this;
        _this.state = {
            activityType: 2,
            fileType: '',// 1批量审核不通过 2四要素认证
            tenantOptions: [],
            projectOptions: [],
            searchName: '',
            threeElementsStatus: '',
            settlementStatus: '',
            identificationStatus: '',
            approveStatus: '',
            dataLists: [], // 列表集合
            page: 1,//当前页码数
            pagination: { // 分页组件元素对象信息
                onChange: (pageNo) => {
                    _this.setState({
                        page: pageNo
                    }, () => {
                        _this.getPageData()
                    })
                },
                current: 1,
                pageSize: 10,
                total: 100,
                showSizeChanger: false,
            },

            isModalVisible: false, // 信息详情
            detailsData: {}, // 详情数据
            isUploadIdcard: '', //是否需要上传身份证
            idCardImageUpAuditState: '',//身份证正面审核状态
            idCardImageUpUrl: '',//身份证正面地址
            idCardImageDownAuditState: '', //身份证背面审核状态
            idCardImageDownUrl: '', //身份证背面地址
            loading: false,
            loading1: false,


            showFooter: false, // 是否显示footer
            applyState: '', // 报名状态
            idStatus: '', // 医生身份认证状态
            applyId: '', // 审核id
            selectionType: null, // 批量审核
            selectedRowKeys: null, // 批量审核ids
            inputVlaue: null, // 输入框值
            isAmend: false, // 是否修改
            contractContent: '', // 合同内容
            isContractVisible: false,
            reasonVisible: false, // 拒绝原因模态框
            reasonType: '', // 拒绝原因类型
            showRejectModal: false, //批量审核不通过modal
            uploadModal: false,
            showSettlement: false, //table是否隐藏财税会员
            arrowFold: false, //搜索展开或折叠'
            syncUserStatus: '0'
        }
        this.timer = null
    }

    formRef = React.createRef();
    inputRef = React.createRef();

    componentDidMount() {
        _this.getPageData(); // 默认展示月报详情
        _this.getShowSettlement(); //table是否隐藏财税会员
        _this.getEmunList()
    }

    /**
     * 获取项目和租户下拉框数据
     */
    getEmunList = () => {
        GetApplyProjectList().then(res => {
            if (res.code == 0 && res.data) {
                this.setState({
                    projectOptions: res.data
                })
            }
        })
        GetApplyTenantList().then(res => {
            if (res.code == 0 && res.data) {
                this.setState({
                    tenantOptions: res.data
                })
            }
        })
    }

    getShowSettlement = () => {
        getApplysettlementShow().then(res => {
            if (res.code == 0 && res.data) {
                this.setState({
                    showSettlement: true
                })
            }
        })
    }


    /**
     * 获取列表数据 
     */
    getPageData = async () => {
        let { page, searchName, tenantCodes, projectCode, threeElementsStatus, settlementStatus, identificationStatus, approveStatus,activityType } = this.state;
        const params = {
            page: page,
            pageSize: this.state.pagination.pageSize,
            tenantCodes,
            projectCode,
            name: searchName,
            threeElementsStatus,
            settlementStatus,
            identificationStatus,
            approveStatus,
            activityType
        }
        const res = await GetRepresentativeList(params);
        if (res && res.code == 0) {
            _this.setState({
                dataLists: [...res.data.rows],
                page: res.data.page,
                pageSize: res.data.pageSize,
                pagination: {
                    ...this.state.pagination,
                    current: res.data.page,
                    pageSize: res.data.pageSize,
                    total: res.data.total,
                }
            })
        }
    }

    // input搜索
    onSearch = () => {
        this.setState({
            page: 1
        }, () => {
            this.getPageData()
        })
    }

    //下拉框筛选
    selectChange = (value, type) => {
        if (type == 'tenant') {
            this.setState({
                tenantCodes: value,
                page: 1
            }, () => {
                this.getPageData()
            })
        } else if (type == 'project') {
            this.setState({
                projectCode: value,
                page: 1
            }, () => {
                this.getPageData()
            })
        } else if (type == 'threeElements') {
            this.setState({
                threeElementsStatus: value,
                page: 1
            }, () => {
                this.getPageData()
            })
        } else if (type == 'identificationStatus') {
            this.setState({
                identificationStatus: value,
                page: 1
            }, () => {
                this.getPageData()
            })
        } else if (type == 'settlementStatus') {
            this.setState({
                settlementStatus: value,
                page: 1
            }, () => {
                this.getPageData()
            })
        } else if (type == 'approveStatus') {
            this.setState({
                approveStatus: value,
                page: 1
            }, () => {
                this.getPageData()
            })
        }
    }

    /**
     * 查看合同
     */
    onContract = async (record) => {
        const res = await GetAgreementDetail(record.id);
        this.setState({
            contractContent: res.content ? res.content.replace('${sign}', '') : '',
            isContractVisible: true
        })
    }

    /**
     * 查看详情
     */
    onDetails = async (id, applyState, idStatus) => {
        const params = {
            id
        }
        const res = await GetApplyDetail(params);
        if (res && res.code == 0) {
            this.setState({
                detailsData: res.data ? res.data : {},
                isUploadIdcard: res.data.isUploadIdcard,
            })

            if (res.data.isUploadIdcard == '1') {
                if (res.data.idCardImageUpId) {
                    this.setState({ loading: true })
                    this.getCardInfo(res.data.idCardImageUpId, undefined)
                }
                if (res.data.idCardImageDownId) {
                    this.setState({ loading1: true })
                    this.getCardInfo(undefined, res.data.idCardImageDownId)
                }
            }
        }

        if (idStatus === "RUNNING") {
            this.setState({
                showFooter: true,
                idStatus,
                applyId: id,
                isModalVisible: true,
                applyState,
                isAmend: false
            })
        } else if (idStatus === "SIGNFAIL" || idStatus === "REJECT" || (idStatus === "AGREE" && applyState === "REJECT") || (idStatus === "AGREE" && applyState === "AGREE")) {
            this.setState({
                showFooter: false,
                isModalVisible: true,
                applyId: id,
            })
        } else if (idStatus === "AGREE" && applyState === "RUNNING") {
            this.setState({
                showFooter: true,
                idStatus,
                applyId: id,
                isModalVisible: true,
                applyState,
                isAmend: false
            })
        }
    }


    /**
     * 获取身份证图片
     * @param idCardImageUpId  正面id
     * @param idCardImageDownId  背面id
     * @param {*} value 
     * @returns 
     */
    getCardInfo = (idCardImageUpId, idCardImageDownId) => {
        let params = {
            idCardImageUpId,
            idCardImageDownId,
        }

        getIdcardInfo(params).then((res => {
            if (res.code == '0') {
                if (idCardImageUpId) {
                    this.setState({
                        idCardImageUpUrl: `data:image/jpeg;base64,${res.data.imageContent}`,
                        idCardImageUpAuditState: res.data.auditState,
                        loading: false
                    })
                }
                if (idCardImageDownId) {
                    this.setState({
                        idCardImageDownUrl: `data:image/jpeg;base64,${res.data.imageContent}`,
                        idCardImageDownAuditState: res.data.auditState,
                        loading1: false
                    })
                }
            } else {
                message.error(res.message, 2)
            }
        }))
    }

    /**
     * 审核
     */
    onBatch = async (value) => {
        let { selectedRowKeys } = this.state;

        if (value == 'batch') {
            this.setState({
                selectionType: 'checkbox'
            })
        } else {

            if (!selectedRowKeys || selectedRowKeys.length == 0) {
                message.warning('请先选择数据！');
                return
            }
            const params = {
                id: selectedRowKeys ? selectedRowKeys.join(',') : '',
                status: value
            }

            // 同意申请
            const res = await GetApplyOperate(params);
            if (res && res.code == 0) {
                message.success(res.message);
                this.setState({
                    selectionType: null
                })
                this.getPageData()
            } else {
                message.error(res.message);
            }

        }
    }

    // 同意申请 / 确认医生信息
    GetCurrentState = async (currstatus, reasons) => {
        let { applyState, idStatus, applyId } = this.state
        let arr = []
        arr.push(applyId)

        if (idStatus === "RUNNING") {
            let params = {
                id: arr ? arr.join(',') : '',
                status: currstatus
            }

            if (reasons) {
                params['rejectReasons'] = reasons || ''
            }

            // 确认医生信息
            const res = await GetApplyIdentification(params);
            if (res && res.code == 0) {
                message.success(res.message);
                this.getPageData()
            } else {
                message.error(res.message);
            }

            this.setState({
                isModalVisible: false,
                reasonVisible: false
            })

            return
        }

        if (idStatus === "AGREE" && applyState === "RUNNING") {
            const params = {
                id: arr ? arr.join(',') : '',
                status: currstatus
            }

            // 同意申请
            const res = await GetApplyOperate(params);
            if (res && res.code == 0) {
                message.success(res.message);
                this.getPageData()
            } else {
                message.error(res.message);
            }

            this.setState({
                isModalVisible: false
            })

            return
        }
    }

    // 确认
    handleOk = async () => {
        this.GetCurrentState('AGREE')

    }
    // 取消
    handleCancel = async () => {
        // 非
        if (this.state.idStatus == 'RUNNING') {
            this.setState({
                reasonVisible: true,
                reasonType: 'isdoctor'
            })
        } else {
            this.GetCurrentState('REJECT')
        }
    }

    // 签名不合格提交
    handleSignCancel = () => {
        this.setState({
            reasonVisible: true,
            reasonType: 'sign'
        });

        // this.GetCurrentState('SIGNFAIL')
    }

  

    // 切换tab 待审核报名1，待AGREE报名2
    handleTabs = (value) => {
        this.activeKey = value
        this.setState({
            page: 1
        }, () => {
            this.getPageData()
        })
    }

    // 自动刷新
    autoRefresh = (checked, e) => {
        if (!checked) {
            clearInterval(this.timer)
            return
        }
        this.timer = setInterval(() => {
            this.getPageData()
        }, 60000)
    }

    // 修改
    onAmend = async (value) => {
        this.setState({
            isAmend: true
        })
    }

    // 修改下拉框
    handleChange = (value) => {
        this.setState({
            provinceVal: value
        })
    }

    // 保存
    onFinish = async (values) => {
        let { detailsData, applyId, applyState, idStatus } = this.state;
        const params = {
            id: applyId,
            name: values.name ? values.name : detailsData.name,
            phone: values.phone ? values.phone : detailsData.phone,
            idCard: values.idCard ? values.idCard : detailsData.idCard,
            hospitalName: values.hospitalName ? values.hospitalName : detailsData.hospitalName,
            dept: values.dept ? values.dept : detailsData.dept,
            jobTitle: values.jobTitle ? values.jobTitle : detailsData.jobTitle,
            bankNumber: values.bankNumber ? values.bankNumber : detailsData.bankNumber,
            bankOfDeposit: values.bankOfDeposit ? values.bankOfDeposit : detailsData.bankOfDeposit,
            createTime: values.createTime ? values.createTime : detailsData.createTime,
            province: values.province ? values.province : detailsData.province,
            city: values.city ? values.city : detailsData.city,
            doctorName: values.doctorName ? values.doctorName : detailsData.doctorName,
        }

        const res = await GetEditId(params);
        if (res && res.code == 0) {
            this.setState({
                isAmend: false,
                // isModalVisible: false,
            })
            this.getPageData()
            this.onDetails(applyId, applyState, idStatus)
            message.success(res.message);
        } else {
            message.error(res.message);
        }
    };

    // form过滤空格
    getValueFromEvent = (event) => {
        return event.target.value.replace(/\s+/g, "")
    }

    // 下载
    downloadRecord = () => {
        let { searchName, tenantCodes, projectCode, threeElementsStatus, settlementStatus, identificationStatus, approveStatus,activityType } = this.state;
        let params = {
            name: searchName,
            approveStatus: approveStatus,
            identificationStatus: identificationStatus,
            tenantCodes,
            activityType
        }

        let tokenData = sessionStorage.getItem('token')
        tokenData = JSON.parse(tokenData);
        const url = BASE_URL + `plat/apply/representative/report`;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', url, true);
        xhr.responseType = "blob";// 返回类型blob
        xhr.setRequestHeader("x-auth-token", tokenData.token);
        xhr.setRequestHeader("Content-Type", 'application/json;charset=UTF-8');
        xhr.onload = function () {
            if (this.status === 200) {
                let blob = this.response;
                let reader = new FileReader();
                reader.readAsDataURL(blob);    // 转换为base64，可以直接放入a表情href
                reader.onload = function (e) {
                    // 转换完成，创建一个a标签用于下载
                    var a = document.createElement('a');
                    a.download = '下载.xls';
                    a.href = e.target.result;
                    $("body").append(a);// 修复firefox中无法触发click
                    a.click();
                    $(a).remove();
                }
            }
        };

        xhr.send(JSON.stringify(params));
    }

    // 撤销
    handleRevoke = async () => {
        let { applyId, applyState, idStatus } = this.state;
        let params = {
            id: applyId
        }
        const res = await revokeRollback(params);
        if (res && res.code == 0) {
            this.setState({
                isModalVisible: false,
            })
            this.getPageData()
            message.success(res.message);
        } else {
            message.error(res.message);
        }
    }

    // 签名不合格/非医生
    closeReasonModal = () => {
        this.setState({
            reasonVisible: false
        });
    }

    // 原因
    getRejectReason = (value, type) => {
        if (type == 'sign') {
            // 签名不合格原因
            this.GetCurrentState('SIGNFAIL', value)
        } else {
            // 非医生原因
            this.GetCurrentState('REJECT', value)
        }
    }


    //删除报名
    deleteItem = (id) => {
        let params = {
            id
        }
        let that = this;
        confirm({
            title: '确定要删除该报名记录?',
            icon: <ExclamationCircleFilled />,
            okText: '确定',
            cancelText: '取消',
            onOk() {
                deleteApply(params).then(res => {
                    if (res.code == 0) {
                        message.success('删除成功', 1)
                        that.getPageData()
                    }
                })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }





    closeModal = () => {
        this.setState({ uploadModal: false })
    }


    //重新获取详情
    refreshDetail = async () => {
        const params = {
            id: this.state.applyId
        }
        const res = await GetApplyDetail(params);
        if (res && res.code == 0) {
            this.setState({
                detailsData: res.data ? res.data : {},
                syncUserStatus: '0'
            })
            if (res.data.syncStatus == 1 && res.data.bindStatus == 1 && res.data.syncIdcardStatus == 1) {
                message.success('同步成功', 2)
            } else {
                message.error('同步失败', 2)
            }
        } else {
            this.setState({
                syncUserStatus: '0'
            })
        }
    }


    /**会员同步 */
    handleUserSync = () => {
        let params = {
            id: this.state.applyId
        }
        this.setState({
            syncUserStatus: '1'
        })

        applyUserSync(params).then(res => {
            if (res.code == 0) {
                this.getPageData()
                this.refreshDetail()
            } else {
                this.setState({
                    syncUserStatus: '0'
                })
            }
        })
    }


    closeDetailModal = () => {
        this.setState({
            isModalVisible: false,
            detailsData: {},
            isUploadIdcard: '', //是否需要上传身份证
            idCardImageUpAuditState: '',//身份证正面审核状态
            idCardImageUpUrl: '',//身份证正面地址
            idCardImageDownAuditState: '', //身份证背面审核状态
            idCardImageDownUrl: '', //身份证背面地址
        })
    }


    render() {
        let { dataLists, pagination, isModalVisible, detailsData, showFooter, arrowFold,
            applyState, idStatus, selectionType, inputVlaue, isAmend, isContractVisible, contractContent,
            reasonVisible, reasonType, showRejectModal, showSettlement, tenantOptions, projectOptions, syncUserStatus,
            idCardImageDownAuditState, idCardImageUpAuditState
        } = this.state;
        let columns = [
            {
                title: '报名活动',
                width: 170,
                render: (text, record) => {
                    return (
                        <div className={'con_div'}>
                            <div className='projectName'>
                                <Tooltip title={record.activityName}>
                                    {record.activityName}
                                </Tooltip>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: '报名项目',
                width: 170,
                render: (text, record) => {
                    return (
                        <div className={'con_div'}>
                            <div className='projectName'>
                                <Tooltip title={record.projectName}>
                                    {record.projectName}
                                </Tooltip>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: '身份信息',
                width: 120,
                render: (text, record) => {
                    return (
                        <div className={'con_div'}>
                            <div className={'title'}>
                                {record.name}
                            </div>
                            <div className={'sub-title'}>
                                {record.phone}
                            </div>
                        </div>
                    )
                }
            },
           
            {
                title: '申请时间',
                width: 100,
                render: (text, record) => {
                    if (record.createTime) {
                        return (
                            <div className={'con_div'}>
                                {record.createTime ? record.createTime.replace('T', ' ') : ''}
                            </div>
                        )
                    }
                }
            },
          
            {
                title: '四要素',
                width: 130,
                render: (text, record) => {
                    return (
                        <div className={'con_div'}>
                            {
                                record.threeElementsStatus === 'SUCCESS' ?
                                    <div className='three_success' >
                                        <span className='icon'></span>
                                        <span>已通过</span>
                                    </div> : record.threeElementsStatus === 'FAIL' ?
                                        <div className='three_fail'>
                                            <span className='icon'></span>
                                            <span>{record.elementsFailCode == '1' ? '姓名和手机号不匹配' :
                                                record.elementsFailCode == '2' ? '身份证错误' :
                                                    record.elementsFailCode == '3' ? '银行卡错误' : '未通过'}</span>
                                        </div> : ""
                            }
                        </div>
                    )
                }
            },
            // {
            //     title: '年龄认证',
            //     width: 130,
            //     render: (text, record) => {
            //         return (
            //             <div className={'con_div'}>
            //                 {
            //                     record.ageCheckStatus === 'SUCCESS' ?
            //                         <div className='three_success' >
            //                             <span className='icon'></span>
            //                             <span>已通过</span>
            //                         </div> : record.ageCheckStatus === 'FAIL' ?
            //                             <div className='three_fail'>
            //                                 <span className='icon'></span>
            //                                 <span>未通过</span>
            //                             </div> : ""
            //                 }
            //             </div>
            //         )
            //     }
            // },
            // {
            //     title: '财税会员',
            //     width: 100,
            //     className: showSettlement ? '' : 'tableHiddle',
            //     render: (text, record) => {
            //         return (
            //             <div className={'con_div apply_sync'}>
            //                 {
            //                     record.syncStatus == 1 ? <div className='sync_success'>已同步</div> :
            //                         record.syncStatus == 2 ? <div className='sync_error'>同步失败</div> :
            //                             <div>—</div>
            //                 }
            //             </div>
            //         )
            //     }
            // },
            {
                title: '报名状态',
                width: 120,
                render: (text, record) => {
                    return (
                        <div className={'apply_state'}>
                            {
                                record.approveStatus === 'RUNNING' ?
                                    <div className='running' >
                                        <span className='dot'></span>
                                        <span>待审核</span>
                                    </div> : record.approveStatus === 'AGREE' ?
                                        <div className='agree'>
                                            <span className='dot'></span>
                                            <span>已通过</span>
                                        </div> : <div className='notPass'>
                                            <span className='dot'></span>
                                            <span>未通过</span>
                                        </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: '拒绝原因',
                width: 150,
                render: (text, record) => {
                    return (
                        <div className={'con_div'} >
                            <div className='rejectReasons'>
                                <Tooltip title={record.rejectReasons}>
                                    {record.rejectReasons}
                                </Tooltip>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: '信息更新时间',
                width: 120,
                render: (text, record) => {
                    if (record.modifyTime) {
                        return (
                            <div className={'con_div'}>{record.modifyTime ? record.modifyTime.replace('T', ' ') : ''}
                            </div>
                        )
                    }
                }
            },
            {
                title: '客户名称',
                width: 120,
                render: (text, record) => {
                    return (
                        <div className={'con_div'}>
                            <div className={'title'}>{record.tenantName}</div>
                        </div>
                    )
                }
            },
            {
                title: '审核人',
                width: 120,
                render: (text, record) => {
                    return (
                        <div className={'con_div'}>
                            <div className={'title'}>{record.approveUser}</div>
                        </div>
                    )
                }
            },
            {
                title: '审核时间',
                width: 120,
                render: (text, record) => {
                    if (record.approveTime) {
                        return (
                            <div className={'con_div'}>
                                {record.approveTime ? record.approveTime.replace('T', ' ') : ''}
                            </div>
                        )
                    }
                }
            },
            // {
            //     title: '同步失败原因',
            //     width: 150,
            //     render: (text, record) => {
            //         return (
            //             <div className={'con_div'} >
            //                 <div className='rejectReasons'>
            //                     <Tooltip title={record.syncFailReason}>
            //                         {record.syncFailReason}
            //                     </Tooltip>
            //                 </div>
            //             </div>
            //         )
            //     }
            // },
          
            {
                title: '操作',
                width: 180,
                fixed: 'right',
                render: (text, record) =>
                    <div onClick={eve => { eve.stopPropagation(); }} className={'partPop'}>
                        <Button type="link" onClick={() => this.onContract(record)} >
                            合同
                        </Button>
                        <Button type="link" onClick={() => this.onDetails(record.id, record.approveStatus, record.identificationStatus)} >
                            详情
                        </Button>
                        <Button type="link" onClick={() => this.deleteItem(record.id)} >
                            删除
                        </Button>
                    </div>
            },
        ]


        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowKeys
                })
            },
            getCheckboxProps: (record) => ({
                disabled: record.identificationStatus !== 'AGREE',
                name: record.name,
            }),
        };

        // 模态框内容 
        const detailsInfo = [{
            title: '个人信息',
            icon: 'icon1',
            content: [
                { type: 'text', label: '姓名', defaultValue: detailsData.doctorName ? detailsData.doctorName : '', require: true },
                { type: 'text', label: '手机号', defaultValue: detailsData.phone ? detailsData.phone : '', require: false },
                { type: 'text', label: '身份证', defaultValue: detailsData.idCard ? detailsData.idCard : '', require: true },
                { type: 'DatePicker', label: '申请时间', defaultValue: detailsData.createTime ? detailsData.createTime.substring(0, 10) : '', require: false },
                { type: 'IdCard', label: '上传身份证照片', defaultValue: '', require: false },
                { type: 'select', label: '所在省份', defaultValue: detailsData.province ? detailsData.province : '', require: true },
                { type: 'text', label: '所在城市', defaultValue: detailsData.city ? detailsData.city : '', require: true },

            ]
        }, {
            title: '支付信息',
            icon: 'icon3',
            content: [
                { type: 'text', label: '姓名', defaultValue: detailsData.name ? detailsData.name : '', require: true },
                { type: 'text', label: '银行卡号', defaultValue: detailsData.bankNumber ? detailsData.bankNumber : '', require: true },
                { type: 'text', label: '开户行', defaultValue: detailsData.bankOfDeposit ? detailsData.bankOfDeposit : '', require: true }
            ]
        }, 
        
        // {
        //     title: '证件信息',
        //     icon: 'icon4',
        //     content: [
        //         { type: 'Image', label: '', defaultValue: detailsData.certificate ? detailsData.certificate.split(',') : [], require: false }
        //     ]
        // }, 
        
        // {
        //     title: '财税平台',
        //     icon: 'icon5',
        //     content: [
        //         { type: 'Icon', label: '', defaultValue: detailsData ? detailsData : null, require: false }]
        // }
    
    ]

        return (
            <div className='applyList'>
                <div className={'apply_wrap'}>
                    <div className={'search_wrap_content'}>
                        <div className='search_left'>
                            <Row className='search_row'>
                                {
                                    arrowFold ?
                                        <div className='arrow_fold' onClick={() => this.setState({ arrowFold: false })}>
                                            <span>收起</span>
                                            <UpOutlined style={{ fontSize: '12px', marginLeft: '3px' }} />
                                        </div>
                                        :
                                        <div className='arrow_fold' onClick={() => this.setState({ arrowFold: true })}>
                                            <span>展开</span>
                                            <DownOutlined style={{ fontSize: '12px', marginLeft: '3px' }} />
                                        </div>
                                }
                                <Col span={8}>
                                    <Search
                                        allowClear
                                        placeholder="请输入姓名"
                                        onSearch={this.onSearch}
                                        onChange={(e) => this.setState({ searchName: e.target.value })}
                                        style={{ width: '80%' }}
                                    />
                                </Col>
                                <Col span={8}>
                                    <Select
                                        mode="multiple"
                                        showSearch
                                        placeholder="请选择租户"
                                        style={{ width: '80%' }}
                                        allowClear

                                        onChange={value => this.selectChange(value, 'tenant')}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {tenantOptions.map(item => (
                                            <Option key={item.code} value={item.code}>{item.name}</Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col span={8}>
                                    <Select
                                        showSearch
                                        placeholder="请选择项目"
                                        style={{ width: '80%' }}
                                        allowClear
                                        onChange={value => this.selectChange(value, 'project')}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {projectOptions.map(item => (
                                            <Option key={item.projectCode} value={item.projectCode}>{item.projectName}</Option>
                                        ))}
                                    </Select>
                                </Col>
                              
                                <Col span={8} hidden={!arrowFold}>
                                    <Select
                                        placeholder="请选择四要素状态"
                                        style={{ width: '80%' }}
                                        allowClear
                                        onChange={value => this.selectChange(value, 'threeElements')}
                                    >
                                        <Option value='RUNNING'>待审核</Option>
                                        <Option value='SUCCESS'>通过</Option>
                                        <Option value='FAIL'>未通过</Option>
                                    </Select>
                                </Col>
                                {/* <Col span={8} hidden={!arrowFold}>
                                    <Select
                                        allowClear
                                        placeholder="请选择财税会员状态"
                                        style={{ width: '80%' }}
                                        onChange={value => this.selectChange(value, 'settlementStatus')}
                                    >
                                        <Option value='1'>已同步</Option>
                                        <Option value='2'>同步失败</Option>
                                        <Option value='0'>未同步</Option>
                                    </Select>
                                </Col> */}
                                <Col span={8} hidden={!arrowFold}>
                                    <Select
                                        allowClear
                                        placeholder="请选择报名状态"
                                        style={{ width: '80%' }}
                                        onChange={value => this.selectChange(value, 'approveStatus')}
                                    >
                                        <Option value='AGREE'>通过</Option>
                                        <Option value='REJECT'>未通过</Option>
                                        <Option value='RUNNING'>待审核报名</Option>
                                    </Select>
                                </Col>
                            </Row>

                        </div>
                        <div className='right_action'>
                            <div className='refresh'>
                                <span className={'autoTxt'}>自动刷新</span>
                                <Switch defaultChecked={false} onChange={this.autoRefresh} />
                            </div>
                            <Button type="primary" icon={<DownloadOutlined />} size={'middle'} onClick={() => this.downloadRecord()}>
                                下载报名记录
                            </Button>
                        </div>
                    </div>

                    <div className={'btns_wrap'}>
                        {
                            selectionType === 'checkbox' ?
                                <>
                                    <Button type="link" onClick={() => this.onBatch('AGREE')} >同意申请</Button>
                                    <Button type="link" onClick={() => this.onBatch('REJECT')} >拒绝</Button>
                                </> :
                                <>
                                    <Button type="primary" ghost onClick={() => this.onBatch('batch')} >批量审核</Button>
                                    <Button type="primary" ghost onClick={() => this.setState({ uploadModal: true, fileType: 1 })} >批量审核不通过</Button>
                                    <Button type="primary" ghost onClick={() => this.setState({ uploadModal: true, fileType: 2 })} >批量四要素认证</Button>
                                </>
                        }
                    </div>
                    <Table
                        locale={{ emptyText: "暂无数据" }}
                        columns={columns}
                        dataSource={dataLists}
                        defaultExpandAllRows={true}
                        expandIconAsCell={false}
                        pagination={dataLists.length !== 0 && pagination}
                        rowKey={'id'}
                        scroll={{ x: 'calc(700px + 50%)' }}
                        rowSelection={selectionType ? {
                            type: selectionType,
                            ...rowSelection,
                        } : null}
                    />
                </div>

                {/* 查看详情 */}
                <Modal
                    wrapClassName={'applyList_modal'}
                    title="申请人信息详情" visible={isModalVisible}
                    onCancel={this.closeDetailModal}
                    width={800}
                    destroyOnClose
                    footer={[
                        <div className='opertion_warp'>
                            <div className='opertion_warp_item' hidden={isAmend}>
                                <Button type="link" onClick={() => this.onAmend('修改')} className={'linkBtn'}>
                                    <FormOutlined /> 修改
                                </Button>
                              
                            </div>
                            {
                                showFooter &&
                                <div className='opertion_warp_item'>
                                   
                                        {
                                        
                                            (detailsData.signStatus == 1 && detailsData.identificationStatus == "RUNNING" && !isAmend) &&
                                            <Button danger ghost type="primary" onClick={this.handleSignCancel}>
                                                签名不合格
                                            </Button>
                                        }
                                    
                                        <Button hidden={isAmend} danger ghost type="primary" onClick={this.handleCancel}>
                                            {idStatus === "AGREE" && '拒绝'}
                                        </Button>
                                        {
                                            detailsData.identificationStatus != "RUNNING" && !isAmend &&
                                            <Button hidden={isAmend}  danger ghost type="primary" onClick={this.handleRevoke}>
                                                撤销
                                            </Button>
                                        }
                                        {/* {detailsData.settlementPermission && idStatus === "AGREE" && (detailsData.syncStatus != 1 || detailsData.bindStatus != 1 || detailsData.syncIdcardStatus != 1) &&
                                            <Button ghost type="primary" onClick={this.handleUserSync} loading={syncUserStatus == '1'}>
                                                同步会员
                                            </Button>
                                        } */}
                                        <Button hidden={isAmend} key="submit" type="primary" onClick={this.handleOk}>
                                            {idStatus === "RUNNING" ? '确认代表信息' : '同意申请'}
                                        </Button>
                               
                                </div>
                            }
                        </div>
                    ]}
                >

                    {
                        detailsInfo.map((item, index) => (
                            <div
                                className={'model_detail_Info'}
                                key={index}
                                hidden={isAmend}
                                style={{ display: (item.title == '财税平台' && (detailsData.settlementPermission == false || detailsData.syncStatus == 0)) ? 'none' : '' }}
                            >
                                <div className={'info_title'}>
                                    <div className={item.icon}></div>
                                    <div> {item.title }</div>

                                </div>
                                <div className='wraps_content'>
                                    {
                                        item.content.map((x, y) => (
                                            <div hidden={x.type == 'IdCard' && this.state.isUploadIdcard != '1'} className={x.type == 'IdCard' ? 'wraps_Image1' : x.type == 'Image' ? 'wraps_Image' : 'wraps'} key={y}>
                                                <span className={'label'} hidden={!x.label}>
                                                    <span style={{ color: '#F03333' }} hidden={!x.require}>*</span>
                                                    {x.label}
                                                </span>
                                                <div className={'Image_item'}>
                                                    {
                                                        x.type == 'IdCard' ?
                                                            <>
                                                                <ConfigProvider locale={zh_CN}>
                                                                    <Spin spinning={this.state.loading}>
                                                                        <div>
                                                                            {!idCardImageUpAuditState ? <div className='no_card'></div> : <Image
                                                                                height={'75px'}
                                                                                src={this.state.idCardImageUpUrl}
                                                                                alt=''
                                                                            />}
                                                                            {!idCardImageUpAuditState ? <div className='state_waitUp'>
                                                                                <span className='icon'></span>
                                                                                未上传
                                                                            </div> : idCardImageUpAuditState == '1' ? <div className='state_success'>
                                                                                <span className='icon'></span>
                                                                                审核通过
                                                                            </div> : idCardImageUpAuditState < 0 ? <div className='state_pass'>
                                                                                <span className='icon'></span>
                                                                                审核失败
                                                                            </div> : ''}
                                                                        </div>
                                                                    </Spin>
                                                                    <Spin spinning={this.state.loading1}>
                                                                        <div>
                                                                            {!idCardImageDownAuditState ? <div className='no_card'></div> : <Image
                                                                                height={'75px'}
                                                                                src={this.state.idCardImageDownUrl}
                                                                                alt=''
                                                                            />}
                                                                            {!idCardImageDownAuditState ? <div className='state_waitUp'>
                                                                                <span className='icon'></span>
                                                                                未上传
                                                                            </div> : idCardImageDownAuditState == '1' ? <div className='state_success'>
                                                                                <span className='icon'></span>
                                                                                审核通过
                                                                            </div> : idCardImageDownAuditState < 0 ? <div className='state_pass'>
                                                                                <span className='icon'></span>
                                                                                审核失败
                                                                            </div> : ''}
                                                                        </div>
                                                                    </Spin>
                                                                    <div style={{ color: '#F69400' }}> <span style={{ color: 'red' }}>* </span> 请核对身份证号码是否正确</div>
                                                                </ConfigProvider>


                                                            </>
                                                            : x.type == 'Image' ?
                                                                <>
                                                                    {x.defaultValue.length > 0 && x.defaultValue.map((i, dIndex) => (
                                                                        <ConfigProvider locale={zh_CN} key={dIndex}>
                                                                            <Image
                                                                                height={'100%'}
                                                                                src={i}
                                                                                alt=''
                                                                                key={dIndex}
                                                                            />
                                                                        </ConfigProvider>
                                                                    ))}
                                                                </>
                                                                : x.type == 'Icon' ?
                                                                    <div className='icon_wrap' style={{ width: 500 }}>
                                                                        {
                                                                            x.defaultValue.syncStatus == 1 ?
                                                                                <div className='icon_wrap_s'>
                                                                                    <div className='syncIcon'></div>
                                                                                    <div>账号已同步</div>
                                                                                </div> : x.defaultValue.syncStatus == 2 ?
                                                                                    <div className='icon_wrap_s'>
                                                                                        <div className='syncIcon_err'></div>
                                                                                        <div>账号同步失败</div>
                                                                                    </div> : ''
                                                                        }
                                                                        {
                                                                            x.defaultValue.syncIdcardStatus == 1 ?
                                                                                <div className='icon_wrap_s'>
                                                                                    <div className='syncIcon'></div>
                                                                                    <div>身份证已同步</div>
                                                                                </div> : x.defaultValue.syncIdcardStatus == 2 ?
                                                                                    <div className='icon_wrap_s'>
                                                                                        <div className='syncIcon_err'></div>
                                                                                        <div>身份证同步失败</div>
                                                                                    </div> : x.defaultValue.syncIdcardStatus == 0 ?
                                                                                        <div className='icon_wrap_s'>
                                                                                            <div className='syncIcon_err'></div>
                                                                                            <div>身份证未验证</div>
                                                                                        </div> : ''
                                                                        }
                                                                        {
                                                                            x.defaultValue.bindStatus == 1 ?
                                                                                <div className='icon_wrap_s'>
                                                                                    <div className='syncIcon'></div>
                                                                                    <div>收款信息已同步</div>
                                                                                </div> : x.defaultValue.bindStatus == 2 ?
                                                                                    <div className='icon_wrap_s'>
                                                                                        <div className='syncIcon_err'></div>
                                                                                        <div>收款信息同步失败</div>
                                                                                    </div> : x.defaultValue.bindStatus == 3 ?
                                                                                        <div className='icon_wrap_s'>
                                                                                            <div className='syncIcon_running'></div>
                                                                                            <div>收款信息验证中</div>
                                                                                        </div> : <div className='icon_wrap_s'>
                                                                                            <div className='syncIcon_not'></div>
                                                                                            <div>收款信息未同步</div>
                                                                                        </div>
                                                                        }

                                                                    </div> :
                                                                    <div className="information"> <Input defaultValue={x.defaultValue} disabled style={{ width: 230 }} /></div>
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        ))
                    }

                    {
                        isAmend &&
                        <Form
                            name="basic"
                            labelCol={{
                                span: 6,
                            }}
                            wrapperCol={{
                                span: 16,
                            }}
                            initialValues={detailsData}
                            onFinish={this.onFinish}
                            autoComplete="off"
                            size={'small'}
                        >
                            <Form.Item className={'mb10'}>个人信息</Form.Item>
                            <Form.Item
                                label="姓名"
                                name="doctorName"
                                rules={[
                                    { required: true, message: '姓名不能为空', max: 10 },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="手机号"
                                name="phone"
                                rules={[
                                    { required: false, message: '手机号不能为空' },
                                    // { pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/, message: '请输入正确的手机号', max: 11 }
                                ]}
                                getValueFromEvent={this.getValueFromEvent}
                            >
                                <Input disabled />
                            </Form.Item>

                            <Form.Item
                                label="身份证"
                                name="idCard"
                                rules={[
                                    { required: true, message: '身份证号不能为空' },
                                    { pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: '身份证号位数不正确', min: 15, max: 18 }
                                ]}
                                getValueFromEvent={this.getValueFromEvent}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="申请时间"
                                name="applyTime"
                                rules={[
                                    { required: false, },
                                ]}
                                getValueFromEvent={this.getValueFromEvent}
                            >
                                <DatePicker defaultValue={moment(detailsData.createTime ? detailsData.createTime.substring(0, 10) : '', dateFormat)} disabled />
                            </Form.Item>
                            
                            <Form.Item
                                label="所在省份"
                                name="province"
                                rules={[
                                    { required: true, message: '请选择所在省份' },
                                ]}
                            >
                                <Select>
                                    {
                                        provinceData.map((v, vIndex) => (
                                            <Select.Option value={v.value} key={vIndex}>{v.label}</Select.Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="所在城市"
                                name="city"
                                rules={[
                                    { required: true, message: '请输入所在城市', max: 50 },
                                ]}
                                getValueFromEvent={this.getValueFromEvent}
                            >
                                <Input />
                            </Form.Item>
                        
                            <Form.Item className={'mb10'}>支付信息</Form.Item>
                            <Form.Item
                                label="姓名"
                                name="name"
                                rules={[
                                    { required: true, message: '姓名不能为空', max: 10 },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="银行卡号"
                                name="bankNumber"
                                rules={[
                                    { required: true, message: '银行卡号不能为空' },
                                    { pattern: /(^[1-9]\d*$)/, message: '银行卡位数不正确', min: 14, max: 18 }
                                ]}
                                getValueFromEvent={this.getValueFromEvent}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="开户行"
                                name="bankOfDeposit"
                                rules={[
                                    { required: true, message: '请输入开户行', max: 50 },
                                ]}
                                getValueFromEvent={this.getValueFromEvent}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item>
                                <div style={{ display: 'flex' }}>
                                    <Button type="primary" ghost style={{ marginRight: 10 }} onClick={() => this.setState({ isAmend: false })}>
                                        取消
                                    </Button>
                                    <Button type="primary" htmlType="submit">
                                        保存
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>
                    }
                 
                </Modal>

                {/* 查看合同 */}
                <Modal Modal
                    title="合同"
                    visible={isContractVisible}
                    onOk={() => { this.setState({ isContractVisible: false }) }}
                    okText='确认'
                    cancelText='关闭'
                    onCancel={() => { this.setState({ isContractVisible: false }) }}
                    width='60%'
                >
                    <div dangerouslySetInnerHTML={{ __html: contractContent || '暂无数据' }}></div>
                </Modal>

                {/* 签名不合格/非医生 */}
                {
                    reasonVisible &&
                    <ReasonModal
                        visible={reasonVisible}
                        closeModal={this.closeReasonModal}
                        type={reasonType}
                        getRejectReason={this.getRejectReason}
                    />
                }


                <div>
                    {this.state.uploadModal && <UploadModal fileType={this.state.fileType} uploadModal={this.state.uploadModal} closeModal={this.closeModal} />}
                </div>

            </div>
        );
    }
}
export default withRouter(ApplylistsComponent);