/**
 * 拒绝
 */
import React, { Component } from 'react';
import { Modal, Button, Form, Input, Select, Radio, message, Timeline, List, Avatar, Tag } from 'antd';
import {
    createFromIconfontCN, EditOutlined, CloseOutlined,
    StepBackwardOutlined, StepForwardOutlined
} from '@ant-design/icons';
import './index.less';

const { Option } = Select;
const { TextArea } = Input;
let timeInterval;
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2283082_862m1tbppl6.js',//icon-shanchu icon-tongji icon-quxiao icon-baocun icon-quyufangwenqushi icon-tianjia1 icon-xiugai
    ],
});

class DetailsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            rejectReason: '', // 质疑原因
        }
    }
    formRef = React.createRef();

    componentDidMount() {
        if (this.props) {
            let { data, rejectReason } = this.props;
            this.setState({
                data,
                rejectReason
            })
        }
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.data) {
            this.setState({
                data: nextProps.data,
                rejectReason: nextProps.rejectReason
            })
        }
    }

    onChange = e => {
        console.log('Change:', e.target.value);
    }

    renderQueryInfo = (value) => {

        const list = [
            { label: '质疑文本：', value: value.questionText },
            { label: '质疑人：', value: value.questionUserName },
            { label: '质疑时间：', value: value.questionTime ? value.questionTime.replace('T', ' ') : '' },
            { label: '质疑状态：', value: <Tag color={value.questionStatus == '待回复' ? `blue` : value.questionStatus == '已回复' ? `warning` : ''}>{value.questionStatus}</Tag> },
            // { label: '回复内容：', value: value.replyContent },
            // { label: '回复人：', value: value.replyUserName },
            // { label: '回复时间：', value: value.replyTime ? value.replyTime.replace('T',' ') : '' }
        ]

        let dom = <>
            <div className={'first_title'}>质疑信息</div>
            {list.map((item, index) => (
                <div className={'lineInfo'} key={index}>
                    <span className={'label'}>{item.label}</span>{item.value}
                </div>
            ))}
        </>

        return dom
    }

    // 取消回复
    cancelReply = () => {
        this.setState({
            rejectReason: ''
        })
        this.props.getQueryStatus(true)
    }

    // 回复
    onReply = () => {
        this.props.replyQueryFun(this.state.rejectReason)
    }

    // 质疑内容
    changeTextArea = (v) => {
        this.setState({
            rejectReason: v.target.value.trim()
        })
    }

    render() {
        let { data, rejectReason } = this.state;
        let { visible, addQueryStatus } = this.props;
        data = data || JSON.parse(sessionStorage.getItem('detailData'))
        let userName = sessionStorage.getItem('currentuserName')

        return (
            <div className='detailInfo_Content'>
                <div className={'query_info'}>
                    {data && this.renderQueryInfo(data)}
                </div>

                <div className={'operate_record'}>
                    <div className={'first_title'}>操作记录</div>
                    <div className={'Timeline'}>
                        <Timeline reverse={true} >
                            {
                                data && data.list.length > 0 && data.list.map((item, index) => (
                                    <div key={index}>
                                        <Timeline.Item color="gray">
                                            <div className={'Timeline_item'}>
                                                <div className={'icon'}
                                                    style={{ background: item.replyUserName == data.collector ? '#643697' : item.replyUserName == userName ? '#4E88F6' : '#F79800' }}
                                                    >
                                                    {item.replyUserName}
                                                </div>
                                                <div className={'info'}>
                                                    <div className={'title'}>
                                                        {item.replyUserName}&nbsp;&nbsp;
                                                        {item.type == 'Answer' ? '回复质疑' :
                                                            item.type == 'create' ? '发起质疑' : item.type == 'Supplement' ? '补充质疑' : '关闭质疑'}
                                                    </div>
                                                    <div className={'subtitle'}>{item.replyContent}</div>
                                                    <span className={'time'}>{item.replyTime ? `${item.replyTime.substring(0, 10)} ${item.replyTime.substring(11, 16)}` : ''}</span>
                                                </div>
                                            </div>
                                        </Timeline.Item>
                                    </div>
                                ))
                            }
                            <Timeline.Item color="gray" hidden={addQueryStatus}>
                                <div className={'Timeline_item'}>
                                    <div className={'icon'} style={{ background: '#4E88F6' }}>
                                        {userName}
                                    </div>
                                    <div className={'info firstInfo'}>
                                        <TextArea maxLength={300} onChange={this.changeTextArea} value={rejectReason} />
                                        <Button
                                            type="link"
                                            onClick={this.cancelReply}
                                            className={'replyBtn'}
                                        >取消回复</Button>
                                        <Button
                                            type="link"
                                            onClick={this.onReply}
                                            className={'replyBtn'}
                                        >回复</Button>
                                    </div>
                                </div>
                            </Timeline.Item>
                        </Timeline>
                    </div>
                </div>
            </div>
        );
    }
}

export default DetailsComponent;