import React from 'react';
import './groupManage.less';
import { Form, Input, Button, message as Message, Table, Popconfirm, TreeSelect, InputNumber } from "antd";
import { createFromIconfontCN } from '@ant-design/icons';
import myIcon from "../researchCenter/photo_icon.png";
import {
    GetGroupPageList,
    DeleteGroupPage,
    AddGroupPage, ModifyProjectAllocation,
} from "../../../../services/project/ProjectService";

let _this;
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2283082_sb9kcktrb1j.js',//icon-shanchu icon-tongji icon-quxiao icon-quyufangwenqushi icon-tianjia1 icon-xiugai
    ],
});
const layout = {
    wrapperCol: {
        span: 24,
    },
};

const { TreeNode } = TreeSelect;

class Participants extends React.Component {

    constructor(props) {
        super(props);
        _this = this;
        let currentRole = sessionStorage.getItem('roleCode');
        _this.state = {
            userRole: currentRole,
            userList: [], // 列表集合
            editFormData: {
                id: '',
                groupCode: ''
            }, // 选中表格某行修改
            editBtnFlag: false,// 修改btn显 隐
            columns: [
                {
                    width: '30%',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div c_left'}>
                                {/* <div className={'photo'}>
                                    <img src={myIcon} alt="" />
                                </div> */}
                                <div className={'c_left_title'}>
                                    <p>{record.shortName}</p>
                                    <p>{record.groupName}</p>
                                </div>
                            </div>
                        )
                    }
                },
                {
                    width: '11%',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div c_left2'}>
                                {record.finishedNum != undefined ? record.finishedNum : '--'}
                                <p>已完成</p>
                            </div>
                        )
                    }
                },
                {
                    width: '11%',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div c_left2'}>
                                {record.goalNum != undefined ? record.goalNum : '--'}
                                <p>目标</p>
                            </div>
                        )
                    }
                },
                {
                    width: '10%',
                    render: (text, record) =>
                        _this.state.userList.length >= 1 && _this.state.userRole !== 'EDC_AM' ? (
                            <div
                                onClick={eve => {
                                    eve.stopPropagation();
                                }}
                                className={'partPop'}
                                style={{ textAlign: 'right' }}
                            >
                                <Popconfirm
                                    title="确认删除该条数据?"
                                    okText='确认'
                                    cancelText='取消'
                                    /*icon={null}*/
                                    onConfirm={() => {
                                        this.handleDelete(record.id)
                                    }}
                                >
                                    <Button
                                        type="primary"
                                        danger
                                        style={{ height: 36 }}
                                    >
                                        {_this.formatIcon('icon-shanchu')}
                                    </Button>
                                </Popconfirm>
                            </div>
                        ) : null,
                },
            ],// 表格 行 配置
            rowId: '', // 表格选中行 id
            page: 1,//当前页码数
            pageSize: 10,//页码大小
            pagination: { // 分页组件元素对象信息
                onChange: (pageNo) => {
                    this.getPageData(pageNo);
                },
                current: 1,
                pageSize: 10,
                total: 100,
                showSizeChanger: false,
            },
            centerId: null,
            userId: null,
            loading: false
        }
    }

    formRef = React.createRef();
    inputRef = React.createRef();

    componentDidMount() {
        this.getPageData(1)
    }

    /**
     * icon 图标
     */
    formatIcon = iconName => {
        return iconName ? (<IconFont type={iconName} />) : undefined;
    }

    /**
     * 获取分页数据
     */
    getPageData = async (currentPage) => {
        const params = {
            name: _this.inputRef.current.state.value,
            projectCode: _this.props.projectCode,
            page: currentPage ? currentPage : this.state.page,
            pageSize: this.state.pageSize,
        }
        const res = await GetGroupPageList(params);
        _this.setState({
            loading: true
        })
        if (res) {
            _this.setState({
                // loading: false,
                userList: res.rows,
                page: res.page,
                pageSize: res.pageSize,
                pagination: {
                    ...this.state.pagination,
                    current: res.page,
                    pageSize: res.pageSize,
                    total: res.total,
                }
            },() =>{
                setTimeout(() =>{
                    this.setState({
                        loading: false
                    })
                }, 1000)
                
            })
        }
    }

    /**
     * 表单提交
     * @param values
     */
    handleFinish = (values) => {
        let params = {
            shortName: values.shortName,
            projectCode: _this.props.projectCode,
            groupName: values.groupName,
            goalNum: values.goalNum,
        }
        AddGroupPage(params).then(res => {
            if (res && res.code === 0) {
                Message.success('添加成功！', 1)
                _this.getPageData(1);
                _this.setState({
                    editBtnFlag: false,
                    editFormData: {
                        id: '',
                        groupCode: '',
                    },
                    rowId: '',
                })
                _this.formRef.current.resetFields();
            }
        })
    }

    /**
     * 搜索 events
     * @param value
     */
    handleSearch = async () => {
        const params = {
            projectCode: _this.props.projectCode,
            groupName: _this.inputRef.current.state.value,
            page: 1,
            pageSize: 10,
        }
        const res = await GetGroupPageList(params);
        _this.setState({
            userList: res.rows,
            page: res.page,
            pageSize: res.pageSize,
            pagination: {
                ...this.state.pagination,
                current: res.page,
                pageSize: res.pageSize,
                total: res.total,
            }
        })
    }

    /**
     * 删除
     * @returns {JSX.Element}
     */
    handleDelete = (id) => {
        _this.formRef.current.resetFields();
        _this.setState({
            editBtnFlag: false,
        })
        DeleteGroupPage(id).then(res => {
            if (res && res.code === 0) {
                this.getPageData()
                Message.success('删除成功！', 1)
                _this.setState({
                    rowId: '',
                    editBtnFlag: false,
                })
                _this.formRef.current.resetFields();
            }
        })
    }

    /**
     * 行点击 event
     * @returns {JSX.Element}
     */
    handleRow = (record) => {
        return {
            onClick: () => {
                _this.setState({
                    editFormData: {
                        id: record.id,
                        groupCode: record.groupCode
                    },
                    editBtnFlag: true,
                    rowId: record.id,
                })
                console.log(record)

                _this.formRef.current.setFieldsValue({
                    goalNum: record.goalNum,
                    groupName: record.groupName,
                    shortName: record.shortName,
                    id: record.id,
                });

            },
            onMouseEnter: () => {
                /*this.setState({
                    rowId: record.id,
                });*/
            },
        }
    };

    /**
     * 行点击高亮
     * @returns {JSX.Element}
     */
    handleSetRowClassName = (record) => {
        return record.id === _this.state.rowId ? 'clickRowStyl' : '';
    }

    /**
     * 修改
     * @returns {JSX.Element}
     */
    handleUpdate = () => {
        const form = _this.formRef.current
        const values = form.getFieldsValue(['shortName', 'groupName', 'goalNum']);
        debugger
        let params = {
            projectCode: _this.props.projectCode,
            id: _this.state.editFormData.id, // 修改 当条数据的id
            groupCode: _this.state.editFormData.groupCode, // 修改 当条数据的id
            shortName: values.shortName,
            groupName: values.groupName,
            goalNum: values.goalNum,
        }
        AddGroupPage(params).then(res => {
            if (res && res.code === 0) {
                Message.success('修改成功！', 1)
                _this.setState({
                    loading: true
                })
                _this.getPageData();
                _this.setState({
                    editFormData: {
                        id: '',
                        groupCode: ''
                    },
                    rowId: '',
                    editBtnFlag: false,
                })
                _this.formRef.current.resetFields();
            }
        })
    }

    /**
     * 取消 修改
     * @returns {JSX.Element}
     */
    handleCancel = () => {
        _this.setState({
            editBtnFlag: false,
            editFormData: {
                id: '',
                groupCode: ''
            },
            rowId: ''
        });
        _this.formRef.current.setFieldsValue({
            goalNum: '',
            groupName: '',
            shortName: '',
            id: '',
        });
    }

    render() {
        let { loading, userList, pagination, userRole, } = _this.state;
        return (
            <div className={'participants'}>
                <div className={'pp_top'} hidden={userRole === 'EDC_AM'}>
                    <div className={'selectQueryGroup'}>
                        <Form
                            layout={'inline'}
                            initialValues={{ layout: 'inline' }}
                            onFinish={!this.state.editBtnFlag ? _this.handleFinish : _this.handleUpdate}
                            ref={_this.formRef}
                            {...layout}
                        >
                            <Form.Item
                                name='shortName'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入分组简称',
                                    },
                                ]}
                                className={this.state.editBtnFlag ? 'editClass' : ''}
                                style={{ width: '20%', marginRight: 0, paddingRight: 16 }}
                            >
                                <Input
                                    placeholder="请输入分组简称不超过5个字符"
                                    style={{ width: '100%', marginBottom: 16, height: 36 }}
                                    allowClear
                                    maxLength={5}
                                />
                            </Form.Item>
                            <Form.Item
                                name='groupName'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入分组名称!',
                                    },
                                ]}
                                className={this.state.editBtnFlag ? 'editClass' : ''}
                                style={{ width: '20%', marginRight: 0, paddingRight: 16 }}
                            >
                                <Input
                                    placeholder="请输入分组名称"
                                    style={{ width: '100%', marginBottom: 16, height: 36 }}
                                    allowClear
                                    maxLength={50}
                                />
                            </Form.Item>

                            <Form.Item
                                name='goalNum'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入分组目标!',
                                    },
                                ]}
                                className={this.state.editBtnFlag ? 'editClass' : ''}
                                style={{ width: '20%', marginRight: 0, paddingRight: 16 }}
                            >
                                <InputNumber
                                    min={0}
                                    max={999999999}
                                    style={{ width: '100%', marginBottom: 16, height: 36, borderColor: '#EEEFF7' }}
                                    parser={value => value.replace(/^(-1+)|[^\d]+/g, '')}
                                    placeholder="请输入分组目标"
                                />
                            </Form.Item>

                            <Form.Item
                                style={{
                                    display: (_this.state.editBtnFlag ? 'none' : 'block'),
                                    textAlign: 'right',
                                    width: '100%',
                                }}
                            >
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    style={{ height: 36 }}
                                >
                                    添加
                                </Button>
                            </Form.Item>
                            <Form.Item
                                style={{
                                    display: (_this.state.editBtnFlag ? 'block' : 'none'),
                                    textAlign: 'right',
                                    width: '100%',
                                }}
                            >
                                <Button
                                    className={'cancelBtn'}
                                    onClick={_this.handleCancel}
                                    style={{ height: 36 }}
                                >
                                    {/*{_this.formatIcon('icon-quxiao')}*/}
                                    取消
                                </Button>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    style={{ height: 36 }}
                                >
                                    {/*{_this.formatIcon('icon-xiugai')}*/}
                                    修改
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
                <div className={'pp_content'}>
                    <div className={'search'}>
                        <span className={'rc_title'}>分组管理</span>
                        <div className={'search_group'}>
                            <Input
                                placeholder="查找"
                                style={{
                                    width: 300,
                                    height: 36,
                                    lineHeight: 1.8,
                                    borderColor: '#EEEFF7',
                                    borderTopLeftRadius: 2,
                                    borderBottomLeftRadius: 2,
                                    borderTopRightRadius: 0,
                                    borderBottomRightRadius: 0,
                                }}
                                allowClear
                                ref={this.inputRef}
                            />
                            <Button
                                type="primary"
                                htmlType="submit"
                                onClick={_this.handleSearch}
                                style={{
                                    height: 36,
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 0,
                                    borderTopRightRadius: 2,
                                    borderBottomRightRadius: 2,
                                }}
                            >
                                查找
                            </Button>
                        </div>
                    </div>
                    <div className={'itemList'}>
                        {
                            <Table
                                loading={loading}
                                locale={{ emptyText: "暂无数据" }}
                                columns={_this.state.columns}
                                dataSource={_this.state.userList}
                                showHeader={false}
                                defaultExpandAllRows={true}
                                expandIconAsCell={false}
                                onRow={_this.handleRow}
                                rowClassName={_this.handleSetRowClassName}
                                pagination={userList.length > 0 && pagination}
                                rowKey={'id'}
                            />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default Participants
