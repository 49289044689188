/**
 * 视频审计列表
 */
import React from 'react';
import './index.less';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import {
    Form, Input, Select, Button, message as Message, Table, message, Modal, DatePicker,
} from "antd";
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { GetProjectDataList, GetAuditVideoList, GetRoleProjectsEnume, GetAuditSign, GetSignStateEnume } from "../../services/audit/auditService";
import { getProjectTypeList } from "../../services/project/ProjectService";
import none from "../../static/images/none_video.png";
import { GetSubjectDetail, GetFormsById } from "../../services/data/DataService";
import CrfForm from '../../components/crf_form/Index';
const dateFormat = 'YYYY-MM-DD';
const FormItem = Form.Item;

let _this;
const { Option } = Select;
const { RangePicker } = DatePicker;

class videoAuditComponent extends React.Component {

    constructor(props) {
        super(props);
        _this = this;
        _this.state = {
            dataLists: [], // 列表集合
            columns: [
                {
                    title: '任务编码',
                    width: 120,
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>{record.taskCode}</div>
                            </div>
                        )
                    }
                },
                {
                    title: '签名状态',
                    width: 100,
                    render: (text, record) => {
                        return (
                            <div className={'con_div'}>
                                {/* <div className='title'>
                                    {record.signState == '0' ? '未签名' : record.signState == '1' ? '提交未审核' : record.signState == '2' ? '已审核未签名' : record.signState == '3' ? '不合格' : ''}
                                </div> */}

                                <div >
                                    {record.signState == '0' ? <div className='state_wait state_create'> <span className='dot'></span> 未签名</div>
                                        : record.signState == '1' ? <div className='state_wait'> <span className='dot'></span> 提交未审核</div>
                                            : record.signState == '2' ? <div className='state_wait state_approve'> <span className='dot'></span> 已审核</div>
                                                : record.signState == '3' ? <div className='state_wait state_error'> <span className='dot'></span> 不合格</div> : ''}
                                </div>
                            </div>
                        )
                    }
                },
                {
                    title: '所在医院',
                    width: 100,
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>{record.hospital}</div>
                            </div>
                        )
                    }
                },
                {

                    title: '上传医生',
                    width: 80,
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>{record.uploadUser}</div>
                            </div>
                        )
                    }
                },
                {
                    title: '医生账号',
                    width: 130,
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>{record.approveCode}</div>
                            </div>
                        )
                    }
                },
                {
                    title: '项目名称',
                    width: 240,
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>
                                    {record.projectName}
                                </div>
                            </div>
                        )
                    }
                },
                {
                    title: '审核内容',
                    width: 140,
                    render: (text, record, index) => {
                        if (record.approveTime) {
                            return (
                                <div className={'con_div'}>
                                    <div className={'title'}>{record.approveContent || '—'}</div>
                                </div>
                            )
                        }
                    }
                },
                {
                    title: '审核备注',
                    width: 160,
                    render: (text, record, index) => {
                        if (record.approveTime) {
                            return (
                                <div className={'con_div'}>
                                    <div className={'title'}>{record.approveRemark || '—'}</div>
                                </div>
                            )
                        }
                    }
                },
                {
                    title: '上传时间',
                    width: 115,
                    render: (text, record, index) => {
                        if (record.uploadTime) {
                            return (
                                <div className={'con_div'}>
                                    <div className={'sub-title'}>{record.uploadTime ? record.uploadTime.replace('T', ' ') : ''}</div>
                                </div>
                            )
                        }
                    }
                },
                {
                    title: '审核人',
                    width: 100,
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>{record.approveUser}</div>
                            </div>
                        )
                    }
                },
                {
                    title: '结算状态',
                    width: 100,
                    render: (text, record, index) => {
                        return (
                            <div className='con_div'>
                                {record.manualSettlementState == '1' ? <span style={{ color: '#3391F0' }}>已结算</span> : <span style={{ color: '#52C41A' }}>待结算</span>}
                            </div>
                        )
                    }
                },
                {
                    title: '审核时间',
                    width: 115,
                    render: (text, record, index) => {
                        if (record.approveTime) {
                            return (
                                <div className={'con_div'}>
                                    <div className={'sub-title'}>{record.approveTime ? record.approveTime.replace('T', ' ') : ''}</div>
                                </div>
                            )
                        }
                    }
                },
                {
                    title: '操作',
                    fixed: 'right',
                    width: 120,
                    render: (text, record) =>
                        <div
                            onClick={eve => {
                                eve.stopPropagation();
                            }}
                            className={'partPop'}
                        >
                            <Button type="link" onClick={() => this.onVideoDetails(record)}>查看</Button>
                            <Button type="link" disabled={!record.signPdfUrl} onClick={() => this.showSign(record)}>查看PDF</Button>
                        </div>
                },
            ],// 表格 行 配置
            page: 1,//当前页码数
            pagination: { // 分页组件元素对象信息
                onChange: (pageNo) => {
                    this.getPageData(pageNo);
                },
                current: 1,
                pageSize: 10,
                total: 100,
                showSizeChanger: false,
            },
            isVisible: false, // 模态框
            projectList: [], // 所有项目
            projectTypeList: [], // 所有项目
            videoList: [],


            formModalVisible: false,
            clickedTenantCode: '', // 当前点击的租户
            crfParams: null, // crf表单初始化
            repeatSubjectCodeList: [], // 重复受试者code列表

            visible: false,
            pdfUrl: '',
            pdfLoading: false
        }
    }

    formRef = React.createRef();
    videoRef = React.createRef();

    componentDidMount = () => {
        // 获取项目枚举
        this.getProjectEnume();
        this.getTypeList();
        this.getSignStateList();
        // 列表
        this.getPageData();
    }

    componentWillUnmount = () => {
        sessionStorage.removeItem('video_audit_search')
    }

    // 获取项目枚举
    getProjectEnume = () => {
        GetRoleProjectsEnume().then(res => {
            this.setState({
                projectList: res || []
            })
        })
    }

    // 获取项目枚举
    getTypeList = () => {
        getProjectTypeList().then(res => {
            let list = []
            for (const key in res.data) {
                list.push(res.data[key])
            }
            this.setState({
                projectTypeList: list
            })
        })
    }

    // 获取签名状态枚举
    getSignStateList = () => {
        // GetSignStateEnume().then(res => {

        // })
    }

    /**
     * 获取列表数据 
     */
    getPageData = async (curPage) => {
        let { page } = this.state;
        let params = {
            page: curPage ? curPage : page,
            pageSize: this.state.pagination.pageSize
        }

        let searchValues = sessionStorage.getItem('video_audit_search');
        let searchData = searchValues ? JSON.parse(searchValues) : null
        if (searchData) {
            if (searchData.projectCode) {
                params['projectCode'] = searchData.projectCode
            }
            if (searchData.uploadUser) {
                params['uploadUser'] = searchData.uploadUser
            }
            if (searchData.taskCode) {
                params['taskCode'] = searchData.taskCode
            }
            if (searchData.hospital) {
                params['hospital'] = searchData.hospital
            }
            if (searchData.projectType) {
                params['projectType'] = searchData.projectType
            }
            if (searchData.signState) {
                params['signState'] = searchData.signState
            }
            if (searchData.manualSettlementState) {
                params['manualSettlementState'] = searchData.manualSettlementState
            }
            if (searchData.date) {
                params['startDate'] = moment(searchData.date[0]).format('YYYY-MM-DD')
                params['endDate'] = moment(searchData.date[1]).format('YYYY-MM-DD')
            }
        }

        const res = await GetProjectDataList(params);
        if (res && res.code == 0) {
            _this.setState({
                dataLists: [],
                page: res.data.page,
                pageSize: res.data.pageSize,
                pagination: {
                    ...this.state.pagination,
                    current: res.data.page,
                    pageSize: res.data.pageSize,
                    total: res.data.total,
                }
            })

            if (res.data.rows) {
                _this.setState({
                    dataLists: [...res.data.rows]
                })
            }
        }
    }

    // 表单值改变时
    onValuesChange = (changedValues, allValues) => {
        sessionStorage.setItem('video_audit_search', JSON.stringify(allValues));
        this.getPageData(1)
    }


    // 查看上传视频内容
    onVideoDetails = (record) => {
        if (record.projectType == 'VIDEOSOLICIT') {
            this.setState({
                isVisible: true,
            }, () => {
                let params = {
                    taskCode: record.taskCode,
                    tenantCode: record.tenantCode
                }

                GetAuditVideoList(params).then(res => {
                    if (res) {
                        this.setState({
                            videoList: res
                        })
                    }
                })
            })
        } else {
            this.toDetail(record)
        }
    }

    /**
     * 关闭
     */
    handleOk = () => {
        if (this.videoRef.current) {
            this.videoRef.current.pause();
        }
        this.setState({
            isVisible: false
        })
    }


    /**
     * 获取表单前置信息
     */
    toGetSubjectDetail = (subjectCode = '') => {
        return new Promise(resolve => {
            GetSubjectDetail(subjectCode).then(res => {
                if (res.code == 0) {
                    return resolve(res.data || {});
                }
            })
        });
    }

    /**
     *  获取表单前置条目信息
     */
    toGetFormsById = (id) => {
        return new Promise(resolve => {
            GetFormsById(id).then(res => {
                if (res.code == 0) {
                    return resolve(res.data || {});
                }
            })
        });
    }



    /**
     *表单 详情
     */
    toDetail = (item) => {
        this.toGetSubjectDetail(item.taskCode).then((res) => {
            this.setState({
                repeatSubjectCodeList: res.repeatSubjectCodeList
            })

            if (res.visits.length != 0) {
                let id = res.visits[0] && res.visits[0].id;

                let spreadList = []
                res.visits.forEach(item => {
                    spreadList.push(
                        this.toGetFormsById(item.id)
                    )
                })
                Promise.all(spreadList).then(res => {
                    let dataForms = []
                    res.forEach(v => {
                        dataForms = dataForms.concat(v.forms)
                    })

                    let filterArr = (dataForms || []).map(item => {
                        return {
                            title: item.name,
                            id: item.id,
                            formCode: item.code
                        };
                    });

                    let forms = {
                        activeId: filterArr[0] && filterArr[0].id,
                        forms: filterArr,
                        state: dataForms[0] && dataForms[0].inputState
                    };
                    localStorage.setItem('localStorage_forms', JSON.stringify(forms));
                    this.setState({
                        clickedTenantCode: item.tenantCode
                    }, () => {
                        this.setState({
                            formModalVisible: true
                        })
                    })

                    // crf
                    if (dataForms[0] && dataForms[0].type == 'CRF') {
                        let d = dataForms[0];
                        let photoEditItem = sessionStorage.getItem('photoEditItem')
                        let crfParams = {
                            caseCode: item.subjectCode,
                            crfCode: d.code,
                            crfVersion: d.version,
                            eventCode: id,
                            headers: {},
                            projectCode: item.projectCode,
                            recordCode: d.id,
                            tenantCode: item.tenantCode,
                            userCode: item.userCode
                        }

                        this.setState({
                            crfParams
                        })
                    } else {
                        this.setState({
                            crfParams: null
                        })
                    }
                })
            }
        });;
    }

    /**
     * 当关闭表单触发
     */
    onCloseModal = () => {
        if (this.formRef.current) {
            this.formRef.current.closeModal();
        }
    }

    /**
     * 关闭弹框
     */
    closeFormModal = () => {
        this.setState({
            formModalVisible: false
        });
    }


    /**
     * 展示病例签名详情
     * @param {*} data 
     */
    showSign = (data) => {
        let params = {
            subjectCode: data.taskCode
        }

        GetAuditSign(params).then(res => {
            if (res.code == 0 && res.data) {
                this.setState({
                    pdfUrl: res.data,
                    visible: true,
                })
            } else {
                message.error('暂无病例报告', 1)
            }
        })
    }



    render() {
        let { columns, dataLists, pagination, isVisible, projectList, videoList, projectTypeList, clickedTenantCode, crfParams, repeatSubjectCodeList, pdfUrl } = this.state;

        return (
            <div className='video_audit'>
                <div className={'audit_wrap'}>
                    <div className={'search_wrap'} id="search_wrap">
                        <Form
                            layout="inline"
                            className={'codeSetForm'}
                            onValuesChange={this.onValuesChange}
                            autoComplete="off"
                            ref={this.formRef}
                            requiredMark={true}
                        >

                            <FormItem
                                name="taskCode"
                                label=""
                                rules={[{ whitespace: true, message: '不能输入空格' }]}
                            >
                                <Input placeholder="请输入任务编码" allowClear style={{ width: '280px' }} />
                            </FormItem>
                            <FormItem
                                name="uploadUser"
                                label=""
                                rules={[{ whitespace: true, message: '不能输入空格' }]}
                            >
                                <Input placeholder="请输入医生姓名或账号" allowClear style={{ width: '280px' }} />
                            </FormItem>
                            <FormItem
                                name="hospital"
                                label=""
                                rules={[{ whitespace: true, message: '不能输入空格' }]}
                            >
                                <Input placeholder="请输入所在医院" allowClear style={{ width: '280px' }} />
                            </FormItem>
                            <FormItem
                                name="date"
                                label=""
                            >
                                <RangePicker locale={locale} style={{ width: '280px' }} />
                            </FormItem>

                            <Form.Item
                                label=""
                                name="projectCode"
                            >
                                <Select defaultValue="所有项目" size='middle' style={{ width: '250px' }} >
                                    <Option value={''}>所有项目</Option>
                                    {projectList.length > 0 && projectList.map(project => (
                                        <Option key={project.projectCode} value={project.projectCode}>{project.projectName}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label=""
                                name="projectType"
                            >
                                <Select defaultValue="项目类型" size='middle' style={{ width: '280px' }} >
                                    <Option value={''}>所有项目类型</Option>
                                    {projectTypeList.length > 0 && projectTypeList.map(project => (
                                        <Option key={project.code} value={project.code}>{project.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label=""
                                name="signState"
                            >
                                <Select defaultValue="签名状态" size='middle' style={{ width: '280px' }} >
                                    <Option value={''}>所有签名状态</Option>
                                    <Option value={'0'}>未签名</Option>
                                    <Option value={'1'}>提交未审核</Option>
                                    <Option value={'2'}>已审核</Option>
                                    <Option value={'3'}>不合格</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label=""
                                name="manualSettlementState"
                            >
                                <Select defaultValue="结算状态" size='middle' style={{ width: '280px' }} >
                                    <Option value='0'>待结算</Option>
                                    <Option value='1'>已结算</Option>
                                </Select>
                            </Form.Item>
                        </Form>
                    </div>
                    {/* <div style={{maxHeight: `calc(100% - ${h}px)`, height: `calc(100% - ${h}px)`,}}> */}
                    <Table
                        className='table_content'
                        locale={{ emptyText: "暂无数据" }}
                        columns={columns}
                        dataSource={dataLists}
                        pagination={dataLists.length !== 0 && pagination}
                        rowKey={'taskCode'}
                        scroll={{ x: 1800 }}
                    />
                    {/* </div> */}

                </div>

                {/* 查看视频内容 */}
                <Modal
                    title="视频内容"
                    visible={isVisible}
                    onOk={this.handleOk}
                    okText='确认'
                    cancelText='关闭'
                    onCancel={this.handleOk}
                    width='45%'
                >
                    <div className="videoAuditList">
                        {
                            videoList && videoList.length > 0 ? videoList.map((url) => (
                                <div className="videoItem">
                                    <video
                                        controls
                                        autoPlay
                                        src={url}
                                        ref={this.videoRef}
                                    >
                                    </video>
                                </div>
                            )) : <div style={{ height: '100%', textAlign: 'center' }}><img src={none} alt='' /></div>
                        }
                    </div>
                </Modal>


                {/* 表单填写弹框 */}
                <Modal
                    visible={this.state.formModalVisible}
                    title={null}
                    onCancel={this.closeFormModal}
                    width={'90%'}
                    centered
                    closable={false}
                    destroyOnClose
                    maskClosable={false}
                    footer={null}>
                    <CrfForm closeModal={this.closeFormModal} ref={this.formRef} crfUseType='dataAudit' textAreaMaxLen={1000} fromApproveRecord={true} {...this.props} tenantCode={clickedTenantCode} crfParams={crfParams}></CrfForm>
                </Modal>

                {/* 查看病例签名 */}
                <Modal
                    title="PDF"
                    visible={this.state.visible}
                    onCancel={() => { this.setState({ visible: false }) }}
                    width={1000}
                    footer={[
                        <div className='opertion_warp'>
                            <Button type="primary" onClick={() => { this.setState({ visible: false }) }}>
                                关闭
                            </Button>
                        </div>
                    ]}
                >
                    <div style={{ display: 'flex' }}>
                        {
                            pdfUrl.includes('pdf') ?
                                <iframe src={pdfUrl} style={{ width: 900, height: 600, margin: '0 auto' }}>
                                    <p>您的浏览器不支持 iframe 标签</p>
                                </iframe> :
                                <div style={{ width: 900, height: 600, margin: '0 auto', overflowY: 'auto' }}>
                                    <img src={pdfUrl} style={{ width: '100%' }}>
                                    </img>
                                </div>
                        }
                    </div>
                </Modal >


            </div>
        );
    }
}
export default withRouter(videoAuditComponent);