/**
 * 图片预览
 */
 import React, { Component } from 'react';
 import './index.less';
 import { Button, Input, message, Row, Col, Switch, List, Modal, Image, ConfigProvider, Spin } from 'antd';
 import {
     ExclamationCircleOutlined, QuestionCircleTwoTone, CheckCircleTwoTone,
     EyeOutlined, CoffeeOutlined, FundProjectionScreenOutlined, FacebookFilled
 } from '@ant-design/icons';
 import zhCN from 'antd/lib/locale/zh_CN';
 import { GetDctApprovalOperation, CancelDctApprovalOperation } from "../../../../../../../services/dct/DctService";
 
 let _this;
 
 class ModalPreviewComponent extends Component {
 
     constructor(props) {
         super(props);
         _this = this;
         this.state = {
             isVisible: false, // 图片列表显隐
             visible: false,// modal显隐
             ImgData: null, // 
             recordList: null, // 操作记录
         };
         this.rejectReason = '' // 拒绝原因
     }
 
     componentDidMount() {
         if (this.props.data) {
             let { data } = this.props;
             sessionStorage.setItem('detailData',JSON.stringify(data))
             this.setState({
                ImgData: data,
                recordList: data.list
            })
         }
     }
 
     UNSAFE_componentWillReceiveProps = (nextProps) => {
         if (nextProps.data) {
            sessionStorage.setItem('detailData',JSON.stringify(nextProps.data))
             this.setState({
                 ImgData: nextProps.data,
                 recordList: nextProps.data.list
             })
         }
     }
 
     // 预览
     onPreview = () => {
         this.setState({
             isVisible: true
         })
     }
     onVisibleChange = (val) => {
         this.setState({
             isVisible: val
         })
     }
 
     // 关闭拒绝模态框
     closeModal = () => {
         this.setState({
             visible: false,
         });
     }
 
     // 渲染图片信息
     renderImgInfo = (item) => {
         let ImgInfoData = []
         if (!item || item.length == 0) {
             return
         }
         ImgInfoData = [
            //  { label: '', value: item.formName },
             { label: '所属项目：', value: item.projectName },
             { label: '所属中心：', value: item.centerName },
             { label: '采集人：', value: item.collector ? item.collector : item.collectorCode },
             { label: `${sessionStorage.getItem('targetName') || '受试者'}：`, value: `${item.subjectName || ''}（${item.subjectCode}）` },
             { label: '访视：', value: item.visitName },
             { label: '表单：', value: item.formName }
         ]
         return ImgInfoData
     }
 
     // 获取拒绝信息
     getModalValue = (v) => {
         this.rejectReason = v
         this.onChecked('REJECT')
     }
 
     // 渲染图片信息
     renderInfoDom = (values) => {
         let dom = <>
             <div className="info_left">
                 {this.renderImgInfo(values).map((item, index) => (
                     <div className={'subtit'} key={index}><label>{item.label}</label>{item.value}</div>
                 ))}
             </div>
         </>
         return dom
     }
 
     render() {
         let { ImgData, isVisible } = this.state;
         ImgData = ImgData || JSON.parse(sessionStorage.getItem('detailData'))
         return (
            <div className="left_magnify">
                <div className="magnify_wrap">
                    <ConfigProvider locale={zhCN}>
                        <div className="ImageBox">
                            <Image
                                src={(ImgData && ImgData.url) || ""}
                            />
                        </div>
                    </ConfigProvider>
                </div>
                <div className="info_wrap">
                    {ImgData && this.renderInfoDom(ImgData)}
                </div>
            </div>
         )
     }
 }
 export default ModalPreviewComponent