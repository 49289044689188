
import modStyl from './PhoneModal.module.scss'
import { Modal } from "antd"
import React, { useContext, useState } from "react"
import UserInfo from './UserInfo'
import CallPhoneNumber from './CallPhoneNumber'
import { DataType, visitTaskContext } from '..'

type Props = {
  current: DataType;
  onClose: ()=> void;
}

const PhoneModal: React.FC<Props> = ({current, onClose})=> {
  // const ctx = useContext(visitTaskContext)
  return (
    <Modal visible={true} title="电话随访" onCancel={onClose} footer={null} closable maskClosable={false} destroyOnClose>
      <div className={modStyl.wrapper}>
        <div className={modStyl.row}>
          <div className={modStyl.label}>患者</div>
          <div className={modStyl.content}>
            <UserInfo data={current}/>
          </div>
        </div>
        <div className={modStyl.row}>
          <div className={modStyl.label}>联系号码</div>
          <div className={modStyl.content}>
            <p className={modStyl.readonly}>152****4433</p>
          </div>
        </div>
        <div className={modStyl.row}>
          <CallPhoneNumber/>
        </div>
      </div>
    </Modal>
  )
}

export default PhoneModal