import { requestGetRes, requestPostRes } from "../EdcService";

/**
 * 转移受试者到
 * @param {*} id 
 * @returns 
 */
type TransoutSubjectParams = {
  projectId: number;
  centerId: number;
  transferCode: string;
  recipientCode: string;
  totalSubjects: number;
  subjectCodes: string[];
}
export const transoutSubject = (params: TransoutSubjectParams) => {
  return requestPostRes('/project/user/subject/transferSingle', params)
};