import React from 'react';
import {Tabs} from 'antd';
import './Detail.less';
import Setting from "./setting/setting";
import Monitoring from "./monitoring/monitoring";
import ResearchCenter from "./researchCenter/researchCenter";
import Participants from "./participants/participants";
import GroupManage from "./groupManage/groupManage";
import RegionalManagement from "./regionalManagement/regionalManagement";
// import Settlement from "./settlement/settlement";
import ProjectSettlement from "./projectSettlement/projectSettlement";
import ProjectReport from "./projectReport/projectReport";
import PubSub from "pubsub-js";
import {createFromIconfontCN} from '@ant-design/icons';
import {withRouter} from 'react-router-dom';

import {GetProjectDetailInfo} from "../../../services/project/ProjectService";

let _this;
const {TabPane} = Tabs;
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_1788044_0dwu4guekcwr.js', // icon-javascript, icon-java, icon-shoppingcart (overrided)
        '//at.alicdn.com/t/font_1788592_a5xf2bdic3u.js', // icon-shoppingcart, icon-python
        '//at.alicdn.com/t/font_542587_l6tst4dbwjq.js', // icon-jingdiananli_wujiaoxing_shoucanghou icon-dingwei
    ],
});
class ProjectDetail extends React.Component {

    constructor(props) {
        super(props);
        _this = this;

        sessionStorage.setItem('projectId',props.location.state.id);
        let currentRole = sessionStorage.getItem('roleCode');

        _this.state = {
            userRole: currentRole,
            key: "",
            projectId: props.location.state.id ? props.location.state.id : '',
            judgeKeyFlag: true,
            detailTitle: '项目测试一',
            detailDate: '2021-12-31',
            detailTarget: '2000',
            detailIntroduceCon: '项目介绍',
            detailProjectStatus: '进行中',
            detailProjectType: '上市后研究',
            detailApplicationDraft: '测试草案',
            detailData: {},
            activeKey: '1', // tabs 切换的key
            shadowFlag: false, // 监听滚动的高度 显隐 阴影
        }
    }

    divRef = React.createRef();

    componentDidMount() {
        if (_this.props.location.state.id !== '') {
            GetProjectDetailInfo(_this.state.projectId).then(res => {
                let statusList = '';
                if (res.status == "PROCESS") {
                    statusList = '进行中'
                } else if (res.status == "STOP") {
                    statusList = '已暂停'
                } else if (res.status == "END") {
                    statusList = '已完成'
                }
                _this.setState({
                    detailTitle: res.name,
                    detailDate: res.endTimeVal,
                    detailTarget: res.groupGoal,
                    detailIntroduceCon: res.description,
                    detailProjectStatus: statusList,
                    detailProjectType: res.typeName,
                    detailApplicationDraft: res.planName,
                    detailData: res
                })

                sessionStorage.setItem('projectCode',res.code);
            })
        } else {
            _this.props.history.push("/noConfigPage");
        }

        PubSub.subscribe("onProjectSetSave", (subscribeName, projectId) => {
            GetProjectDetailInfo(projectId).then(res => {
                let statusList = '';
                if (res.status == "PROCESS") {
                    statusList = '进行中'
                } else if (res.status == "STOP") {
                    statusList = '已暂停'
                } else if (res.status == "END") {
                    statusList = '已完成'
                }
                _this.setState({
                    activeKey: '1',
                    judgeKeyFlag: true,
                    detailTitle: res.name,
                    detailDate: res.endTimeVal,
                    detailTarget: res.groupGoal,
                    detailIntroduceCon: res.description,
                    detailProjectStatus: statusList,
                    detailProjectType: res.typeName,
                    detailApplicationDraft: res.planName,
                    detailData: res,
                })
            })
        });

        // 定位到项目报表tab页
        if(_this.props.location.state.activeKey){
            this.setState({
                activeKey: _this.props.location.state.activeKey
            })
        }
    }

    /**
     * icon 图标
     */
    formatIcon = iconName => {
        return iconName ? (<IconFont type={iconName}/>) : undefined;
    }

    /**
     * 选择
     */
    handleChange = (key) => {
        this.setState({
            activeKey: key,
            shadowFlag: false
        })
        if (key == '1') {
            _this.setState({
                judgeKeyFlag: true
            })
        } else {
            _this.setState({
                judgeKeyFlag: false
            })
        }

    }

    /**
     * 监听滚动
     */
    handleOnScroll = () => {
        if (this.divRef.current.scrollTop > 0) {
            this.setState({
                shadowFlag: true
            })
        } else {
            this.setState({
                shadowFlag: false
            })
        }
    }

    onSave = (value) => {
        this.setState({
            activeKey: value
        })
    }

    render() {
        let { activeKey, userRole} = _this.state;

        return (
            <div className={'detail'}>
                <div className={'detail_title'}>
                    <div className={'p_title_left'}>
                        {/*{_this.formatIcon('icon-python')}*/}
                        <span></span>
                        {_this.state.detailTitle}
                    </div>
                    <div className={'p_title_right'} style={{display: (_this.state.judgeKeyFlag) ? 'none' : 'block'}}>
                        <div className={'p_t_r_date'}>
                            <span>截止日期：</span>
                            <span>{_this.state.detailDate}</span>
                        </div>
                        <div className={'p_t_r_target'}>
                            <span>目标样本数：</span>
                            <span>{_this.state.detailTarget}</span>
                        </div>
                    </div>
                </div>
               
                <div
                    className={`detail_con ${_this.state.activeKey == '1' ? 'mon' : 'other'} ${_this.state.shadowFlag ? 'shadowDiv' : ''}`}
                    onScrollCapture={() => this.handleOnScroll()}
                    ref={this.divRef}
                >
                    <Tabs
                        tabBarStyle={{
                            'color': '#101010',
                        }}
                        tabBarGutter={58}
                        tabPosition={'top'}
                        activeKey={_this.state.activeKey}
                        onChange={_this.handleChange}
                    >
                        <TabPane tab="项目监控" key="1">
                            {_this.state.activeKey == '1' ? <Monitoring projectId={_this.state.projectId}/> : null}
                        </TabPane>
                        <TabPane tab="项目报表" key="7">
                            {_this.state.activeKey === '7' ?
                                <ProjectReport
                                    {...this.props}
                                    projectId={_this.state.projectId}
                                    detailTitle={_this.state.detailTitle}
                                /> : null}
                        </TabPane>
                        {userRole !== 'EDC_AM' ? (
                            <TabPane tab="项目研究中心" key="2" >
                                {_this.state.activeKey == '2' ? <ResearchCenter projectId={_this.state.projectId}/> : null}
                            </TabPane>
                        ) : null}
                        <TabPane tab="项目参与者" key="3">
                            {_this.state.activeKey == '3' ? <Participants projectId={_this.state.projectId}/> : null}
                        </TabPane>
                        {userRole !== 'EDC_AM' ? (
                            <TabPane tab="项目区域管理" key="4" hidden={userRole === 'EDC_AM'}>
                                {_this.state.activeKey == '4' ?
                                    <RegionalManagement projectId={_this.state.projectId}/> : null}
                            </TabPane>
                        ) : null}
                        {userRole !== 'EDC_AM' ? (
                            <TabPane tab="分组管理" key="8" hidden={userRole === 'EDC_AM'}>
                                {_this.state.activeKey == '8' ?
                                    <GroupManage projectId={_this.state.projectId} projectCode={_this.state.detailData.code} /> : null}
                            </TabPane>
                        ) : null}
                        {userRole !== 'EDC_AM' ? (
                            <TabPane tab="结算管理" key="5" hidden={userRole === 'EDC_AM'}>
                                {/* {_this.state.activeKey === '5' ? <Settlement projectId={_this.state.projectId} detailTitle={_this.state.detailTitle} /> : null} */}
                                {_this.state.activeKey === '5' ? <ProjectSettlement projectId={_this.state.projectId} detailTitle={_this.state.detailTitle} /> : null}
                            </TabPane>
                        ) : null}
                        {userRole !== 'EDC_AM' ? (
                            <TabPane tab="项目设置" key="6" hidden={userRole === 'EDC_AM'}>
                                {_this.state.activeKey == '6' ?
                                    <Setting projectId={_this.state.projectId} {..._this.state.detailData} onSave={this.onSave}/> : null}
                            </TabPane>
                        ) : null}
                    </Tabs>
                </div>
            </div>
        );
    }
}

export default withRouter(ProjectDetail)