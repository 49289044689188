import modStyl from './AudioPlayer.module.scss'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { CaretRightFilled, PauseOutlined, RedoOutlined } from '@ant-design/icons'
import { formatSeconds } from './utils';

enum PlayStatus {
  Ready,
  Pause,
  Play,
  Again,
}

//#region 播放按钮
type PlayButtonProps = {
  status: PlayStatus;
  onClick: ()=> void;
}
const PlayButton: React.FC<PlayButtonProps> = ({onClick, status})=> {
  const s = {
    // 初始状态显示播放按钮
    [PlayStatus.Ready]: <CaretRightFilled/>,
    // 正在播放显示暂停按钮
    [PlayStatus.Play]: <CaretRightFilled/>,
    // 暂停状态显示播放按钮
    [PlayStatus.Pause]: <PauseOutlined/>,
    // 重播状态显示重播按钮
    [PlayStatus.Again]: <RedoOutlined/>
  }
  return (
    <button onClick={onClick} type="button">{s[status]}</button>
  )
}
//#endregion


type Props = {
  label?: string;
  url: string;
  duration: number;
}

const AudioPlayer: React.FC<Props> = (props)=> {

  const audioRef = useRef<HTMLAudioElement>(null)

  const lab = useMemo(()=> {
    return props.label ? props.label : new URL(props.url).pathname
  }, [props.label, props.url])


  const [status, setStatus] = useState(PlayStatus.Ready)

  const [duration, setDuration] = useState(()=> props.duration ? formatSeconds(props.duration) : '--:--')

  const player = ()=> {
    if(!audioRef.current) {
      return
    }

    const audio = audioRef.current

    if(status === PlayStatus.Ready || status === PlayStatus.Again) {
      audio.play()
      setStatus(PlayStatus.Pause)
    } else if (status === PlayStatus.Pause) {
      audio.play()
      setStatus(PlayStatus.Play)
    } else if(status === PlayStatus.Play) {
      audio.pause()
      setStatus(PlayStatus.Pause)
    }
  }

  const [percent, setPercent] = useState(0)

  const timeupdate = function({target}: Event) {
    const cur = Math.floor((target as HTMLAudioElement).currentTime)
    const dur = Math.floor((target as HTMLAudioElement).duration)
    const percentValue = Math.floor(cur / dur * 10000) / 100
    setPercent(percentValue)
    setDuration(formatSeconds(cur))
    if(percentValue === 100) {
      setStatus(PlayStatus.Again)
    }
  }

  useEffect(()=> {
    if(audioRef.current) {
      audioRef.current.addEventListener('timeupdate', timeupdate)
    }

    return ()=> {
      if(audioRef.current) {
        audioRef.current.removeEventListener('timeupdate', timeupdate)
      }
    }
  }, [audioRef.current])

  return (
    <div className={modStyl.player}>
      <div className={modStyl.west}>
        <PlayButton onClick={player} status={status}/>
      </div>
      <div className={modStyl.east}>
        <h2>{lab}</h2>
        <p>
          <span>{duration}</span>
          <span>2020-12-12 12:12:12</span>
        </p>
      </div>
      <audio ref={audioRef} src={props.url}/>
      <div className={modStyl.background} style={{width: `${percent}%`}}></div>
    </div>
  )
}

export default AudioPlayer