
import React from "react"

type DictItem<T> = {
  value: number,
  label: T
}

declare global {
  interface Array<T> {
    transform(ignoreEmpty?: boolean): DictItem<T>[];
    toMap(): Map<number, T>;
  }
}

if(!Array.prototype.transform) {
  // eslint-disable-next-line no-extend-native
  Array.prototype.transform = function <T>(this: T[], ignoreEmpty=true) {
    const pieces: DictItem<T>[] = []
    this.forEach((label, value)=> {
      if(ignoreEmpty && label === '') {
        return
      }
      pieces.push({
        label,
        value
      })
    })
    return pieces
  }
}
if(!Array.prototype.toMap) {
  // eslint-disable-next-line no-extend-native
  Array.prototype.toMap = function<T>(this: DictItem<T>[]) {
    const m = new Map<number, T>()
    this.forEach(({label, value})=> {
      m.set(value, label)
    })
    return m
  }
}

// 随访状态
export const visitStatusDict = ['待随访', '随访中', '已随访', '已失效'].transform();
// 随访类型
export const visitTypeDict = ['', '用药提醒', '问卷提醒'].transform();
// 受试者状态
export const examineeStatusDict = ['', '死亡', '失访', '撤回知情同意', '生存'].transform();
// 性别
export const genderDict = ['', '男', '女'].transform();
// 本人还是家属
export const whoisDict = ['', '本人', '家属'].transform();
// 学历
export const qualDict = ['', '文盲', '小学', '初中', '高中', '大学', '研究生及以上'].transform();

const dictMap = new Map([
  // 学历
  ['qual', qualDict.toMap()],
  // 本人还是家属
  ['whois', whoisDict.toMap()],
  // 性别
  ['gender', genderDict.toMap()],
  // 随访状态
  ['visitStatus', visitStatusDict.toMap()],
  // 随访类型
  ['visitType', visitTypeDict.toMap()],
  // 受试者状态
  ['examineeStatus', examineeStatusDict.toMap()],
])

type Props = {
  name: string;
  value: number | string | null;
}
const DictTransform: React.FC<Props> = ({name, value})=> {
  if('' === value || null === value || !dictMap.has(name)) {
    return null
  }
  return <span>{dictMap.get(name)?.get(Number(value))}</span>
}

export default DictTransform