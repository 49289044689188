import { CloseOutlined, InfoOutlined } from "@ant-design/icons";

import modStyl from './Current.module.scss'
import React, { PropsWithChildren, useState } from "react";

interface Props<T> {
  data: T;
}

const Current = <T extends {[name: string]: unknown}>({data}: PropsWithChildren<Props<T>>)=> {
  
  const [visible, setVisible] = useState(false)

  return (
    <>
    {
      visible ? (
        <div className={modStyl.lock}>
          <div className={modStyl.inner}>
            <h2>{data.formName}</h2>
            <ul>
              <li>
                <span>所属项目：</span>
                <span>{data.projectName}</span>
              </li>
              <li>
                <span>所属中心：</span>
                <span>{data.centerName}</span>
              </li>
              <li>
                <span>访视：</span>
                <span>{data.visitName}</span>
              </li>
              <li>
                <span>表单：</span>
                <span>{data.formName}</span>
              </li>
              <li>
                <span>{sessionStorage.getItem('targetName') || '受试者'}：</span>
                <span>{data.subjectName} ({data.subjectCode})</span>
              </li>
              <li>
                <span>采集人：</span>
                <span>{data.collector}</span>
              </li>
            </ul>
          </div>
          <button onClick={()=> setVisible(false)} type="button" className={modStyl.close}>
            <CloseOutlined/>
          </button>
        </div>
      ) 
      
      : (
        <button onClick={()=> setVisible(true)} type="button" className={modStyl.toggle}>
          <InfoOutlined/>
        </button>
      )
    }
    </>
  )
}

export default Current;