import modStyl from './subjectBind.module.scss'
import React, { useState, useEffect, useMemo } from "react";
import { Button, Popconfirm} from "antd";
import { ExclamationCircleOutlined, LinkOutlined, UnlockOutlined} from "@ant-design/icons";
import { QRCodeCanvas } from "qrcode.react";
import storageX, { Engine } from '../../../../../../util/storageX';
import { getSubjectBinding, getSubjectBindState, subjectUnbind } from '../../../../../../services/data/patientSelfReported';


//#region 知情显示
type SignStateProps = {
  subjectName: string;
  signState: 0 | 1 | 2 | 3;
}
const SignState:React.FC<SignStateProps> = ({signState, subjectName})=> {
  return (
    <div className={modStyl.state}>
      {
        subjectName.length > 0 && (
          <span>{subjectName}</span>
        )
      }
      <span className={modStyl.label}>（已知情）</span>
    </div>
  )
}
//#endregion

//#region 解绑按钮
type UnbindButtonProps = {
  onClick: ()=> void;
}
const UnbindButton: React.FC<UnbindButtonProps> = ({onClick})=> {
  return (
    <Popconfirm
      onConfirm={onClick}
      okText="确定"
      cancelText="取消"
      icon={<ExclamationCircleOutlined />}
      title="您正在解绑患者，解绑之后需要重新绑定患者！">
      <Button size="small" type="ghost" danger icon={<UnlockOutlined/>}>解绑</Button>
    </Popconfirm>
  )
}
//#endregion

// informed: number | null 君实知情签署类型 1线上 2线下
type Props = {
  informed: null | number;
  subjectCode: string;
  resumeVisitId: string;
  state: string;
  subjectName: string;
  signState: 0 | 1 | 2 | 3;
  refresh: (resumeVisitId: string)=> void;
}
type Info = {
  bindState: number;
  reportUser: string;
}
const SubjectBind: React.FC<Props> = ({informed, signState, subjectCode, resumeVisitId, refresh, state, subjectName}) => {
  const targetName = sessionStorage.getItem('targetName') || '受试者';
  const [info, setInfo] = useState({} as Info);
  const [codeURL, setCodeURL] = useState(null);
 
  const fetchBindInfo = async (subjectCode: string) => {
    const { data, code } = await getSubjectBindState(subjectCode);
    
    if(code === 0) {
      setInfo(data ?? {});
    }

    if (data?.bindState === 0 && [null, 2].includes(informed)) {
      const doctorName = storageX(Engine.Session).get('currentuserName')
      const result = await getSubjectBinding({ subjectCode, doctorName });
      setCodeURL(result?.data?.scanCodeUrl)
    }
  }

  const visible = useMemo(()=> {
    // 未绑定且项目为线上签署时不用展示
    if(info.bindState === 0 && 1 === informed) {
      return false
    }
    return ['Active', 'Finish', 'Lose', 'Stop', 'Pause', 'Init'].includes(state) && subjectCode
  }, [subjectCode, state, informed, info.bindState])

  useEffect(()=> {
    if (subjectCode) {
      fetchBindInfo(subjectCode)
    }
  }, [subjectCode]);
  

  // 解绑
  const handleUnbind = async () => {
    const { code } = await subjectUnbind(subjectCode);
    if (code === 0) {
      await fetchBindInfo(subjectCode)
      refresh(resumeVisitId)
    }
  }

  return (
    <>
      {
        visible && (
          <div className={modStyl.container}>
            <div className={modStyl.header}>
              <div className={modStyl.west}>
                <LinkOutlined/>
                {targetName}绑定
              </div>
              <div className={modStyl.east}>
                {
                  info.bindState === 1 && (
                    <>
                      <SignState subjectName={subjectName} signState={signState}/>
                      <UnbindButton onClick={handleUnbind}/>
                    </>
                  )
                }
              </div>
            </div>
            <div className={modStyl.content}>
              {
                info.bindState === 0 && [null, 2].includes(informed) && (
                  <div className={modStyl.qr}>
                    {!!codeURL && <QRCodeCanvas value={codeURL} size={128} />}
                    <p>{targetName}使用微信扫一扫进行绑定</p>
                  </div>
                )
              }
            </div>
          </div>
        )
      }
    </>
  )
};

export default SubjectBind;
