/**
 * 数据源下载模块路由配置
 */
 import DownloadComponent from '../components/download';    
 import DctApproveDownload from '../components/dctApproveDownload';
 import DctApproveRecord from '../components/dctApproveRecord'; 
 import dctApproveSingleAuditDownload from '../components/dctApproveSingleAuditDownload';
 
 const routes = [
     {'path': '/download', 'component': DownloadComponent}, // 数据源下载
     {'path': '/dctApproveDownload', 'component': DctApproveDownload}, // 审核记录下载
     {'path': '/dctApproveRecord', 'component': DctApproveRecord}, // 审核操作记录
     {'path': '/dctApproveSingleAuditDownload', 'component': dctApproveSingleAuditDownload}, // 审核记录下载
 ];
 
 export default routes