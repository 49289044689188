import React from 'react';
import './participants.less';
import { Form, Input, Select, Button, message as Message, InputNumber, Table, Popconfirm, AutoComplete, TreeSelect, message, Modal } from "antd";
import { createFromIconfontCN, RetweetOutlined, CaretRightOutlined, PauseOutlined, EditOutlined, SwapOutlined } from '@ant-design/icons';
import myIcon from "../researchCenter/photo_icon.png";
import RemarksModal from "./modal/modal";
import ModalTransfer from "./modalTransfer/modalTransfer";
import ModalEditor from "./modalEditor/modalEditor";
import debounce from 'lodash/debounce';
import DataTransfer from "./dataTransfer/dataTransfer"; //研究中心转移
import {
    updateAll,
    batchStopUser,
    GetProjectAllocationList,
    GetProjectUserPageList,
    DeleteProjectAllocation,
    UpdateProjectAllocationStatus,
    AddProjectAllocation, ModifyProjectAllocation,
    GetTenantUserSelects,
    GetCenterByProjectId,
    updateuserState
} from "../../../../services/project/ProjectService";

let _this;
const { Option } = Select;
const { Search } = Input;
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2283082_sb9kcktrb1j.js',//icon-shanchu icon-tongji icon-quxiao icon-quyufangwenqushi icon-tianjia1 icon-xiugai
    ],
});
const layout = {
    wrapperCol: {
        span: 24,
    },
};

const { TreeNode } = TreeSelect;

class Participants extends React.Component {

    constructor(props) {
        super(props);
        _this = this;
        _this.handleCodeAutoSearch = debounce(_this.handleCodeAutoSearch, 1000);
        _this.handleUserNameAutoSearch = debounce(_this.handleUserNameAutoSearch, 1000);
        let currentRole = sessionStorage.getItem('roleCode');
        _this.state = {
            selectedRowKeys: [],
            selectionType: 'checkbox',
            userRole: currentRole,
            roleSelectList: [], // 下拉角色集合
            statusList: [], // 下拉状态集合
            userList: [], // 列表集合
            userSelectList: [],// 参与人员下拉集合
            centerSelectList: [], // 所属中心下拉集合
            editFormData: {
                id: '',
                name: ''
            }, // 选中表格某行修改
            editBtnFlag: false,// 修改btn显 隐
            codeSelectFlag: false, // 编辑状态下 参与人员账号可改
            multipleFlag: false, // 编辑状态下 角色不可改
            visible: false,// 模块显隐
            visibleTransfer: false,// 受试者转移模块显隐
            visibleEditor: false,// 受试者转移模块显隐
            columns: [
                {
                    width: '30%',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div c_left'}>
                                <div className={'photo'}>
                                    <img src={myIcon} alt="" />
                                </div>
                                <div className={'c_left_title'}>
                                    <p>{record.name ? record.name : '测试项目'} {record.status === 'STOP' ? <span className='tag'>（已暂停）</span> : ''}</p>
                                    <p>{record.code ? record.code : '测试项目'}</p>
                                </div>
                            </div>
                        )
                    }
                },
                {
                    width: '11%',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div c_left2'}>
                                {record.finishedNum != undefined ? record.finishedNum : '--'}
                                <p>已完成</p>
                            </div>
                        )
                    }
                },
                {
                    width: '11%',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div c_left2'}>
                                {record.goalNum != undefined ? record.goalNum : '--'}
                                <p>目标</p>
                            </div>
                        )
                    }
                },
                {
                    width: '12%',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div c_left3'}>
                                {record.centerNames ? record.centerNames : '未绑定'}
                                <p>所属中心</p>
                            </div>
                        )
                    }
                },
                {
                    width: '14%',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div c_left4'}>
                                {record.roleNames ? record.roleNames : '未绑定'}
                                <p>项目角色</p>
                            </div>
                        )
                    }
                },
                {
                    width: '20%',
                    render: (text, record) =>
                        _this.state.userList.length >= 1 && _this.state.userRole !== 'EDC_AM' ? (
                            <div
                                onClick={eve => {
                                    eve.stopPropagation();
                                }}
                                className={'partPop'}
                                style={{ textAlign: 'right' }}
                            >

                                {/* 数据转移 */}
                                {
                                    (record.roleCodes[0] === 'EDC_CRC' && record.centerNames) &&
                                    <span className={'transfer'} onClick={() => this.onTransferDataModal(record)}>
                                        <SwapOutlined />
                                    </span>
                                }

                                {/* 编辑 */}
                                <span className={'transfer'} onClick={() => this.onEditor(record)}>
                                    <EditOutlined />
                                </span>

                                {/* 更新参与者状态 */}
                                <span className={'transfer'} onClick={() => this.updateParticipatorState(record)}>
                                    <CaretRightOutlined hidden={record.status === "PROCESS"} />
                                    <PauseOutlined hidden={record.status === "STOP"} />
                                </span>

                                {/* 受试者转移 */}
                                {
                                    (record.roleCodes[0] === 'EDC_CRC' && record.centerNames) &&
                                    <span className={'transfer'} onClick={() => this.onTransferModal(record)}>
                                        <RetweetOutlined />
                                    </span>
                                }

                                <Popconfirm
                                    title="确认删除该条数据?"
                                    okText='确认'
                                    cancelText='取消'
                                    /*icon={null}*/
                                    onConfirm={() => {
                                        this.handleDelete(record.id)
                                    }}
                                >
                                    <Button
                                        type="primary"
                                        danger
                                        style={{ height: 36 }}
                                    >
                                        {_this.formatIcon('icon-shanchu')}
                                    </Button>
                                </Popconfirm>
                            </div>
                        ) : null,
                },
            ],// 表格 行 配置
            rowId: '', // 表格选中行 id
            page: 1,//当前页码数
            pageSize: 10,//页码大小
            pagination: { // 分页组件元素对象信息
                onChange: (pageNo) => {
                    this.getPageData(pageNo);
                },
                current: 1,
                pageSize: 10,
                total: 100,
                showSizeChanger: false,
            },
            userCodeDisabled: false, // 参与者 账号可操作性
            userNameDisabled: false, //参与者 姓名可操作性
            operatorType: "", //数据操作员类型
            isNewUser: false, // 是否是新增的用户
            centerTreeData: [], //筛选前的treeData
            filteredTreeData: [],//筛选后的treeData
            selectTreeValue: null,
            transferInfo: {}, // 受试者信息
            centerId: null,
            userId: null,
            loading: false
        }
    }

    formRef = React.createRef();
    inputRef = React.createRef();

    componentDidMount() {
        _this.getDatas(); // 查询列表
    }

      /**
     * 全部暂停、恢复
     */
      handleUpdateAll = (status)=>{
        updateAll({status,projectId:this.props.projectId}).then(res => {
            if (res) {
                Message.success("操作成功!", 1)
                this.getDatas();
            }
        })
    }
    /**
     * 批量暂停、恢复
     */
    batchStop = (status)=>{
        if(!this.state.selectedRowKeys.length){
            return Message.warning("请选择项目参与者", 1)
        }
        batchStopUser({status ,ids: this.state.selectedRowKeys}).then(res => {
            if (res) {
                Message.success("操作成功!", 1)
                this.setState({
                    selectedRowKeys:[]
                })
                this.getDatas();
            }
        })
    }

    /**
     * 更新参与者状态 
     */
    updateParticipatorState = (record) => {
        let params = {
            status: record.status === 'PROCESS' ? 'STOP' : 'PROCESS'
        }
        updateuserState(record.id, params).then(res => {
            if (res) {
                _this.getDatas();
            }
        })
    }

    /**
     * 获取租户 下的参与者用户列表
     */
    getTenantUserSelects = (param) => {
        if (param.userCode) {
            let params = {
                userCode: param.userCode,
                userName: ''
            }
            GetTenantUserSelects(params).then(res => {
                if (res) {
                    _this.setState({
                        userSelectList: res
                    })
                }
            })
        }
        else if (param.userName) {
            let params = {
                userCode: '',
                userName: param.userName,
            }
            GetTenantUserSelects(params).then(res => {
                if (res) {
                    _this.setState({
                        userSelectList: res
                    })
                }
            })
        }
    }

    /**
     * 获取租户 下的参与者用户列表 Search事件
     */
    handleCodeAutoSearch = (val) => {
        if (val) {
            this.setState({
                userCode: val,
                isNewUser: true,
            }, () => {
                _this.getTenantUserSelects({ userCode: val })
            })
        }
        else {
            _this.setState({
                userSelectList: []
            })
        }
    }

    /**
     * 获取租户 下的参与者用户列表 onSelect事件
     */
    handleCodeAutoSelect = (val) => {
        this.formRef.current.setFieldsValue({ code: val.split('#')[0], userName: val.split('#')[1] });
        this.setState({
            isNewUser: false,
            userNameDisabled: true
        })
    }

    //当userCode 改变
    onUserCodeChange = (val) => {
        if (val && this.state.userNameDisabled) {
            this.setState({
                userNameDisabled: false
            })
            this.formRef.current.setFieldsValue({ userName: "" });
        }
        else if (!val) {
            this.setState({
                userNameDisabled: false
            })
            this.formRef.current.setFieldsValue({ userName: "" });
        }
    }

    /**
 * 搜索参与者姓名
 */
    handleUserNameAutoSearch = (val) => {
        if (val) {
            this.setState({
                userName: val,
                isNewUser: true,
            }, () => {
                _this.getTenantUserSelects({ userName: val })
            })
        }
        else {
            _this.setState({
                userSelectList: []
            })
        }
    }

    /**
     * 搜索参与者姓名onSelect事件
     */
    handleUserNameAutoSelect = (val) => {
        this.formRef.current.setFieldsValue({ code: val.split('#')[0], userName: val.split('#')[1] });
        this.setState({
            isNewUser: false,
            userCodeDisabled: true
        })
    }

    //当userName 改变
    onUserNameChange = (val) => {
        if (val && this.state.userCodeDisabled) {
            this.setState({
                userCodeDisabled: false
            })
            this.formRef.current.setFieldsValue({ code: "" });
        } else if (!val) {
            this.setState({
                userCodeDisabled: false
            })
            // this.formRef.current.setFieldsValue({ code: "" });
        }
    }


    /**
     * icon 图标
     */
    formatIcon = iconName => {
        return iconName ? (<IconFont type={iconName} />) : undefined;
    }

    /**
     * 查询列表
     */
    getDatas = async (operation) => {
        const selectData = await GetProjectAllocationList({ "projectId": _this.props.projectId });
        console.log(selectData)
        let statusList = [];
        selectData.statusList.map(x => {
            if (x == "PROCESS") {
                statusList.push({ code: x, message: "进行中" })
            } else if (x == "STOP") {
                statusList.push({ code: x, message: "已暂停" })
            } else if (x == "END") {
                statusList.push({ code: x, message: "已完成" })
            }
        })
        _this.setState({
            roleSelectList: selectData.roleSelectList,
            /*userSelectList: selectData.userSelectList,*/
            centerSelectList: selectData.centerSelectList,
            statusList
        }, () => {
            if (operation == 'add') {
                _this.getPageData('1');
            } else if (operation == 'del') {
                if (this.state.userList.length == 1 && _this.state.page != 1) {
                    _this.getPageData(_this.state.page - 1);
                } else {
                    _this.getPageData(_this.state.page);
                }
            } else {
                _this.getPageData(_this.state.page);
            }

        })

        //获取区域、中心下拉列表
        GetCenterByProjectId({ projectId: _this.props.projectId }).then(res => {
            if (res) {
                this.filterTreeData(res);
            }
        })
    }

    //筛选treeData
    filterTreeData = (treeData) => {
        console.log(treeData);
        let filterFun = (data) => {
            for (let item of data) {
                if (item.children && item.children.length > 0) {
                    if (item.type === "center") {
                        item.value = item.id + '_' + item.title
                        item.title = `${item.title}(中心)`
                    }
                    else if (item.type === "area") {
                        item.value = item.id + '_' + item.title
                        item.title = `${item.title}(区域)`
                    }
                    filterFun(item.children);
                }
                else {
                    if (item.type === "center") {
                        item.value = item.id + '_' + item.title
                        item.title = `${item.title}(中心)`
                    }
                    else if (item.type === "area") {
                        item.value = item.id + '_' + item.title
                        item.title = `${item.title}(区域)`
                    }
                }
            }
        }
        filterFun(treeData);
        this.setState({
            centerTreeData: treeData
        }, () => {
            this.setState({
                operatorType: "EDC_CRC"
            })
            this.formRef.current.setFieldsValue(
                {
                    roleCodes: "EDC_CRC"
                }
            )
            _this.setTreeDataSelectable()
        })
    }

    //选择角色
    onSelectRule = (value) => {
        this.setState({
            operatorType: value
        })
        this.formRef.current.setFieldsValue({
            centerIds: null
        })
        this.setTreeDataSelectable()
    }

    //根据选择的角色设置可选的区域、中心
    setTreeDataSelectable = () => {
        let filterData = JSON.parse(JSON.stringify(this.state.centerTreeData));
        // let role = _this.formRef.current.getFieldsValue(['roleCodes']).roleCodes;
        let role = "EDC_CRC"
        let filterFun = (data) => {
            for (let item of data) {
                if (item.children && item.children.length > 0) {
                    if (item.type === 'area') {
                        if (role === "EDC_CRC") {
                            item.selectable = false
                        }
                        else if (role === "EDC_AM") {
                            item.selectable = true
                            item.title = <span style={{ color: '#6287EC' }}>{item.title}</span>
                        }
                        else {
                            item.selectable = false
                        }
                    }
                    else if (item.type === 'center') {
                        if (role === "EDC_AM") {
                            item.selectable = false
                        }
                        else if (role === "EDC_CRC") {
                            item.selectable = true
                            item.title = <span style={{ color: '#6287EC' }}>{item.title}</span>
                        }
                        else {
                            item.selectable = false
                        }
                    }
                    filterFun(item.children);
                }
                else {
                    if (item.type === 'area') {
                        if (role === "EDC_CRC") {
                            item.selectable = false
                        }
                        else if (role === "EDC_AM") {
                            item.selectable = true
                            item.title = <span style={{ color: '#6287EC' }}>{item.title}</span>
                        }
                        else {
                            item.selectable = false
                        }
                    }
                    else if (item.type === 'center') {
                        if (role === "EDC_AM") {
                            item.selectable = false
                        }
                        else if (role === "EDC_CRC") {
                            item.selectable = true
                            item.title = <span style={{ color: '#6287EC' }}>{item.title}</span>
                        }
                        else {
                            item.selectable = false
                        }
                    }
                }
            }
        }

        // filterFun(filterData);

        // console.log(filterData)

        this.setState({
            filteredTreeData: filterData
        })

    }

    /**
     * 获取分页数据
     */
    getPageData = async (currentPage) => {
        const params = {
            name: _this.inputRef.current.state.value,
            projectId: _this.props.projectId,
            page: currentPage ? currentPage : this.state.page,
            pageSize: this.state.pageSize,
        }
        const res = await GetProjectUserPageList(params);
        _this.setState({
            loading: true
        })
        if (res) {
            _this.setState({
                // loading: false,
                userList: res.rows,
                page: res.page,
                pageSize: res.pageSize,
                pagination: {
                    ...this.state.pagination,
                    current: res.page,
                    pageSize: res.pageSize,
                    total: res.total,
                }
            },() =>{
                setTimeout(() =>{
                    this.setState({
                        loading: false
                    })
                }, 1000)
                
            })
        }
    }

    /**
     * 表单提交
     * @param values
     */
    handleFinish = (values) => {
        let centerIds;
        let areaId;
        if (values.roleCodes === "EDC_CRC" || values.roleCodes === "EDC_DRE") {
            centerIds = values.centerIds ? values.centerIds.split('_')[0] : null;
            areaId = undefined;
        }
        else if (values.roleCodes === "EDC_AM") {
            centerIds = undefined;
            areaId = values.centerIds ? values.centerIds.split('_')[0] : null;
        }
        let params = {
            roleCodes: [values.roleCodes],
            projectId: _this.props.projectId,
            name: values.userName,
            isNewUser: this.state.isNewUser,
            goalNum: values.goalNum,
            code: values.code,
            centerIds: centerIds ? [centerIds] : undefined,
            areaId: areaId
        }
        AddProjectAllocation(params).then(res => {
            if (res && res.code === 0) {
                Message.success('添加成功！', 1)
                _this.getDatas('add');
                _this.setState({
                    editBtnFlag: false,
                    userCodeDisabled: false,
                    userNameDisabled: false,
                    userSelectList: [],
                    editFormData: {
                        id: '',
                        name: '',
                    },
                    rowId: '',
                })
                _this.formRef.current.resetFields();
            }
        })
    }

    /**
     * 搜索 events
     * @param value
     */
    handleSearch = async () => {
        const params = {
            projectId: _this.props.projectId,
            name: _this.inputRef.current.state.value,
            page: 1,
            pageSize: 10,
        }
        const res = await GetProjectUserPageList(params);
        _this.setState({
            userList: res.rows,
            page: res.page,
            pageSize: res.pageSize,
            pagination: {
                ...this.state.pagination,
                current: res.page,
                pageSize: res.pageSize,
                total: res.total,
            }
        })
    }

    /**
     * 删除
     * @returns {JSX.Element}
     */
    handleDelete = (id) => {
        _this.formRef.current.resetFields();
        _this.setState({
            editBtnFlag: false,
        })
        let params = {
            researchId: id
        }
        DeleteProjectAllocation(id, params).then(res => {
            if (res && res.code === 0) {
                _this.getDatas('del');
                Message.success('删除成功！', 1)
                _this.setState({
                    editFormData: {
                        id: '',
                        name: ''
                    },
                    rowId: '',
                    editBtnFlag: false,
                    codeSelectFlag: false,
                    multipleFlag: false,
                    userNameDisabled: false,
                    userCodeDisabled: false,
                    userSelectList: []
                })
                _this.formRef.current.resetFields();
            }
        })
    }

    /**
     * 更新状态
     * @returns {JSX.Element}
     */
    UpdateProjectResearchStatus = (status, options) => {
        UpdateProjectAllocationStatus(options.id, { status }).then(res => {
        })
    }

    /**
     * 行 点击 event
     * @returns {JSX.Element}
     */
    handleOnclick = (currentId) => {
        _this.state.userList.forEach(i => {
            i.checked = false;
            if (i.id === currentId) {
                if (i.roleCodes.includes("EDC_PM")) {
                    Message.error('当前参与者为项目管理员，不可操作！');
                    return;
                }
                console.log(i)
                i.checked = true;
                _this.setState({
                    editFormData: {
                        id: i.id,
                        name: i.name
                    },
                    editBtnFlag: true,
                    codeSelectFlag: true,
                    multipleFlag: true,
                })
                _this.formRef.current.setFieldsValue({
                    code: i.code,
                    roleCodes: i.roleCodes,
                    name: i.name,
                    centerIds: i.centerIds,
                    goalNum: i.goalNum,
                    id: i.id,
                });
            }
        })
    }

    /**
     * 行点击 event
     * @returns {JSX.Element}
     */
    handleRow = (record) => {
        return {
            onClick: () => {
                if (record.roleCodes.includes("EDC_PM")) {
                    Message.error('当前参与者为项目管理员，不可操作！');
                    return;
                }
                _this.setState({
                    editFormData: {
                        id: record.id,
                        name: record.name
                    },
                    editBtnFlag: true,
                    codeSelectFlag: true,
                    multipleFlag: true,
                    rowId: record.id,
                })
                console.log(record)

                let center_id = null;
                if (record.centerIds && record.centerIds.length > 0 &&
                    record.centerNames && (record.centerNames != '' || record.centerNames != 'null' || record.centerNames != 'undefined')
                ) {
                    center_id = record.centerIds[0] + '_' + record.centerNames
                }

                _this.formRef.current.setFieldsValue({
                    code: record.code,
                    userName: record.name,
                    name: record.name,
                    centerIds: center_id,
                    goalNum: record.goalNum,
                    id: record.id,
                    roleCodes: record.roleCodes[0]
                });
                _this.setTreeDataSelectable(record.roleCodes[0]);

            },
            onMouseEnter: () => {
                /*this.setState({
                    rowId: record.id,
                });*/
            },
        }
    };

    /**
     * 行点击高亮
     * @returns {JSX.Element}
     */
    handleSetRowClassName = (record) => {
        return record.id === _this.state.rowId ? 'clickRowStyl' : '';
    }

    /**
     * 修改
     * @returns {JSX.Element}
     */
    handleUpdate = () => {
        const form = _this.formRef.current
        const values = form.getFieldsValue(['userName', 'code', 'roleCodes', 'centerIds', 'goalNum']);
        values.centerIds = values.centerIds ? values.centerIds.split('_')[0] : null;
        let centerIds;
        let roleCodes;
        if (values.centerIds instanceof Array) {
            centerIds = [...values.centerIds]
        } else {
            centerIds = values.centerIds == null ? [] : [values.centerIds]
        }
        if (values.roleCodes instanceof Array) {
            roleCodes = [...values.roleCodes]
        } else {
            roleCodes = values.roleCodes == null ? [] : [values.roleCodes]
        }
        console.log('提交');
        console.log(centerIds);
        let params = {
            projectId: _this.props.projectId,
            id: _this.state.editFormData.id, // 修改 当条数据的id
            name: values.userName, // 修改 当条数据的name
            roleCodes: roleCodes,
            centerIds: centerIds,
            goalNum: values.goalNum,
            code: values.code
        }
        ModifyProjectAllocation(params).then(res => {
            if (res && res.code === 0) {
                Message.success('修改成功！', 1)
                _this.setState({
                    loading: true
                })
                _this.getDatas();
                _this.setState({
                    editFormData: {
                        id: '',
                        name: ''
                    },
                    rowId: '',
                    editBtnFlag: false,
                    codeSelectFlag: false,
                    multipleFlag: false,
                    userNameDisabled: false,
                    userCodeDisabled: false,
                    userSelectList: []
                })
                _this.formRef.current.resetFields();
            }
        })
    }

    /**
     * 取消 修改
     * @returns {JSX.Element}
     */
    handleCancel = () => {
        _this.setState({
            editBtnFlag: false,
            codeSelectFlag: false,
            multipleFlag: false,
            userCodeDisabled: false,
            userNameDisabled: false,
            editFormData: {
                id: '',
                name: ''
            },
            rowId: ''
        });
        _this.formRef.current.setFieldsValue({
            code: "",
            roleCodes: [],
            userName: "",
            name: '',
            centerIds: [],
            goalNum: '',
            id: '',
        });
        _this.state.userList.forEach(i => {
            i.checked = false
        })
    }

    /**
     * 设置显示隐藏状态和要传递的参数
     */
    showModal = (itemData) => {
        this.setState({
            visible: true,
        });
    }

    /**
     * 设置关闭隐藏状态和要传递的参数
     */
    closeModal = () => {
        this.setState({
            visible: false,
        });
    }

    /**
     * 受试者转移关闭模态框
     */
    closeModalTransfer = () => {
        this.setState({
            visibleTransfer: false,
        });
    }

    /**
     * 受试者转移关闭模态框
     */
    closeModalDataTransfer = () => {
        this.setState({
            visibleDataTransfer: false,
        });
    }
    /**
     * 受试者转移显示模态框
     */
    onTransferModal = (record) => {
        this.setState({
            visibleTransfer: true,
            transferInfo: { ...record }
        });
    }
    /**
     * 研究中心转移显示模态框
     */
    onTransferDataModal = (record) => {
        this.setState({
            visibleDataTransfer: true,
            transferInfo: { ...record }
        });
    }

    /**
     * 编辑
     */
    onEditor = (record) => {
        this.setState({
            visibleEditor: true,
            userId: record.id
        });
    }

    // 关闭修改模态框
    closeModalEditor = () => {
        this.setState({
            visibleEditor: false,
        });
    }

    // onTreeSelectChange
    onTreeSelectChange = (value, item) => {
        // this.setState({
        //     selectTreeValue: item.id
        // })
    }

    // 设置父节点不可选
    renderTreeNodes = (data) => {
        if (data && data.length > 0) {
            data.map((item) => {
                if (item.children) {
                    item.disabled = true;
                    return (
                        <TreeNode key={item.id} title={item.title} value={item.value} disabled={item.disabled}>
                            {this.renderTreeNodes(item.children)}
                        </TreeNode>
                    )
                }
                return <TreeNode {...item} key={item.id} title={item.title} value={item.value}></TreeNode>
            })
        }
    }

   

    render() {
    
        let { loading, userList, pagination, userRole, visibleEditor, userId , selectionType,selectedRowKeys} = _this.state;
        
        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowKeys
                })
              console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            // getCheckboxProps: (record ) => ({
            //   disabled: record.status === 'STOP', // Column configuration not to be checked
            //   name: record.name,
            // }),
            selectedRowKeys,
          };
        return (
            <div className={'participants'}>
                <div className={'pp_top'} hidden={userRole === 'EDC_AM'}>
                    <div className={'selectQueryGroup'}>
                        <Form
                            layout={'inline'}
                            initialValues={{ layout: 'inline' }}
                            onFinish={!this.state.editBtnFlag ? _this.handleFinish : _this.handleUpdate}
                            ref={_this.formRef}
                            {...layout}
                        >
                            <Form.Item
                                name='code'
                                rules={[
                                    {
                                        required: true,
                                        message: '参与者账号应为：6-20位数字或字母！',
                                        pattern: new RegExp(/^[A-Za-z0-9]{6,20}$/)
                                    },
                                ]}
                                className={this.state.editBtnFlag ? 'editClass' : ''}
                                style={{ width: '20%', marginRight: 0, paddingRight: 16 }}
                            >
                                <AutoComplete
                                    style={{ width: '100%', marginBottom: 16, height: 36 }}
                                    allowClear
                                    onSearch={val => this.handleCodeAutoSearch(val)}
                                    placeholder="请输入参与者账号"
                                    disabled={this.state.userCodeDisabled || _this.state.multipleFlag ? true : false}
                                    onChange={val => this.onUserCodeChange(val)}
                                    onSelect={val => this.handleCodeAutoSelect(val)}
                                >
                                    {
                                        _this.state.userSelectList && _this.state.userSelectList.length != 0 ?
                                            _this.state.userSelectList.map((r, index) => (
                                                <Option value={`${r.userCode}#${r.userName}`}
                                                    key={index}>{`${r.userCode}（${r.userName}）`}</Option>
                                            )) : ''
                                    }
                                </AutoComplete>
                            </Form.Item>
                            <Form.Item
                                name='userName'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入参与者姓名!',
                                    },
                                ]}
                                className={this.state.editBtnFlag ? 'editClass' : ''}
                                style={{ display: (_this.state.editBtnFlag ? 'none' : ''), width: '20%', marginRight: 0, paddingRight: 16 }}
                            >
                                <AutoComplete
                                    style={{ width: '100%', marginBottom: 16, height: 36 }}
                                    allowClear
                                    onSearch={val => this.handleUserNameAutoSearch(val)}
                                    placeholder="请输入参与者姓名"
                                    maxLength={20}
                                    disabled={this.state.userNameDisabled}
                                    onChange={val => this.onUserNameChange(val)}
                                    onSelect={val => this.handleUserNameAutoSelect(val)}
                                >
                                    {
                                        _this.state.userSelectList && _this.state.userSelectList.length != 0 ?
                                            _this.state.userSelectList.map((r, index) => (
                                                <Option value={`${r.userCode}#${r.userName}`}
                                                    key={index}>{`${r.userCode}（${r.userName}）`}</Option>
                                            )) : ''
                                    }
                                </AutoComplete>
                            </Form.Item>

                            <Form.Item
                                name='userName'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入参与者姓名!',
                                    },
                                ]}
                                className={this.state.editBtnFlag ? 'editClass' : ''}
                                style={{ display: (!_this.state.editBtnFlag ? 'none' : ''), width: '20%', marginRight: 0, paddingRight: 16 }}
                            >
                                <Input
                                    placeholder="请输入参与者姓名"
                                    style={{ width: '100%', marginBottom: 16, height: 36 }}
                                    allowClear
                                    maxLength={20}
                                />
                            </Form.Item>

                            <Form.Item
                                name='roleCodes'
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择角色!',
                                    },
                                ]}
                                className={this.state.editBtnFlag ? 'editClass' : ''}
                                style={{ width: '20%', marginRight: 0, paddingRight: 16 }}
                            >
                                <Select
                                    // mode="multiple"
                                    placeholder="请选择角色"
                                    style={{ width: '100%', marginBottom: 16, height: 36 }}
                                    allowClear
                                    // disabled={_this.state.multipleFlag ? true : false}
                                    onSelect={(value) => { this.onSelectRule(value) }}
                                >
                                    {/* {
                                        _this.state.roleSelectList && _this.state.roleSelectList.length != 0 ?
                                            _this.state.roleSelectList.map(r => (
                                                <Option value={r.roleCode} key={r.roleCode}>{r.roleName}</Option>
                                            )) : ''
                                    } */}
                                    {/* {
                                        [{ roleCode: "EDC_CRC", roleName: "数据采集员" }, { roleCode: "EDC_AM", roleName: "机构管理员" }].map((r, index) => (
                                            <Option value={r.roleCode} key={index}>{`${r.roleCode}（${r.roleName}）`}</Option>
                                        ))
                                    } */}
                                    {
                                        [
                                            { roleCode: "EDC_CRC", roleName: "数据采集员" },
                                            { roleCode: "EDC_AM", roleName: "区域管理员" },
                                            { roleCode: "EDC_DRE", roleName: "数据评论员" },
                                            { roleCode: "ReadOnly", roleName: "项目只读" },
                                            /*
                                            { roleCode: "EDC_PROJ_SJ", roleName: "项目设计" }
                                            */
                                        ].map((r, index) => (
                                            <Option value={r.roleCode} key={index}>{`${r.roleName}`}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name='centerIds'
                                className={this.state.editBtnFlag ? 'editClass' : ''}
                                style={{
                                    display: (_this.state.editBtnFlag ? 'none' : ''),
                                    width: '20%', marginRight: 0, paddingRight: 16
                                }}
                            >
                                <TreeSelect
                                    style={{ width: '100%', marginBottom: 16, height: 36 }}
                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    showSearch
                                    treeData={this.state.filteredTreeData}
                                    placeholder="请选择管理区域/所属中心"
                                    // treeDefaultExpandAll // 展开树节点
                                    allowClear
                                    onSelect={this.onTreeSelectChange}
                                    filterTreeNode={true}
                                    treeNodeFilterProp="title"
                                >
                                    {this.renderTreeNodes(this.state.filteredTreeData)}
                                </TreeSelect>
                            </Form.Item>

                            <Form.Item
                                name='centerIds'
                                className={this.state.editBtnFlag ? 'editClass' : ''}
                                style={{
                                    display: (!_this.state.editBtnFlag ? 'none' : ''),
                                    width: '20%', marginRight: 0, paddingRight: 16
                                }}
                            >
                                <TreeSelect
                                    style={{ width: '100%', marginBottom: 16, height: 36 }}
                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    showSearch
                                    treeData={this.state.filteredTreeData}
                                    placeholder="请选择管理区域/所属中心"
                                    // treeDefaultExpandAll // 展开树节点
                                    allowClear
                                    onSelect={this.onTreeSelectChange}
                                    filterTreeNode={true}
                                    treeNodeFilterProp="title"
                                >
                                    {this.renderTreeNodes(this.state.filteredTreeData)}
                                </TreeSelect>
                                {/* <Select
                                    placeholder="请选择管理区域/所属中心"
                                    style={{ width: '100%', marginBottom: 16, height: 36 }}
                                    allowClear
                                >
                                    {
                                        _this.state.centerSelectList && _this.state.centerSelectList.length != 0 ?
                                            _this.state.centerSelectList.map(r => (
                                                <Option value={r.centerId}
                                                    key={r.centerId}>{r.centerName}</Option>
                                            )) : ''
                                    }
                                </Select> */}
                            </Form.Item>

                            {
                                this.state.operatorType === "EDC_CRC" || this.state.operatorType === "" ? <Form.Item
                                    name='goalNum'
                                    rules={[
                                        {
                                            type: 'number',
                                            max: 999999999,
                                            message: '请输入小于10位的正整数!',
                                        }
                                    ]}
                                    className={this.state.editBtnFlag ? 'editClass' : ''}
                                    style={{ width: '20%', marginRight: 0 }}
                                >
                                    <InputNumber
                                        min={0}
                                        max={999999999}
                                        style={{ width: '100%', marginBottom: 16, height: 36, borderColor: '#EEEFF7' }}
                                        parser={value => value.replace(/^(-1+)|[^\d]+/g, '')}
                                        placeholder="请输入参与者目标样本数"
                                    />
                                </Form.Item> : null
                            }

                            <Form.Item
                                style={{
                                    display: (_this.state.editBtnFlag ? 'none' : 'block'),
                                    textAlign: 'right',
                                    width: '100%',
                                }}
                            >
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    style={{ height: 36 }}
                                >
                                    {/*{_this.formatIcon('icon-tianjia1')}*/}
                                    添加
                                </Button>
                            </Form.Item>
                            <Form.Item
                                style={{
                                    display: (_this.state.editBtnFlag ? 'block' : 'none'),
                                    textAlign: 'right',
                                    width: '100%',
                                }}
                            >
                                <Button
                                    className={'cancelBtn'}
                                    onClick={_this.handleCancel}
                                    style={{ height: 36 }}
                                >
                                    {/*{_this.formatIcon('icon-quxiao')}*/}
                                    取消
                                </Button>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    style={{ height: 36 }}
                                >
                                    {/*{_this.formatIcon('icon-xiugai')}*/}
                                    修改
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
                <div className={'pp_content'}>
                    <div className={'search'}>
                        <span className={'rc_title'}>项目参与者</span>
                        <div className={'search_group'}>
                            <Input
                                placeholder="查找"
                                style={{
                                    width: 300,
                                    height: 36,
                                    lineHeight: 1.8,
                                    borderColor: '#EEEFF7',
                                    borderTopLeftRadius: 2,
                                    borderBottomLeftRadius: 2,
                                    borderTopRightRadius: 0,
                                    borderBottomRightRadius: 0,
                                }}
                                allowClear
                                ref={this.inputRef}
                            />
                            <Button
                                type="primary"
                                htmlType="submit"
                                onClick={_this.handleSearch}
                                style={{
                                    height: 36,
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 0,
                                    borderTopRightRadius: 2,
                                    borderBottomRightRadius: 2,
                                }}
                            >
                                {/* {_this.formatIcon('icon-chazhao')}*/}
                                查找
                            </Button>

                            <Button
                                type="danger"
                                style={{ marginLeft: 16, height: 36 }}
                                onClick={()=>{this.handleUpdateAll('STOP')}}
                               
                            >
                                全部暂停
                            </Button>
                            <Button
                                type="primary"
                                style={{ marginLeft: 16, height: 36 }}
                                onClick={()=>{this.handleUpdateAll('PROCESS')}}
                               
                            >
                                全部恢复
                            </Button>
                            <Button
                                type={selectedRowKeys.length?'danger':'default'}
                                style={{ marginLeft: 16, height: 36 }}
                                onClick={()=>{this.batchStop('STOP')}}
                                disabled={!selectedRowKeys.length}
                                title={!selectedRowKeys.length && '请勾选项目参与者'}
                            >
                                批量暂停
                            </Button>
                            <Button
                                type={selectedRowKeys.length?'primary':'default'}
                                style={{ marginLeft: 16, height: 36 }}
                                onClick={()=>{this.batchStop('PROCESS')}}
                                disabled={!selectedRowKeys.length}
                                title={!selectedRowKeys.length && '请勾选项目参与者'}
                            >
                                批量恢复
                            </Button>
                            <Button
                                type="primary"
                                style={{ marginLeft: 16, height: 36 }}
                                onClick={this.showModal}
                                hidden={userRole === 'EDC_AM'}
                            >
                                批量导入参与者
                            </Button>
                        </div>
                    </div>
                    <div className={'itemList'}>
                        {
                            <Table
                            rowSelection={{
                                type: selectionType,
                                    ...rowSelection,
                                }}
                                loading={loading}
                                locale={{ emptyText: "暂无数据" }}
                                columns={_this.state.columns}
                                dataSource={_this.state.userList}
                                
                                defaultExpandAllRows={true}
                                expandIconAsCell={false}
                                onRow={_this.handleRow}
                                rowClassName={_this.handleSetRowClassName}
                                pagination={userList.length > 0 && pagination}
                                rowKey={'id'}
                            />
                        }
                    </div>
                </div>

                {/* 批量导入参与者 */}
                <div className={'modal'}>
                    <RemarksModal
                        visible={this.state.visible}
                        closeModal={this.closeModal}
                        projectId={this.props.projectId}
                        getDatas={this.getDatas}
                    />
                </div>

                {/* 受试者转移 */}
                {this.state.visibleTransfer &&
                    <div className={'modalTransfer'}>
                        <ModalTransfer
                            visible={this.state.visibleTransfer}
                            closeModal={this.closeModalTransfer}
                            projectId={this.props.projectId}
                            getDatas={this.getDatas}
                            transferInfo={this.state.transferInfo}
                            centerId={this.state.centerId}
                        />
                    </div>
                }

                {/* 研究中心转移 */}
                {this.state.visibleDataTransfer &&
                    <div className={'modalTransfer'}>
                        <DataTransfer
                            visible={this.state.visibleDataTransfer}
                            closeModal={this.closeModalDataTransfer}
                            projectId={this.props.projectId}
                            getDatas={this.getDatas}
                            transferInfo={this.state.transferInfo}
                            centerId={this.state.centerId}
                        />
                    </div>
                }

                {/* PM可查看和修改医生（参与者）收款资料 */}
                {
                    visibleEditor &&
                    <div className={'modalEditor'}>
                        <ModalEditor
                            visible={visibleEditor}
                            closeModal={this.closeModalEditor}
                            userId={userId}
                            getDatas={this.getDatas}
                        />
                    </div>
                }
            </div>
        );
    }
}

export default Participants
