/**
 * 平台系统管理模块路由配置
 */
import User from '../components/system/user';     // 用户管理
import Role from '../components/system/role';     // 项目中心
import AuditPermission from '../components/system/audit/permission';     // 审核权限管理
import SignAudit from '../components/system/sign/audit';  //签名审核权限管理
const routes = [
    { 'path': '/system/user', 'component': User },
    { 'path': '/system/role', 'component': Role },
    { 'path': '/system/audit/permission', 'component': AuditPermission },
    { 'path': '/system/sign/audit', 'component': SignAudit },
];

export default routes